@import '../../../values/Colored.css';

.WonAwards-carosualbox{
    width: 100%;
    padding: 42px 120px 28px 118px ;
    background-color: var(--white-color);
}
.Award-Cardbox{
    width: 100%;
    padding: 50px 120px 18px 118px ;
    background-color: var(--bg-consultcard);
    display: flex;
    justify-content: center;
    align-items: center;
}
.Award-card{
    text-transform: none;
    width: 97%;
   max-width:380px;
   min-height:250px;
   text-decoration: none;
    flex-grow: 0;
    margin: 39.1px 0px 0px 0px;
    padding: 8px 8px 0px 8px;
    border-radius: 15px;
    background-color: var(--white-color) !important;
    display: flex;
    flex-direction:column;
    margin: auto;
    -webkit-tap-highlight-color: transparent;
  }
    .Award-imageawd{
        width: 42vh;
        height: 245px;
        align-self: center;
          border-radius: 4%;
          max-width:100%;
        flex-grow: 0;
        margin: 0px 0px 9px 0vh;
        background-color:var(--text-black);
        }
        .Award-nametextawd{
            flex-grow: 0;
            padding: 0px 14px 0px 14px;
            margin: 5px 0px 8px 0px ;
        font-family: FontRegular;
            font-size: 15px;
            font-stretch: normal;
            font-weight: 500;
            font-style: normal;
            line-height: 1.4;
            text-decoration:none;
            letter-spacing: normal;
            text-align: center;
            color: var(--black-color);
            height: 38px;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            justify-content: center;
           overflow: hidden;
         }
.WonAwards-Text {
    margin: 0px 0px 30px 0px;
    font-family: FontSemiBold;
    font-size: 34px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-Txt);
  }
  .SeemoreAwrds-btn{
        width: 100%;
        max-width: 139.085px;
        height: 34.569px;
        flex-shrink: 0;
          display: flex;
          text-decoration: none;
          text-transform: none;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap:7px;
          padding: 9px 20.5px 9px 12.5px;
          border-radius: 4px;
          border: solid 1px var(--black-color) !important;
          background-color: var(--white-color) !important;
          margin: auto;
          margin-top: 0px;
      }
  .SeemoreAwrds-text{
    flex-grow: 0;
    font-family:FontSemiBold;
    font-size: 13px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    letter-spacing: -0.154px;;
    text-align: center;
    color: var(--black-color);
  }
@media screen and (max-width:1472px) {
    .WonAwards-carosualbox {
padding: 50px 100px 18px 100px 
    }
}@media screen and (max-width:1161px) {
    .WonAwards-carosualbox {
padding: 40px 80px 5px 80px 
    }
}
@media screen and (max-width:600px) {
    .WonAwards-Text {
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;
        margin: 0px 0px 30px 0px;
    }
    .WonAwards-carosualbox{
        min-height: 450px;
    }
}
@media screen and (max-width:560px) {
    .WonAwards-carosualbox {
padding: 40px 50px 14px 50px
    }
}