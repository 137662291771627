@import "../../../values/Colored.css";

.MainGrey-box {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  padding: 48.5px 160px 55px 160px;
  background-color: var(--light-grey);
  gap: 20px;
}
.outer-div {
  width: 50%;
}
.How-it-text{
    color: var(--black-color);
}
.description-underLine{
    text-decoration:none;
    color:var(--consult-docbtn);
}
.howitStarted-text {
  margin: 0px 0px 15.3px 0px;
  font-family:FontSemiBold;
  font-size: 36px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: var(--consult-docbtn);
}
.descriptionStarted-Text {
  max-width:90%;
  font-family: FontRegular;
  font-size:16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align:left;
  color: var(--black-color);
}
.AllImg-Box{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.images-boxes{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}
.First-image1 {
  width: 100%;
  max-width: 30%;
  margin: 0 0% 2% 2%;
  border-radius: 2.5%;
}
.Second-image2 {
  max-width: 32%;
  margin: 0px 0px 7% 2.5%;
  border-radius: 2.5%;
}
.Third-image3 {
  max-width: 32%;
    margin: 0 0% 0 2%;
    border-radius: 2.5%;
}
.Fourth-image4 {
  max-width: 30%;
  margin: -5% 1% 1% 2.5%;
  border-radius: 2.5%;
}

@media screen and (max-width:1000px) {
  .MainGrey-box{
    flex-wrap:wrap-reverse;
    margin: 0px 0px 62.4px;
    padding: 35px 20px 0px 20px;
  }
  .howitStarted-text{
    font-size: 28px;
    margin: 0px 0px 0px 0px;
  }
  .descriptionStarted-Text{
    font-size:18px;
    margin: 0px 5px 50px 0px;
    text-align: justify;
    max-width:100%;
   letter-spacing:0px;
  }
  .outer-div {
    width: 100%
  }
  .images-boxes{
    max-width:550px;
    margin-left:0px;
  }
  .First-image1{
    max-width:46%;
    margin: 0 0% 2% 0%;
  }
  .Second-image2{
    max-width:48%;
    margin: -4% 0px 7% 2.5%;
  }
  .Third-image3{
    max-width: 46%;
    margin: 0 0% 0% 0%;
  }
  .Fourth-image4{
    max-width:48%;
    margin: -9% 1% 1% 2.5%;
  }
}
@media screen and (max-width:500px) {
  .Third-image3 {
    max-width: 50%;
  }
  .Second-image2{
    max-width:50%;
  }
}

@media screen and (min-width:2500px) {
  .First-image1 {
    max-width: 27%;
  }
}
@media screen and (min-width:2800px) {
  .First-image1 {
    max-width: 25%;
  }
}
@media screen and (min-width:3000px) {
  .First-image1 {
    max-width: 23%;
  }
}
@media screen and (min-width:3200px) {
  .First-image1 {
    max-width: 21%;
  }
}