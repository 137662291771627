@import "../../../values/Colored.css";

.Main-ListCartBox{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 50px;
    margin: 35px 77px 35px 77px;
}
.ListandPaymentBox{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 50px;
}
.fullList-box{
    width: 80%;
}
.YouMay-txt{
    flex-grow: 0;
    font-family: FontRegular;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.15px;
    text-align:left;
    color: var(--text-event); 
    margin-bottom: 5px;
}
.LikedProductsBox{
    width: 100%;
    margin-top: 25px;
}
.LikedProducts{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    margin-top:30px;
}

@media screen and (max-width:1000px) {
    .Main-ListCartBox{
        margin: 35px 20px 35px 20px;
    }
    .ListandPaymentBox{
        flex-wrap: wrap;
        gap: 20px;
    }
    .fullList-box{
        width:100%;
    }
    
}