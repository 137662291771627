@import "../../../values/Colored.css";

.header-drawer{
    z-index: 2000;
}
.main-drawerBox{
    width: 100%;
    min-width:60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
}
.logo-close-Box{
    width: 100%;
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: var(--Top-color);
    padding: 20px 10px 20px 10px ;
}
.mobilebar-Box1{
padding: 0px 10px 0px 10px ;
width: 65px;
}
.textType-btn-light{
    align-self: flex-start;
    margin-left: 20px;
}
.drawer-closeBtn{
    max-width:25px;
    min-width:25px;
    font-size: 10px;
    color: var(--black-color);
}
.drawer-logo-position{
    display:flex;
    align-items: center;
    justify-content: center;
     width:100%;
      gap:10px;
      -webkit-tap-highlight-color: transparent;
      margin-top: 0px;
      padding-right:8px ;
}
.drawerZenOnco-Logo{
    width: 100%;
    max-width:26vh;
  }
.DVD-width{
    width: 100%;
}
.mobile--mainBox2{
    width: 100%;
    padding: 0px 15px 0px 15px ;
}
.mobile-searchbar{
    width: 100%;
    max-width: 650px;
    font-family: FontRegular;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    background-color: var(--mobilesearchbar-bg);
    margin: 10px 0px 0px 0px;
    border: none !important;
    border-radius: 8px;
}
.mobile-searchicon{
    width: 20px;
    height: 20px;
}
.mobile-menubtnbox{
    display: flex;
    flex-direction: column;
     margin-top: 18px ;
     margin-bottom: 20px;
     gap: 15px;
}
.mobile-btntext{
    width: 100%;
    font-family: FontLight;
    font-weight: 600;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--black-Txt);
    border: none !important;
    text-transform: none;
}
.badgeColormobile{
    color:var(--white-color);
  }
  .badgeColormobile1{
    color:var(--black-color);
  }
.mobile--mainBox3{
    width: 100%;
    padding: 20px 0px 0px 0px ;
}
.mobile-btn-light{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
    margin-left: 5px;
}
.textType-btn-Mobile {
    width: 100%;
    flex-grow: 0;
    text-transform: none;
    font-family: FontSemiBold;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.15px;
    text-align: left;
    color: var(--black-color) !important;
    background-color: transparent !important;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 30px;
  }
  .badge-btn{
    align-self: center;
    background-color: transparent !important;
    max-width: 40px;
    min-width: 40px;
    margin-right: 5px;
}
.searchMobile-btn{
    width: 30px;
    background-color: transparent !important;
    margin-top: 5px;
}
.searchMobile-img{
    width: 100%;  
}
  .callMobileLink{
    width: 40px;
    background-color: transparent !important;
  }
  .callMobileImg{
 width: 100%;
  }
  .callMobileImg1{
    width: 100%;
     }
  .cartIcon-img{
    width:30px;
  }
  @media only screen and (max-width: 600px) {
    .badge-btn{
max-width: 30px;
min-width: 30px;
    }
    .callMobileLink{
        min-width: 32px;
      }
      .searchMobile-btn{
        min-width: 24px;
    }
    .callMobileImg1{
      width: 29px;
    }
  }
  @media only screen and (max-width: 415px) {
    .badge-btn{
max-width: 30px;
min-width: 30px;
    }
    .callMobileLink{
        min-width: 28px;
      }
      .searchMobile-btn{
        min-width: 23px;
    }
.cartIcon-img{
    width: 20px;
}
  }
@media only screen and (max-width: 348px) {
    .drawer-logo-position{
          padding-right:2px ;
    }
    .callMobileLink{
        min-width: 25px;
      }
      .searchMobile-btn{
        min-width: 20px;
    }
    .cartIcon-img{
        width: 18px;
    }
  }
  @media only screen and (max-width: 310px) {
    .drawer-logo-position{
          padding-right:-10px ;
    }
  }
