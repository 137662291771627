@import "../../../values/Colored.css";

.MostSearched-MainBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
margin: 0px 77px 0px 77px
}
.MostSearchedtxt-btn-Box{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    text-decoration: none;
    text-transform: none;
    padding: 0px;
    background-color: transparent !important;
}
.MostSearch-btn{
    width: 100%;
    max-width: fit-content;
    max-height:25px;
    min-height:25px;
    text-transform: none;
    display: flex;
margin-left: auto;
}
.mostSearchedLink{
    text-decoration: none;
}
.showBtn{
    font-size:35px;
    color:var(--white-color);
}
.Most-Searched-Txt{
    max-width:100%;
    font-family: FontRegular;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color:var(--black-Txt);
}
.Result-Box{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.Result-Box1{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
}
.Searched-Txt-result-box{
    width: 23%;
    margin:10px 10px 5px 0px;
}
.Searched-Txt-result{
    font-family: FontRegular;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color:var(--black-Txt);
}
@media screen and (max-width:1000px) {
    .MostSearched-MainBox{
        margin: 0px 20px 0px 20px;
        padding-bottom: 20px;
    }
    .Most-Searched-Txt{
    width: 100%;
    }
    .MostSearchedtxt-btn-Box{
max-width: max-content;
min-width: max-content;
margin-right: auto;
    }
}
.dropdown_searchList{
    color: var(--black-Txt);
    font-size: 30px;
}
@media screen and (max-width:600px) {
.Searched-Txt-result-box{
    width: 100%;
    margin:5px 0px 0px 0px;
    padding: 7px;
}
}
