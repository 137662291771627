@import "../../../../values/Colored.css";
.contactdetails-selectbox{
    border-radius: 30px;
    background-color: var(--inputBG);
}
.auto-cancerInfo{
border-color:var(---txt-color) !important;
}
.auto-cancerInfoicon{
 margin-right: -9px;
}
.cancerInfo-input {
    max-width:100%;
max-height:40px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: fontRegular;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.15px;
    border-radius: 4px;
    padding:30px 10px 25px 10px;
    border: solid 1px var(---border1-color);
    color: var(--black-color) !important;
  }
  .cancerInfo-input:hover{
    border: solid 1px var(--black-color);
  }
  .input-items-box{
    margin-top: -15px;
  }
  .cancerInfo-label{
    font-family: fontMedium;
    font-size: 16.5px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.15px;
    text-align: left;
 margin-top:15px;
    color: var(---txt-color) !important;
  }
.contact-inputBox{
    width: 100%;
}
.contetcdetails-imgmainbox1{
    max-width: 100%;
    margin-top: -8px;
    margin-bottom: -15px;
    height: auto;
}
.contetcdetails-txtF-Btn{
    width:100%;
    display: flex;
    flex-direction: row;
    text-transform: none;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    padding: 10px 16px;
    border-radius: 7px;
    background-color: var(--feedback-orange) !important;
}
@media screen and (max-width:413px) {
  .cancerType-menulists{
   white-space:break-spaces;
   width: 100%;
  }
}