@font-face {
  font-family: 'FontBold';
  /* src: local('FontBold'), url('../src/fonts/Quicksand-Bold.ttf') format('truetype'); */
  /* src: local('FontBold'), url('../src/fonts/Poppins-Bold.ttf') format('truetype'); */
  src: local('FontSemiBold'), url('../src/fonts/Poppins-SemiBold.woff2') format('woff2');
  font-display:swap;
}

@font-face {
  font-family: 'FontLight';
  src: local('FontLight'), url('../src/fonts/Poppins-Light.woff2') format('woff2');
  font-display:swap;
}

@font-face {
  font-family: 'FontMedium';
  /* src: local('FontMedium'), url('../src/fonts/Poppins-Medium.ttf') format('truetype'); */
  src: local('FontRegular'), url('../src/fonts/Poppins-Regular.woff2') format('woff2');
  font-display:swap;
}

@font-face {
  font-family: 'FontRegular';
  src: local('FontRegular'), url('../src/fonts/Poppins-Regular.woff2') format('woff2');
  font-display:swap;
}

@font-face {
  font-family: 'FontSemiBold';
  /* src: local('FontSemiBold'), url('../src/fonts/Poppins-SemiBold.ttf') format('truetype'); */
  src: local('FontMedium'), url('../src/fonts/Poppins-Medium.woff2') format('woff2');
  font-display:swap;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.loader-position-Pages{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  align-self: center;
  margin-top:20%;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
