@import "../../../values/Colored.css";

.OfficeLocation-box {
  display: flex;
  flex-direction: column;
    flex-grow: 0;
    padding: 35px 85px 20px 82px;
    border-radius: 41px;
    background-color: var(--darkBlue);
   gap: 30px;
    overflow: hidden;
    background-image: url("/src/images/footerBack.webp");
    background-position:center;
    background-position-x:5vh;
    background-position-y:7vh;
    background-size:auto;
     background-repeat: no-repeat; 
     margin-left: 190px;
     margin-right: 190px;
     margin-bottom:40px;
  }
  .locaions-btnsBox{
    display: flex;
    gap: 50px;
  }
  .Leftside-content-box{
    display:flex;
    flex-direction:column;
    flex-wrap:wrap;
     width:100%;
     gap: 35px;
     margin-top: 5px;
  }
.Footer-Logo{
  max-width: 223px;
  width: 100%;
  text-align: left;
  margin-left: -15px;
  height: max-content;
}
.CareerRelated-numbers-box{
  display:flex;
   gap:5vh;
    width:100%;
}
.For-career-career-related {
  width: 100%;
  max-width: 270px;
  flex-grow: 0;
  font-family:FontSemiBold;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--white-color);
}
.copyRight-box{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 15px;
  margin-top: auto;
}
.copyRight-txt{
  width: 100%;
  max-width: 880px;
  flex-grow: 0;
  font-family:FontRegular;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  margin-right: auto;
  color: var(--footer_txt);
  margin-top: 15px;
}
.Number-Text {
  width: 100%;
  max-width: 223px;
  flex-grow: 0;
  margin: 38px 0 0;
  font-family:FontMedium;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.04;
  letter-spacing: normal;
  text-align: left;
  color: var(--white-color);
}
.Carezenoncoio {
  width: 100%;
  max-width: 278px;
  flex-grow: 0;
  font-family:FontMedium;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.04;
  letter-spacing: normal;
  text-align:left;
  color: var(--white-color);
}
.Rightside-content-box{
  width: 100%;
  max-width: max-content;
  display:flex; 
  flex-direction: column;
  gap: 5px;
  margin-top: -70px;
}
.Rowbtn-box{
  width: 100%;
  max-width: fit-content;
  display:flex;
  align-items: flex-start;
  justify-content: flex-start;
   flex-direction:column;
gap: 3px;
}
.FlexBox{
  display: flex;
  gap:50px;
}
.CareToFaqs-btn{
  width: 100%;
  max-width: max-content;
  text-decoration: none;
  text-transform: none;
  background-color: transparent !important;
  -webkit-tap-highlight-color: transparent !important;
}
.CareToFaqs-Link{
  width: 100%;
  max-width: max-content;
  text-decoration: none;
  text-transform: none;
  padding: 6px 9px 6px 9px;
  background-color: transparent !important;
  -webkit-tap-highlight-color: transparent !important;
}
.Dr-btn{
  width: 100%;
  max-width: max-content;
  text-transform: none;
  text-decoration: none;
  background-color: transparent !important
}
.CareToFaqs-Text {
    flex-grow: 0;
    font-family:FontMedium;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--white-color);
  }
  .SocialMedia-Logo-Box{
    display: flex;
    align-items: flex-end;
    gap: 30px;
  }
  .Privacy-Terms{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: flex-start;
margin-left: -7px;
margin-bottom: 20px;
  }
  .Privacy-TermsDvd{
    height: 15px;
    border-color: var(--copy-rightTxt);
  }
  .Privacy-Termstxt{
    flex-grow: 0;
    font-family:FontSemiBold;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--white-color);
  }
  .iconBtn-Size1{
margin-top: 10px;
width: 100%;
max-width: 40px;
height: max-content;
    color: var(--white-color) !important;
  }
  .iconBtn-Size2{
    margin-top: 10px;
    width: 100%;
    max-width: 49px;
    height: max-content;
    color: var(--white-color) !important;
      }
      .iconBtn-Size3{
        margin-top: 10px;
        width: 100%;
        max-width: 55px;
        height: max-content;
        color: var(--white-color) !important;
          }
          .iconBtn-Size4{
            margin-top: 10px;
            width: 100%;
            max-width: 17px;
            height: max-content;
            color: var(--white-color) !important;
              }
  .Footer-AppPlay-store{
    width: 100%;
    display: flex;
    gap: 5px;
    margin-top: 15px;
    margin-left: 15px;
  }
  .Footer-PlayStore-btn{
width:40%;
    border-radius: 10px;
    -webkit-tap-highlight-color: transparent;
  }
  .Footer-AppStore-btn{
width:41%;
    border-radius: 10px;
    -webkit-tap-highlight-color: transparent;
  }
  .Footer-Store-btns-box{
width:100%;
max-width: 140px;
height: max-content;
  }
  .Footer-Store-btns-box2{
    width:100%;
    max-width: 140px;
    height: max-content;
      }
.Alink{
  -webkit-tap-highlight-color: transparent;
}
.SocialM_heading-txt{
  width: 100%;
  flex-grow: 0;
  font-family:FontRegular;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: var(--footer_txt);
}
.Ngo_SocialMedia-Box{
  display: flex;
  align-items:flex-start;
  justify-content: flex-start;
  gap: 25px;
  flex-wrap: wrap;
  margin-top: 15px;
  margin-left: 15px;
}
.SocialMedia_Main-Box{
  display: flex;
  align-items:center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
}
.SocialMedia-Box{
  display: flex;
  align-items:center;
}
.NGO-Box{
  display: flex;
  align-items:flex-start;
  justify-content:flex-start;
  flex-direction: column;
  gap: 5px;
}
.Youtube-iconSize{
  width:23px;
  margin-left: -10px;
   }
   .Insta-iconSize{
       width:20px;
        }
   .Facebook-iconSize1{
       width:10px;
        }
        .LoveHeals-iconSize{
           width: 100%;
           min-width: 110px;
           margin-left: -10px;
        }
        @media screen and (max-width:1370px) {
          .OfficeLocation-box{
            padding: 30px 30px 20px 30px;
          }
        }
        @media screen and (max-width:1250px) {
          .FlexBox{
            gap:5px;
          }
        }
@media screen and (max-width:1205px) {
  .OfficeLocation-box {
       margin-left:90px;
       margin-right:90px;
    }
}
@media screen and (max-width:1050px) {
  .FlexBox{
    margin-left: -20px;
    gap:0px;
  }
}
  @media screen and (max-width:1000px) {
    .OfficeLocation-box{
      margin-left:20px;
      margin-right:20px;
    }
    .Footer-AppPlay-store{
      margin-left:0px;
    }
    .Ngo_SocialMedia-Box{
      margin-left: 0px;
    }
  }
  @media screen and (max-width:570px) {
    .SocialMedia-Logo-Box{
      gap:10px;
    }
  }
  @media screen and (min-height:1450px){
.OfficeLocation-box {
  background-position-y:center;

  }
}