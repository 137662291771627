@import "../../../values/Colored.css";

.yourJounry-MainBox{
    width: 100%;
    margin: 37px 0px 0px 0px ;
    padding: 0px 50px 0px 50px ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.yourJounry-txtMainBox{
    margin: 0px 0px 10px 0px ;
}
.yourJounry-heading{
    margin: 0px 7.4px 10px 0px;
  font-family: FontSemiBold;
  font-size: 34px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: var(--black-Txt);
  width: 100%;
}
.yourJounry-heading-subtext{
    font-family:FontRegular;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.15px;
    text-align: center;
    margin: 15px 0px 30px 0px;
    color: var( --txtBlack-light) !important;
    width: 100%;
}
.yourJounry-txtMainBox2{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:70px
}
.yourJounry-heading-subtexts{
    margin:16px 15px 2px 0px;
    font-family:FontRegular;
    font-size: 18px;
  font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-Txt);
}
.yourJounry-tik{
    height:18px;
    width: 18px;
   margin-right: 17px;
   position: relative;
   top: 7px;
}
.yourJounry-img{
    width: 100%;
}
.yourJounry-img-box{
 max-width: 320px;
 max-height: 320px;
}
.yourJounry-txtbox{
    display: flex;
    align-items: center;
}
@media only screen and (max-width: 1000px) {
    .yourJounry-txtMainBox2{
       flex-direction: column;
       padding: 0px 0px 0px 0px ;
    }
    .yourJounry-MainBox{
        padding: 0px 20px 0px 20px ;
    }}
    @media only screen and (max-width: 600px) {
        .yourJounry-heading{
            margin: 20px 7.4px -7px 0px;
          font-size: 24px;
          font-weight: 400;
        }
        .yourJounry-heading-subtext{
            font-size: 14px;
            margin: 15px 0px 0px 0px;
        }
        .yourJounry-heading-subtexts{
            font-size: 14px;
            color: var(--Modal-head)
        }
        .yourJounry-txtMainBox{
        min-height: 68px;
        }
        .yourJounry-txtMainBox2{
            padding: 15px 0px 20px 0px ;
         }
         .subtext-despcrition600{
            margin-top: -65px;
            min-height:210px;
         }
         .yourJounry-txtbox{
            padding-left: 16px;
            align-items: normal;
        }
        .yourJounry-img-box{
            max-width: 280px;
           }
        .yourJounry-tik{
            height:16px;
            width: 16px;
            margin-right: 12.5px;
            top: 18px;
        }
      }
  @media only screen and (max-width: 500px) {
    .yourJounry-MainBox{
        padding: 0px 10px 0px 10px ;
    margin: 30px 0px 40px 0px ;

    }
    .yourJounry-txtMainBox2{
        justify-content: flex-start;
    }
  }
  @media only screen and (max-width: 315px) {
    .yourJounry-heading{
        font-size: 21px;
    }
  }
