@import "../../../values/Colored.css";
.AddressBar-mainBox{
    width:100%;
    min-width:100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding:35px 35px;
    border: 1px solid var(--Border-shadow);
    border-radius: 10px;
    gap: 10px;
    overflow: hidden;
}
.redioTo-MenuBox{
    width: 100%;
    display: flex;
    margin-top: -2px;
    flex-direction: column;
    align-items:flex-start;
    justify-content:center;
    gap: 10px;
}
.bar-RadioBtn{
    width: 5px;
    height: 5px;
}
.EditNavigate{
text-decoration: none;
text-transform: none;
color: var(--black-color);
}
.Bar-name-Numbertxt{
    width:100%;
    text-transform: none;
    font-family: FontSemiBold;
    font-size:18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: var(--black-color);
    text-overflow: ellipsis;
    overflow: hidden;
}
.Bar-menuBtn{
    max-width: 15px;
    min-width: 15px;
    max-height: 15px;
    min-width: 15px;
    margin-left: auto;
    color: var(--black-color);

}
.bar-Address-txt{
    width:100%;
    text-transform: none;
    font-family: FontSemiBold;
    font-size:14px;
    font-weight:100;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: var(--text-grey);
    text-overflow: ellipsis;
    overflow: hidden;
}
@media screen and (max-width:350px) {
    .bar-Address-txt{
        max-width: 150px;
    } 
    .Bar-name-Numbertxt{
        max-width: 150px;  
    }
    .AddressBar-mainBox{
        padding:20px 20px;
    }
}