@import "../../../values/Colored.css";

.vicitCenterHeading_main-Box{
  display: flex;
  align-items:center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 40px 77px 50px 77px;
}
.visitCenters_main-Box{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    gap: 20px;
    margin-top: 20px;
}
.ViciteCenter-heading{
  margin: 0px 7.4px 0px 0px;
font-family: FontSemiBold;
font-size: 34px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: 1.22;
letter-spacing: normal;
text-align: center;
color: var(--black-Txt);
width: 100%;
}
.visitCenters-Box{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    max-width:fit-content;
    background-color:var( --centers-BG2);
    border-radius: 24px;
    gap: 10px;
    overflow: hidden;
    padding-bottom: 18px;
}
.visitCenters-Box1{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-width:fit-content;
  background-color:var(--centers-BGs);
  border-radius: 24px;
  gap: 10px;
  overflow: hidden;
  padding-bottom: 18px;
}
.visitCenters-Box2{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-width:fit-content;
  background-color:var(--centers-BG1);
  border-radius: 24px;
  gap: 10px;
  overflow: hidden;
  padding-bottom: 18px;
}
.city-icon{
    width:100%;
}
.icon_address-Box{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
width: 75px;
    background-color:var(--white-color);
    border-radius: 50%;
height: 75px;
    padding: 10px;
    margin-top: -50px;
}
.city_Center-txt{
    font-family:FontSemiBold;
    font-size: 28px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.0;
    letter-spacing: normal;
    text-align: left;
    color:var(--consult-docbtn);
    margin-top: -7px;
  }
  .cancer_Center-txt{
    font-family:FontSemiBold;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-Txt);
  }
  .area_denmark-txt{
    font-family:FontMedium;
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.0;
    letter-spacing: normal;
    text-align: left;
    color: var(--txtBlack-light);
  }
  .area_denmark-txtdark{
    font-family:FontMedium;
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.0;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-color);
  }
  .view_map-Btn{
    display: flex;
    align-items: center;
    justify-content: center;
background-color: transparent !important;
text-decoration: none;
text-transform: none;
width: 100%;
max-width: 130px;
padding: 7px 19px 7px 19px ;
border-radius: 4px;
margin-left: .5px;
background-color: var(--consult-docbtn) !important;
  }
  .view_map-txt{
    font-family:FontRegular;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: normal;
    text-align: left;
    color: var(--white-color);
  }
  .center_image-Box{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
    max-width:350px;
    gap: 7px;
}
.center-img{
    width: 100%;
}
.center-img1{
    width: 100%;
}
.visitCenter-statebox{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
}
.City-name{
  font-family: fontRegular;
font-size: 18px;
  font-weight: 400;
  color: var(--txtBlack-light);
}
.Info-name{
  font-family: fontRegular;
font-size: 18px;
  font-weight: 400;
  color: var(--txtBlack-light);
}
.skeletonRect{
  width: 80%;
  margin:auto;
  height: 300px;
  border-radius: 12px;
}
@media screen and (max-width:1665px) {
    .center-img{
        min-width:31vh;
        min-height: 145px;
    }
}
@media screen and (max-width:1560px) {
            .center-img{
              min-width:30vh;
              max-height: 17vh;
        min-height: 133px;
              }
  }
  @media screen and (max-width:1425px) {
    .center-img{
      min-width:100%;
      }
}
  @media screen and (max-width:915px) {
     .visitCenters_main-Box{
          display: grid;
          grid-template-columns: 1fr 1fr;
              }
        .city_Center-txt{
          margin-top: 0px;
        }
        .center-img{
          width: 50%;
          max-height: 16vh;
        min-height:130px;
          }
  }
  @media screen and (max-width:660px) {
  .visitCenters_main-Box{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
        }
      }
@media screen and (max-width:600px) {
  .vicitCenterHeading_main-Box{
    padding: 40px 17px 25px 17px;
  }  
  .Centers_div{
    width: 100%;
    height:550px;
    min-height: 370px;
    max-height: max-content;
  }
  .City-name{
  font-size: 14px;
  margin-top: 7px;
  }
  .Info-name{
    font-size: 14px;
    margin-top: 7px;
    }
  .view_map-Btn{
padding: 7px 12px;
  }
  .cancer_Center-txt{
    font-family:FontBold;
  
  }
  .area_denmark-txt{
    color: var(--black-Txt);
  }
  .visitCenters-Box{
  padding-bottom:24px;
  }
  .visitCenters-Box1{
    padding-bottom:24px;
    }
    .visitCenters-Box2{
      padding-bottom:24px;
      }
  .ViciteCenter-heading{
  font-size: 24px;
  }
  .icon_address-Box{
  width: 60px;
  height: 60px;
      margin-top: -40px;
  }
  }
@media screen and (max-width:383px) {
  .Info-name{
    font-size: 12px;
    }
  }
@media screen and (max-width:340px) {
  .cancer_Center-txt{
    margin-left: 7px;
    margin-right: 7px;
  }
  .Info-name{
    font-size: 11px;
    }
  }
@media screen and (max-width:309px) {
  .Info-name{
    font-size: 9.8px;
    }
    .ViciteCenter-heading{
      font-size: 23px;
      }
}
