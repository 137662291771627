@import "../../../values/Colored.css";
.connectForm_main-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 37px 20px 37px;
  background-color: var(--connectForm);
  gap: 10px;
  border-radius: 10px;
  max-width: 415px;
  margin-top: 20px;
  width: 100%;
}
.connect-wellness-txt {
  font-family: FontRegular;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: normal;
  text-align: left;
  color: var(--black-Txt);
  margin: 7px 0px;
}
.connectForm_main-name-Box {
  width: 100%;
  border-radius: 4px;
  font-family: FontMedium;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--filled-holdertxt);
  background-color: var(--inputBG) !important;
  box-shadow: 0 0 24px 0 var(--border-black);
  will-change: transform;
  transform: translateZ(0);
}
.country_to-numberBox {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.country_to-numberDivider {
  width: 2px;
}
.CountryconnectForm {
  width: 100%;
  max-width: 101px;
  height: 55px;
  font-family: FontSemiBold;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--Badge-Text);
  border: transparent !important;
  border-color: transparent !important;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: var(--white-color) !important;
  display: flex;
  align-items: center;
  padding: 5px 5px 0px 10px;
  box-shadow: 0 0 24px 0 var(--border-black);
  will-change: transform;
  transform: translateZ(0);
}
.CountryconnectForm {
  border: none;
  will-change: transform;
  transform: translateZ(0);
}

.country-keyboardIcon {
  color: var(--black-color);
  max-width: 20px;
  min-width: 20px;
  max-height: 20px;
  min-height: 20px;
  padding: 0px;
}
.connectForm_main-number-Box {
  width: 100%;
  font-family: FontMedium;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--filled-holdertxt);
  background-color: var(--white-color) !important;
}
.connectForm_submit-Btn {
  width: 100%;
  text-decoration: none;
  text-transform: none;
  background-color: var(--consult-docbtn) !important;
  max-width: 350px;
  padding: 10px;
  color: var(--white-color);
  gap: 15px;
  margin-top: 7px;
}
.connectForm_submit-txt {
  font-family: FontSemiBold;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--white-color) !important;
}
.auto-inputBox {
  font-size: 14px;
  font-family: FontSemiBold;
  height: 55px;
}
.connectForm_alert-box {
  width: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
}
@media screen and (max-width: 601px) {
  .connectForm_main-box {
    margin-top: 5px;
  }
  .CountryconnectForm {
    max-width: 86px;
  }
  .connect-wellness-txt {
    font-size: 16px;
    min-height: 24px;
  }
  .connectForm_submit-txt {
    min-height: 20px;
  }
}
@media screen and (max-width: 350px) {
  .connect-wellness-txt {
    font-size: 14px;
  }
}
@media screen and (max-width: 321px) {
  .CountryconnectForm {
    max-width: 60px;
  }
}
