@import "../../../values/Colored.css";

.Main-CounsultExpert_box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 37px 100px 50px 100px;
  gap: 45px;
}
.CounsultExpert-box {
  width: 100%;
  min-width: 430px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.CounsultSession-box {
  width: 100%;
  min-width: 430px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -80px;
  gap: 10px;
}
.CounsultExpert-Medical-text {
  flex-grow: 0;
  margin: 0 0px 10px 0;
  font-family: FontSemiBold;
  font-size: 34px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: var(--black-Txt);
}
.CounsultExpert-Points {
  flex-grow: 0;
  margin: -13px 0px 0px 1px;
  font-family:FontRegular;
  font-size: 18px;
  font-style: normal;
  line-height: 1.5;
  font-weight: 500;
  letter-spacing: normal;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  color: var(--txtBlack-light);
}
.tik-Icon {
  width:100%;
  max-width: 16px;
  margin-right: 10px; 
}
.tik-Icon1 {
    height:16px;
    width: 16px;
    margin-right: 10px;
    position: relative;
    top: 5px;
}
.CounsultExpert-button {
  max-width: max-content;
  max-height: 35px;
  display: flex;
  text-transform: none;
  text-decoration: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: -13px 0px 0px 0px;
  padding: 10px 30px;
  border-radius: 5px;
  border: 1px solid ;
  text-transform: none;
  font-family: FontRegular;
  flex-grow: 0;
  border-color: var(--darkBlue);;
  background-color:transparent !important;
}
.CounsultExpert-btntext {
  margin-top: 2px;
  display: flex;
  flex-grow: 0;
  font-family: FontSemiBold;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.04;
  letter-spacing: normal;
  text-align: left;
  color: var(--darkBlue);;
}
.CounsultExpert-Cards {
  width: 60%;
  position: relative;
  margin-top: -20px;
}

@media screen and (max-width:1869px) {
  .CounsultExpert-Cards {
    width: 68%;
}}
@media screen and (max-width:1672px) {
  .CounsultExpert-Cards {
    width: 72%;
}}
@media screen and (max-width:1590px) {
  .CounsultExpert-Cards {
    width: 80%;
}}
@media screen and (max-width:1381px) {
  .CounsultExpert-Cards {
    width: 88%;
}}
@media screen and (max-width:1000px) {
  .CounsultExpert-Cards {
    width: 95%;
  }
  .Main-CounsultExpert_box {
    flex-wrap: wrap;
    padding: 55px 20px 40px 20px;
  }
.Allcards-Box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .Endtoend-Medical-text {
    margin: 0 0px 0px 0;
    max-width: none;
    width: 100%;
  }
  .CounsultExpert-Points {
    margin: 0px 0px 0px 0px;
  }
  .CounsultExpert-box {
    min-width: 220px;
    margin-top: -30px;
  }
  .CounsultSession-box {
    min-width: 220px;
    margin-top: -30px;
  }
}
@media screen and (max-width:906px) {
  .CounsultExpert-Cards {
    width: 88%;
}}
@media screen and (max-width:636px) {
  .CounsultExpert-Cards {
    width: 86%;
}}
@media screen and (max-width:600px) {
  .CounsultExpert-Medical-text {
    font-size:24px;
    text-align: center;
  }
  .skeletonProducts{
    width:100%;
    max-width: 220px;
    margin:auto;
    height: 350px;
    border-radius: 12px;
  }
  .CounsultExpert-btntext {
    font-weight: 400;
    letter-spacing: 1px;
  }
  .CounsultExpert-button {
    padding: 1px 45px;
  } 
  .CounsultExpert-button-box{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: -10px;
    margin-bottom: 35px;
  }
  .Main-CounsultExpert_box {
   margin-top: 14px;
   min-height:590px;
  }
  .CounsultExpert-Cards {
    width: 86%;
    margin-top: -32px;
}
}
@media screen and (max-width:553px) {
  .CounsultExpert-Cards {
    width: 82%;
}}
@media screen and (max-width:500px) {
  .CounsultExpert-Cards {
    width: 100%;
}
.Main-CounsultExpert_box {
  padding: 60px 20px 12px 20px;
}
}

