.Survivorscardsbtn-Box{
    display: flex;
    align-self: center;
    align-items: center;
    width: 90%;
   position: absolute;
  }
  .Survivorscardsbtn-prev{
    color: var(--black-color) !important;
    background-color: transparent !important;
    margin-left: -40px;
    min-width: 18px;
    max-width: 38px;
    height: 38px;
    border: 2px solid;
    border-color: var(---border1-color);
    border-radius: 50%;
 }
 .Survivorscardsbtn-next{
    display: flex;
    flex-direction: column;
    margin-left: auto;
    color: var(--black-color) !important;
    background-color: transparent !important;
    border: 1px solid;
    border-radius: 50%;
    margin-right: -40px;
    min-width: 18px;
    max-width: 38px;
    height: 38px;
    border: 2px solid;
    border-color: var(---border1-color);
    border-radius: 50%;
 }
@media screen and (max-width:1629px) {.Survivorscardsbtn-Box{width: 87}}
@media screen and (max-width:1350px) {.Survivorscardsbtn-Box{width: 83}}
@media screen and (max-width:1000px) {.Survivorscardsbtn-Box{width: 88.5%}}
@media screen and (max-width:880px) {.Survivorscardsbtn-Box{width: 80%}}
@media screen and (max-width:842px) {.Survivorscardsbtn-Box{width: 83%}}
@media screen and (max-width:760px) {.survivorsMain-box{padding: 0px 35px 0px 35px ;}.Survivorscardsbtn-Box{width: 86%}}
@media screen and (max-width:570px) {.Survivorscardsbtn-Box{width: 63%}}
@media screen and (max-width:473px) {.Survivorscardsbtn-Box{width: 70%;}}
@media screen and (max-width:435px) {.Survivorscardsbtn-Box{width: 73%;}}
@media screen and (max-width:415px) {.Survivorscardsbtn-Box{width: 75%;}}
@media screen and (max-width:340px) {.Survivorscardsbtn-Box{width: 73%;}}