@import "../../values/Colored.css";

.Products-MainBox{
    overflow: hidden;
}
.Products-Header{
    min-height:55px;
position:sticky;
z-index:1001;
}

.loader-position-DetailsP{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    align-self: center;
    margin-top:100px;
    margin-bottom: 100px;
  }