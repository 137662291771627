
.PDeatailHead-Box{
    width: 100%;
    background-color: var(--event-darkblue);
 padding: 0px 0px 40px 0px;
}
.PDEatlis-Navigation{
    margin:40px 0px 0px 0px;
}
.Navigater-seprate{
    color: var(--white-color);
}
.medecine-Details-Box{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 25px;
}
.medecine-allImg-Box{
    max-width:380px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    gap: 15px;
    margin-bottom:-350px;
    background-color: var(--white-color);
    margin-top:30px;
}
.head-imgBox{
height:400px;
width:380px;
    overflow: hidden;
    border: solid 1px var(--Border-shadow);
}
.head-img{
    width: 100%;
    height: 400px;
}
.secondary-image-Box{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
   gap:5px;
}
.secondary-images-button{
     max-height:90px;
    min-height: 90px;
    max-width: 90px;
    min-width: 90px; 
    background-color: var(--white-color) !important;
    border: solid 1px var(--Border-shadow);
    cursor: pointer;
    position: relative;
}
.selected-Sec-button{
        max-height:90px;
        min-height: 90px;
        max-width: 90px;
        min-width: 90px;
        background-color: var(--white-color) !important;
        border: solid 1px var(--event-orange);
        overflow: hidden;
        cursor: pointer
}

.secondary-images{
    max-height:90px;
    min-height: 90px;
    max-width: 90px;
    min-width: 90px;  
}
.medicine-desc-box{
    max-width:400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:flex-start;
    gap: 15px;
    margin-top:50px; 
}
.medicineOnco-txt{
    text-transform: none;
    font-family: FontSemiBold;
    font-size:32px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: var(--white-color);
}
.medicine-quantity-txt{
    flex-grow: 0;
    font-family: FontRegular;
    font-size:14px;
    font-weight:400;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.98;
    letter-spacing: normal;
    color: var(--wellness-grey); 
}
.rating-Box{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap:10px;
}
.medicine-discP-txt{
    text-transform: none;
    font-family: FontRegular;
    font-size:20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: var(--white-color);
}
.medicine-orgP-txt{
    text-decoration: line-through;
    text-transform: none;
    font-family: FontRegular;
    font-size:18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: var(--white-color);
}
.disc-Percentage-txt{
    text-transform: none;
    font-family: FontRegular;
    font-size:16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: var(--event-orange);
}
.quantity-btn-Box{
display: flex;
align-items: center;
justify-content: center;
padding:5px;
gap:20px;
background-color: var(--white-color);
border-radius: 5px;
}
.quantity-allBox{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.plus-minusBtn{
    text-transform: none;
    max-width:25px;
    min-width:25px;
    max-height:25px;
    min-height:25px;
    background-color: var(--Border-shadow) !important;
    color: var(--white-color) !important;
    border-radius: 24px;
}
.plus-minusBtn1{
    display: flex;
    max-width:25px;
    min-width:25px;
    max-height:25px;
    min-height:25px;
    background-color: var(--consult-docbtn) !important;
    color: var(--white-color) !important;
    border-radius: 24px;
}
.quantity-txt{
    width: 100%;
    min-width:2.5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: none;
    font-family: FontSemiBold;
    font-size:12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: var(--black-color) !important;  
}
.addTocart-btn {
    width:130px;
    max-height: 40px;
    display: flex;
    flex-direction: row;
    text-transform: none;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 23.4px 26.50px 23.4px 7px;
    padding: 10px 16px;
    border-radius: 4px;
    background-color: var(--consult-docbtn) !important;
    color:var(--white-color) !important;
  }
  .addTocart-text{
    flex-grow: 0;
    font-family: FontSemiBold;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.15px;
    text-align: center;
    color: var(--white-color);
  }

  @media screen and (max-width:1000px) {
    .medecine-Details-Box{
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
    .medecine-allImg-Box{
        margin-bottom:0px;
    }
    .head-imgBox{
        width:100%;
        max-width: 350px;
    }
    .addTocart-btn{
        margin: 23.4px 0px 23.4px 0px;
    }
  }
  @media screen and (max-width:500px) {
    .head-imgBox{
        width:100%;
        max-width: 300px;
        height:300px;
    }
    .medecine-allImg-Box{
        max-width: 300px;
        margin-bottom:0px;
    }
    .head-img{
        width: 100%;
        max-width: 300px; 
    height: 300px;
    }
    .medecine-Details-Box{
        align-items: center;
        justify-content: center;
    }
  }

  @media screen and (min-height:1000px) {
    .PDeatailHead-Box{
      padding-top: 50px;
           }
  }
  @media screen and (min-height:1350px) {
    .PDeatailHead-Box{
      padding-top: 100px;
           }
  }
  @media screen and (min-height:1750px) {
    .PDeatailHead-Box{
      padding-top: 150px;
           }
  }