@import "../../values/Colored.css";

.notFoundMain-box{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 750px;
}
.notFound-txt{
    font-family: FontSemiBold;
    font-size: 42px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.15px;
    text-align: center;
    margin: 15px 0px 0px 0px;
    color: var(--black-Txt) !important;
}
@media screen and (max-width:600px) {
    .notFoundMain-box{
        min-height: 550px;
    }
    .notFound-txt{
        font-size: 24px;
    }
}