@import '../../../values/Colored.css';

.TreatmentIndia-mainbox {
  padding: 25px 100px 0px 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--Top-color);
}
.TreatmentIndia-headingtext {
  margin: 10px 0px 0px 0px;
  font-family: Helvetica;
  font-family: FontSemiBold;
  font-size: 34px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: center;
  color: var(--black-Txt);
}
.TreatmentIndia-subbox {
  margin: 18px 0px 50px 0px;
  max-width: 90%;
}
.TreatmentIndia-subtext {
  font-family: FontRegular;
  font-size: 16px;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: var(--black-Txt);
  height: 185px;
  overflow: hidden;
  text-align: left;
}
.TreatmentIndia-subtext2 {
  font-family: FontRegular;
  font-size: 16px;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: var(--black-Txt);
  text-align: left;
}
.TreatmentIndia-readmorebtn {
  font-family: FontRegular;
    font-weight: normal;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.15px;
    color: var(--black-Txt);
    text-transform: none;
    text-decoration: underline;
    cursor: pointer;
}
@media only screen and (max-width: 1681px) {
  .TreatmentIndia-subtext {
    height: 220px;
  }
}
@media only screen and (max-width: 1530px) {
  .TreatmentIndia-subtext {
    height: 249px;
  }
}
@media only screen and (max-width: 1450px) {
  .TreatmentIndia-subbox {
    max-width: 85%;
  }
}
@media only screen and (max-width: 1324px) {
  .TreatmentIndia-subtext {
    height: 290px;
  }
}
@media only screen and (max-width: 1260px) {
  .TreatmentIndia-subbox {
    max-width: 100%;
  }
  .TreatmentIndia-subtext{
        height: 248px;
    }
}
@media only screen and (max-width: 1155px) {
  .TreatmentIndia-subtext{
    height: 289px;
}
}
@media only screen and (max-width: 1000px) {
  .TreatmentIndia-mainbox {
    padding: 40px 30px 0px 30px;
  }
}
@media only screen and (max-width: 745px) {
  .TreatmentIndia-subtext {
    height: 340px;
  }
}
@media only screen and (max-width: 695px) {
  .TreatmentIndia-subtext {
    height: 380px;
  }
}
@media only screen and (max-width: 615px) {
  .TreatmentIndia-mainbox {
    padding: 28px 20px 0px 20px;
  }
}
@media only screen and (max-width: 600px) {
  .TreatmentIndia-subtext {
    height: 295px;
    font-size: 15px;
    font-style: 400;
    font-family: FontLight;
    text-align: left;
  }
  .TreatmentIndia-subbox {
    margin: 15px 0px 45px 0px;
  }
  .TreatmentIndia-headingtext {
    font-size: 24px;
  }
  .TreatmentIndia-readmorebtn {
    font-size: 14px;
  }
  .TreatmentIndia-mainbox{
    background-color: var(--Top-color);
  }
}
@media only screen and (max-width: 553px) {
  .TreatmentIndia-subtext2 {
    font-size: 14px;
    font-style: 400;
    font-family: FontLight;
    letter-spacing:  -0.154px;
    line-height: 24px;
    text-align: left;
  }
  .TreatmentIndia-subtext {
    height: 343px;
    font-size: 14px;
    font-style: 400;
    letter-spacing:  -0.154px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 510px) {
  .TreatmentIndia-subtext {
    height: 390px;
  }
}
@media only screen and (max-width: 485px) {
  .TreatmentIndia-subtext {
    height: 380px;
  }
}
@media only screen and (max-width: 445px) {
  .TreatmentIndia-subtext {
    height: 415px;
  }
} 
@media only screen and (max-width: 430px) {
  .TreatmentIndia-subtext {
    height: 437px;
  }
} 
@media only screen and (max-width: 403px) {
  .TreatmentIndia-subtext {
    height: 486px;
  }
}
@media only screen and (max-width: 365px) {
  .TreatmentIndia-subtext {
    height: 511px;
  }
}
@media only screen and (max-width: 354px) {
  .TreatmentIndia-subtext {
    height: 558px;
  }
}
@media only screen and (max-width: 333px) {
  .TreatmentIndia-subtext {
    height: 590px;
  }
}
@media only screen and (max-width: 321px) {
  .TreatmentIndia-subtext {
    height: 630px;
  }
}
@media only screen and (max-width: 305px) {
  .TreatmentIndia-subtext {
    height: 655px;
  }
}
@media only screen and (max-width: 291px) {
  .TreatmentIndia-subtext {
    height: 703px;
  }
}
@media screen and (min-width:2400px) {
  .TreatmentIndia-subtext {
    height: 160px;
  }
}
@media screen and (min-width:4600px) {
  .TreatmentIndia-subtext {
    height: 120px;
  }
}