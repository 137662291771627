@import "../../../values/Colored.css";

.hjourney-mainbox {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 10px 44px 10px;
    gap: 70px;
  }
  .hjourney-img{
    width: 104%;
    max-width: 480px;
    cursor: pointer;
  }
  .hjourney-subbox{
    display: flex;
    flex-direction: column;
    gap: 55px;
  }
  .hjourney-subbox1{
    display: flex;
    justify-content: flex-start;
    align-items: center;
     gap: 70px;
     width: 100%;
  }
  .hjourney-innerbox{
    display: flex;
    gap : 15px;
    width: 100%;
  }
  .hjourney-avtarbox{
   width: 90px;
   height: 90px;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 50%;
  }
  .hjourney-avatar{
  width: 100%;
  max-width: 70px;
  min-height: 70px;
  }
  .hjourney-avatar1{
    width: 100%;
    max-width: 50px;
    min-height: 50px;
    }
  .hjourney-fitythousand {
    margin: 0px 7.4px 0 0px;
    font-family:FontSemiBold;
    font-size: 32px;
    font-weight: 600;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-color);
  }
    .onelakhs-box{
      height: 20px;
    }
  .hjourney-onelakhs{
    margin: 0px 7.4px 0 0px;
    font-family:FontRegular;
    font-size: 32px;
    font-weight: 400;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-color);
  }
  .hjourney-desp{
    width: 105%;
    margin: 0px 7.4px 0 0px;
    font-family:FontRegular;
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-Txt);
  }
  .AlertSTd{
   position:absolute;
  }
  .hjourney-satisfaction{
    margin: 0px 7.4px 0 0px;
    font-family:FontRegular;
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-Txt);
    width: 100%;
  }
  @media only screen and (max-width: 1358px) {
    .hjourney-img{
      width: 100%;
      max-width: 420px;
    }
    .hjourney-subbox1{
       gap: 20px;
    }
    .hjourney-innerbox{
      gap :10px;
    }
  }
  @media only screen and (max-width: 1268px) {
    .hjourney-img{
      max-width: 350px;
    }
    .hjourney-subbox1{
       gap: 10px;
       padding-left: 10px;
    }
    .hjourney-innerbox{
      gap :10px;
    }
  }
  @media only screen and (max-width: 1190px) {
    .hjourney-subbox1{
       gap: 10px;
    }
  }
  @media only screen and (max-width: 1098px) {
    .hjourney-fitythousand {
      font-size: 36px;
    }
    .hjourney-onelakhs{
      font-size: 28px;
    }
    .hjourney-desp{
      font-size: 22px;
    }
    .hjourney-satisfaction{
      font-size: 22px;
    }
  }
  @media only screen and (max-width: 1050px) {
    .hjourney-mainbox {
      flex-direction: column;
    padding: 40px 10px 40px 10px;
    }
    .hjourney-fitythousand {
      font-size: 34px;
    }
  }
  @media only screen and (max-width: 663px) {
    .hjourney-fitythousand {
      font-size: 33px;
    }
    .hjourney-desp{
      font-size:22px;
    }
  }
  @media only screen and (max-width:628px) {
    .hjourney-innerbox{
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    }
    .hjourney-subbox{
      gap: 50px;
    }
    .hjourney-subbox1{
      justify-content: flex-start;
      align-items:flex-start;
    }
    .hjourney-fitythousand {
      font-size: 33px;
      text-align: center;
    }
    .hjourney-desp{
      text-align: center
    }
    .hjourney-satisfaction{
      text-align: center
    }
    .hjourney-avtarbox{
      width: 80px;
      height: 80px;
     }
     .hjourney-avatar{
      max-width: 85px;
      min-height: 85px;
      }
  }
  @media screen and (max-width:600px) {
    .hjourney-fitythousand {
      font-size: 24px;
       margin-left: 5px;
    }
    .hjourney-mainbox {
      flex-direction: column;
    padding: 40px 10px 30px 10px;}
    .hjourney-avatar{
      max-width: 65px;
      min-height: 65px;
      margin-right: 10px;
      }
    .hjourney-desp{
      font-size: 14px;
      font-family:FontLight;
      font-weight: 600;
    }
    .hjourney-satisfaction{
      font-size: 14px;
      font-family:FontLight;
      font-weight: 600;
      max-width: fit-content;
    }
  }
  @media only screen and (max-width:450px) {
    .hjourney-desp{
    max-width: fit-content;
    }
  }