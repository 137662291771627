@import "../../../values/Colored.css";

.mainPaymentBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 35px 20px;
    background-color: var(--light-Skyblue);
    border-radius: 5px;
}
.PriceDetail-txt {
    width: 100%;
    text-transform: none;
    font-family: FontSemiBold;
    font-size: 24px;
    font-weight: 500;
    flex-grow: 0;
    border-radius: 4px;
    margin-bottom: 10px;
}
.priceTotalBox {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 10px 0px;
}
.price-item-txt {
    text-transform: none;
    font-family: FontBold;
    font-size: 17px;
    font-weight: 500;
    flex-grow: 0;
    border-radius: 4px;
}
.Price-txt {
    text-transform: none;
    font-family: FontSemiBold;
    font-size: 17px;
    font-weight: 500;
    flex-grow: 0;
    border-radius: 4px;
    margin-left: auto;
}
.Disc-apply-txt {
    text-transform: none;
    font-family: FontBold;
    font-size: 15px;
    font-weight: 500;
    flex-grow: 0;
    border-radius: 4px;
    margin-left: auto;
    color: var(--event-orange);
}
.DVD-Width {
    width: 100%;
}
.FinalPriceMainBox {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.finalPrice-Box {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 15px 0px;
}
.finalTotal-txt {
    text-transform: none;
    font-family: FontBold;
    font-size: 18px;
    font-weight: 500;
    flex-grow: 0;
    border-radius: 4px;
}
.finalPrice-txt {
    text-transform: none;
    font-family: FontBold;
    font-size: 18px;
    font-weight: 500;
    flex-grow: 0;
    border-radius: 4px;
    margin-left: auto;
}
.CheckOut-btn {
    max-height: 35px;
    display: flex;
    flex-direction: row;
    text-transform: none;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px 16px;
    border-radius: 4px;
    background-color: var(--consult-docbtn) !important;
    color: var(--white-color) !important;
}
.CheckOut-text {
    flex-grow: 0;
    font-family: FontSemiBold;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.15px;
    text-align: center;
    color: var(--white-color);
}
.couponBox {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 20px 0px 0 0px;
    gap: 20px;
}