@import "../../../values/Colored.css";

.ChatbotBox{
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    bottom: 20px;
    left: 25px;
    max-width: 375px;
    box-shadow:0 0 28px 0 var(--border-black) ;
    border-radius: 18px;
    overflow: hidden;
    background-color: var(--white-color);
    z-index: 2000;
}

.ChatHeadinfo_Box{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background-color: var(--consult-docbtn);
    background:var(--consult-docbtn);
    padding: 18px 25px;
    gap: 20px;
}
.expertDP{
    width: 58px;
    height: 57px;
    border-radius: 50px;
    background-color: var(--white-color);
}
.expertHeadName{
    width: 100%;
    font-family: FontMedium;
    font-weight: 600;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--white-color);
    border: none !important;
    text-transform: none;
}
.Chatbot_point{
    font-size: 22px;
    color: var(--Chatbox-send);
}
.expertInfo-txt{
    width: 100%;
    font-family: FontSemiBold;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height:20px;
    letter-spacing: 0.7px;
    color: var(--white-color);
    border: none !important;
    text-transform: none;
    gap:2px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.chattingMain-Box{
    display: flex;
    flex-direction: column;
width: 100%;
background-color: var(--white-color);
padding: 20px 20px 0px 18px;
height: 422px;
overflow-y: auto;
gap: 2px;
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;
}
.chattingMain-Box::-webkit-scrollbar {
    display: none;
  }
.chatInput_sendBox{
    display: flex;
align-items: center;
justify-content: flex-start;
width: 100%;
background-color: var(--white-color) !important;
height: 70px;
box-shadow: 0px -5px 10px 0px var(--card-shodow);
}
.SendMsg-Box{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: auto;
    padding-bottom: 15px;
}
.chattingMain-Box > :first-child {
    margin-top: auto !important;
    /* use !important to prevent breakage from child margin settings */
}
.ReplyMsg-Box{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 35px;
}
.senderName-txt{
    font-family: FontRegular;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--filled-holdertxt);
    border: none !important;
    text-transform: none;
    text-align: right;
    margin-left: auto;
}
.sendMesg-txt{
    font-family: FontLight;
    font-size: 14px;
    font-stretch: normal;
    font-weight: 600;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--white-color);
    border: none !important;
    text-transform: none;
    background-color: var(--Chatbox-send);
    padding: 16px 18px;
    text-align: right;
border-radius:  25px 0px 25px 25px;
margin-left: auto;
max-width: 337px;
}
.ReplyName-txt{
    font-family: FontRegular;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--filled-holdertxt);
    border: none !important;
    text-transform: none;
    text-align: right;
    margin-left: 18px;
}
.ReplyMesg-txt{
    font-family: FontRegular;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--ChatBox-coachTxt);
    border: none !important;
    text-transform: none;
    background-color: var(--Chatbox-replay);
    padding: 16px 15px;
    text-align: left;
border-radius: 25px 25px 25px 0px;
margin-left: right;
max-width: 310px;
}
.ChatBot-holder-Box{
    width:100%;
    border-radius: 4px;
    font-family: FontLight;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var( --filled-holdertxt);
    background-color: transparent !important;
    box-shadow:0 0 24px 0 var(--border-black) ;
    margin-bottom: auto;
    height: 70px;
}
.Chatinput-inputBox{
    font-size: 14px;
    font-family: FontRegular;
     height: 100%;
     background-color: transparent !important;
     box-shadow: none;
     color: var(--black-color);
     margin-left: 15px;
  }
  .more_powerBtn-Box{
    margin-left: auto;
    padding: 5px 10px 0px 0px;
  }
  .ChatMoreBtn{
    max-width: 30px;
    min-width: 30px;
    max-height: 20px;
    min-height: 20px;
    color: var(---txt-color);
  }
  .ChatMoreIcon{
    font-size: 30px;
  }
  .ChatPowerBtn{
    max-width: 25px;
    min-width: 25px;
    max-height: 25px;
    min-height: 25px;
    color: var(---txt-color);
  }
  .ChatPowerIcon{
    font-size: 17px;
  }
  .ChatSendBtn{
    max-width: 33px;
    min-width: 33px;
    max-height: 33px;
    min-height: 33px;
    background-color: var(--white-color) !important;
    margin-right: 8px;
  }
  .chatSendIcon{
    width:100%
  }
  .loading {
    font-weight: bold;
    font-family: monospace;
    clip-path: inset(0 3ch 0 0);
    animation: l 1.3s steps(4) infinite;
    height: 15px;
  }
  
  @keyframes l {
    to {
      clip-path: inset(0 -1ch 0 0)
    }
  }

@media screen and (max-width:450px) {
    .ChatbotBox{
        position: sticky;
        width: 95%;
        bottom: 10px;
        margin: 10px;
    }
}
@media screen and (max-width:330px) {
    .expertHeadName{
        font-size: 16px;
    }
    .Chatinput-inputBox{
         margin-left:0;
      }
      .ChatSendBtn{
        max-width: 20px;
        min-width: 20px;
        margin-right:0px;
      }
}
@media screen and (max-width:300px) {
    .expertHeadName{
        font-size: 14px;
    }
    .ChatHeadinfo_Box{
        padding: 18px 20px;
    }
}
@media screen and (max-height:678px){
    .chattingMain-Box{
    height: 290px;
    }
    .sendMesg-txt{
        padding: 14px 14px;}
        .ReplyMesg-txt{
            padding: 14px 14px;}
}