@import "../../../values/Colored.css";

.LoveHeals-mainBox{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--darkBlue);
    padding: 77px;
}
.LoveHeals-box{
width: 40%;
margin-left: 50px;
}
.Love-TextImage {
width:100%;
max-width:55%;
}
.Heals-Text {
  margin: 40.5px 0px 13px 0;
  font-family: FontSemiBold;
  font-size: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.46;
  letter-spacing: normal;
  text-align: left;
  color: var(--white-color);
}
.All-numbersText-box{
    display: flex;
    flex-direction: column;
    width:60%;
    justify-content: center;
    align-items: center;
    gap:75px;
}
.Years-to-Cities{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin: auto;
  gap:95px;
}
.Text100 {
width: 340px;
  margin: 0px 0px 0px 0px;
  font-family: Helvetica;
  font-size: 5.4vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.46;
  letter-spacing: normal;
  text-align: left;
  color: var(--white-color);
}
.care-team-advisorText {
width:max-content;
  margin:35px 0px 0px 0px;
  font-family: Helvetica;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: var(--white-color);
}
.Text5 {
  max-width: 114px;
  margin: -25px 0px 0px 0px;
  font-family: Helvetica;
  font-size: 5.4vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.98;
  letter-spacing: normal;
  text-align: left;
  color: var(--white-color);
}
.Text5 .text-style-1 {
  font-size: 2.3vw;
}
.experience-in-caregivingText {
  margin: 7px 0px 0px 0px;
  font-family: Helvetica;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: var(--white-color);
}
.Text7200 {
  width:340px;
  margin: 0px 0px 27px 0px;
  font-family: Helvetica;
  font-size: 5.4vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.46;
  letter-spacing: normal;
  text-align: left;
  color: var(--white-color);
}
.people-guidedText {
  max-width: 148px;
  margin: 27px 0px 0px 0px;
  font-family: Helvetica;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: var(--white-color);
}
.Text200 {
  max-width: 217px;
  margin: 0px 0px 0px 0px;
  font-family: Helvetica;
  font-size: 5.4vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.46;
  letter-spacing: normal;
  text-align: left;
  color: var(--white-color);
}
.cities-Text {
  max-width: 51px;
  margin: 27px 0px 0px 0px;
  font-family: Helvetica;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: var(--white-color);
}
@media screen and (max-width:1000px) {
  .LoveHeals-mainBox{
    flex-wrap: wrap;
    padding: 20px;
  }
  .Love-TextImage{
width:140px;
max-width:140px;
  }
  .Heals-Text{
    font-size:60px;
    margin: 15px 0px 13px 0;
  }
  .All-numbersText-box{
    flex-wrap: wrap;
    margin-top: 70px;
    align-items: flex-start;
    justify-content: flex-start;
    gap:55px;
  }
  .Years-to-Cities{
    gap:55px;
  }
  .Text100{
font-size: 52px;
  }
  .care-team-advisorText{
    margin: 11px 16px 0px 0px;
  }
  .Text7200{
    font-size: 52px;
    margin: 0px 0px 0px 0px;
  }
.people-guidedText{
  margin: 11px 0px 0px 0px;
}
  .Text5{
    font-size: 52px;
    margin: -15px 0px 0px 0px;
  }
  .Text5 .text-style-1 {
    font-size:36px;
  }
  .Text200{
    font-size: 52px;
  }
  .cities-Text{
    margin: 11px 0px 20px 0px;
  }
  .Box200-Cities{
    justify-content:center;
    display:flex;
     flex-direction:column;
  }
  .LoveHeals-box{
    width: 100%;
     margin-left: 0px;
    display: flex;  
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width:876px){
  .All-numbersText-box{
    margin-left: 12%;
    width: 100%;
   }
   .Text7200 {
    width:280px;
    font-size: 6.4vw;
  }
  .Text5 {
    max-width: 114px;
    font-size: 6.4vw;
  }
  .Text5 .text-style-1 {
    font-size: 3.3vw;
  }
  .Text100 {
    width: 280px;
      font-size: 6.4vw;
  }
  .Text200 {
    max-width: 217px;
    font-size: 6.4vw;
  }
  }
  @media screen and (max-width:505px){
  .All-numbersText-box{
    margin-left: 15px;
    width: 100%;
   }
}
  @media screen and (max-width:465px){
     .Text7200 {
      width:220px;
    }
    .Love-TextImage{
      width:100%;
      max-width:150px;
        } 
    .Text5 {
      max-width: 14px;
    }
    .Text200 {
      max-width: 27px;
    }
    .care-team-advisorText {
      width:70%;
      }
    }
    @media screen and (max-width:420px){
      .All-numbersText-box{
        margin-left: 0px;
        width: 100%;
       }
       .Text7200 {
        width:150px;
        font-size: 7.4vw;
        margin-left: 20px;
      }
      .Text5 {
        font-size: 7.4vw;
      }
      .Text5 .text-style-1 {
        font-size: 4.3vw;
      }
      .Text100 {
        width: 150px;
        font-size: 7.4vw;
        margin-left: 20px;
      }
      .Text200 {
        max-width: 27px;
        font-size: 7.4vw;
      }
      .care-team-advisorText {
        margin-left: 20px;
        }
        .cities-Text {
          max-width: 51px;
          font-size: 20px;
        }
        .people-guidedText {
          max-width: 148px;
          font-size: 120%;
         margin-left: 20px;
        }
      }
      @media screen and (max-width:360px){
         .Text7200 {
          width:90px;
        }
        .Text100 {
          width: 90px;
        }
        .Text200 {
          margin-left: -8px;
        }
        .care-team-advisorText {
          width:100%;
          min-width: 100px;
          }
          .cities-Text {
          margin-left: -8px;
          }
          .people-guidedText {
            max-width: 150px;
          }
        }
        @media screen and (max-width:320px){
          .care-team-advisorText {
            max-width:90px;
            min-width: auto;
            margin-left: 5%;
            }
            .Text7200 {
              margin-left: 5%;
            }
            .Text100 {
              margin-left: 5%;
            }
            .people-guidedText {
              max-width:90px;
              margin-left: 5%;
            }
            .Text200 {
              margin-left: -0px;
            }
            .cities-Text {
            margin-left: -0px;
            }
        }
        @media screen and (min-width:2000px) {
          .Love-TextImage {
            width:100%;
            max-width:45%;
            }
            .Text100 {
                font-size: 5vw;
              }
              .Text5 {
                font-size: 5vw;
              }
              .Text5 .text-style-1 {
                font-size: 2vw;
              }
              .Text7200 {
                font-size: 5vw;
              }
              .Text200 {
                font-size: 5vw;
              }
        }
        @media screen and (min-width:2500px) {
          .Love-TextImage {
            width:100%;
            max-width:45%;
            }
            .Text100 {
                font-size: 4vw;
              }
              .Text5 {
                font-size: 4vw;
              }
              .Text5 .text-style-1 {
                font-size: 1.5vw;
              }
              .Text7200 {
                font-size: 4vw;
              }
              .Text200 {
                font-size: 4vw;
              }
        }
        @media screen and (min-width:2500px) {
          .Love-TextImage {
            width:100%;
            max-width:35%;
            }
            .Text100 {
                font-size: 3vw;
              }
              .Text5 {
                font-size: 3vw;
              }
              .Text5 .text-style-1 {
                font-size: 1vw;
              }
              .Text7200 {
                font-size: 3vw;
              }
              .Text200 {
                font-size: 3vw;
              }
        }
        