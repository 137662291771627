@import "../../../values/Colored.css";
.videocardTopBox{
  margin: 60px 0px 0px 0px;
    width: 100%;
    padding: 0px 77px 0px 77px;
}
.VideoPagination-Box{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 0px 50px 0px;
}
.dwnload-DrTalk{
  width:100%;
  display:flex;
  align-items:center;
  justify-content:center;
 }
 @media screen and (max-width:700px){
  .videocardTopBox{
    margin: 40px 0px 20px 0px;
    padding: 0px 20px 0px 20px;
  }
  .dwnload-DrTalk{
margin-top: 20px;
   }
  .VideoPagination-Box{
    margin: 30px 0px 30px 0px;
  }
 }