@import "../../../../values/Colored.css";

.treatmentmodel-dialog{
    border-radius: 20px;
    max-width: 500px;
}
.treatmentModal-MainBox{
    width: 100%;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    padding:2px 15px 20px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.treatMent-txt-imgBox{
    display: flex;
    gap: 6px;
}
.tretment-img{
    width: 50%;
    height: auto;;
}
.treatmentModal-Box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:28px 50px 28px 50px;
}
.otpsentbox{
    width: 100%;
   flex-direction: row;
   display: flex;
   justify-content: center;
   gap: 7px;
}
.treatmentModal-title-text{
    font-family:FontBold;
    font-style: normal;
    font-size: 17.5px;
    text-align: center;  
    color: var(--Modal-head);
}
.treatmentModal-txtF-Box{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 25px 0px 15px 0px;
}
.treatmentModal-Booklabel {
    flex-grow: 0;
    font-family: FontSemiBold;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.15px;
    text-align: center;
    color: var(--white-color);
  }
.treatmentModal-nametxtF-Box{
    width: 100%;
    border-radius: 8px;
    font-family: FontRegular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    background-color: var(--inputBG) !important;
}
.MuiInput-underline.Mui-disabled:before {
    border-bottom: 1px solid ;
  }
  .CountryConnectCode{
    width:85px;
    max-width:inherit;
        font-family: FontSemiBold;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: var(--Badge-Text);
        border: transparent !important;
        border-color: transparent !important;
        border-radius: 5px;
        z-index: 1400;
    }
.numberTyp-txtField{
    width: 100%;
    border-radius: 8px;
    font-family: FontRegular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    background-color: var(--inputBG) !important;
}
.treatmentModal-txtF-Btn{
    width:100%;
    display: flex;
    flex-direction: row;
    text-transform: none;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    padding: 10px 16px;
    border-radius: 7px;
}
.treatmentModal-countrycode{
    color: var(--wellness-grey); 
}
.treatmentModal-selectbox{
   width: 19%;
   margin-left: -8px;
}
.treatmentModel-imgmainbox{
    display: flex;
    justify-content: center;
    margin-top: -35px;
}
.treatmentModel-imgmainbox1{
    width: 80%;
    margin-bottom: 25px;
}
.treatmentModel-img{
    width: 100%;
    height: auto;    
}
.treatmentModal-txtF-Box2{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: -19px;
    margin-bottom: 50px;
    padding: 20px 40px 15px 40px;
    background-color: var(--inputBG) !important;
}
.modal-closeBtn{
    max-width:25px;
    min-width:25px;
    font-size: 10px;
    margin-left: auto;
    margin-right: 15px;
    margin-bottom: auto;
    margin-top: 5px;
    color: var(--black-color);
    background-color: transparent !important;
}
.Treatment-verfiybtn{
    width:100%;
    max-width: max-content;
    display: flex;
    text-transform: none;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 5px;
    padding: 10px 16px;
    border-radius: 7px;
}
.Treatment-verfiybtntext{
    width: 100%;
    flex-grow: 0;
    font-family: FontSemiBold;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    text-align: center;
    color: var(--white-color);
}
.OTPsentext{
    display: flex;
  font-family: FontSemiBold;
  color: var(--text-doctor);
}
.OTPsentext1{
    font-family: FontSemiBold;
  }
  .treatment-muiotpinput{
    max-width:310px;
  }
  @media screen and (max-width:567px) {
    .treatmentModal-Box{
        padding:28px 37px 28px 37px;
    }
    .treatmentModal-txtF-Box2{
        padding: 20px 32px 15px 32px;
    }
    .treatmentModal-title-text{
        font-size: 99%;
    }
  }
  @media screen and (max-width:517px) {
    .otpsentbox{
        flex-direction: column;
        align-items: center;
     }
     .treatMent-txt-imgBox{
       margin-left: 16px;
    }
  }
   @media screen and (max-width:467px) {
    .treatmentModal-selectbox{
        width: 28%;
     }
    .treatmentModal-Box{
        padding:28px 27px 28px 27px;
    }
    .treatmentModal-txtF-Box2{
        padding: 20px 22px 15px 22px;
    }
    .CountryConnectCode{
        width: 50px;
    }
    .treatmentModal-MainBox{
        margin-top: -15px;
        padding:0px 15px 20px 15px;
    }
}
@media screen and (max-width:446px) {
    .treatment-muiotpinput{
        width: 100%;
        max-width:100%;
        min-width: 205px;
      }
    .treatmentModal-Box{
        padding:28px 22px 28px 22px;
    }
    .treatmentModal-txtF-Box2{
        padding: 20px 20.5px 15px 20.5px;
    }
}
@media screen and (max-width:330px) {
    .treatmentModal-selectbox{
        width: 48%;
     }
     .treatmentModal-txtF-Box2{
        padding: 20px 1px 15px 1px;
    }
}
