@import '../../../values/Colored.css';

.Main-ExpertSession {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin:66px 0px 0px 0px;
    padding: 55px 100px 40px 100px;
    gap: 45px;
  }
  .ExpertSession-Cards {
    width: 60%;
    position: relative;
    margin-top: -10px;
  }
  .ExpertSession-divider{
    margin-top: 10px;
    padding: 0px 15px 0px 15px ;
   }
   .ExpertSession-dvd{
    background-color: var( --expert-dvd);
   }
   @media screen and (max-width:1869px) {
    .ExpertSession-Cards {
      width: 68%;
  }}
  @media screen and (max-width:1672px) {
    .ExpertSession-Cards {
      width: 72%;
  }}
  @media screen and (max-width:1590px) {
    .ExpertSession-Cards {
      width: 80%;
  }}
  @media screen and (max-width:1381px) {
    .ExpertSession-Cards {
      width: 88%;
  }}
  @media screen and (max-width:1000px) {

    .Main-ExpertSession {
      flex-wrap: wrap;
      padding: 5px 20px 40px 20px;
    }
    .ExpertSession-Cards {
        width: 95%;
      }
}
@media screen and (max-width:906px) {
    .ExpertSession-Cards {
      width: 88%;
  }}
  @media screen and (max-width:636px) {
    .ExpertSession-Cards {
      width: 86%;
  }}
@media screen and (max-width:600px) {
    .Main-ExpertSession {
        margin-top: 1px;
        padding: 40px 20px 12px 20px;
        min-height: 410px;
    }
    .ExpertSession-Cards {
        width: 86%;
        margin-top: 2px;
        min-height: 350px;
    }
}
@media screen and (max-width:553px) {
    .ExpertSession-Cards {
      width: 82%;
  }}
  @media screen and (max-width:500px) {
    .ExpertSession-Cards {
      width: 100%;
  }
}
