@import "../../../values/Colored.css";

.Filter-AppdwnldBox{
  margin: 50px 0px 10px 0px;
  padding:0px 0px 0px 0px;
  border-radius: 24px;
  min-width:320px;}
  .popup-dwnloadBox{
    width: 100%;
    display: flex;
    flex-direction: column;}
  .Download-closeBtnpop{
    max-width:25px;
    min-width:25px;
    font-size: 10px;
    margin-left: auto;
    margin-bottom: auto;
    color: var(--black-color);}
  .DwnldApp-Text{
    max-width:250px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: var(--white-color);
    margin: 20px 0px 20px 0px;
}
.Filter-Dwnld-img{
    width:100%;
    max-width: 300px;
    height: max-content;
}
.popup-dwnload-BoxP{
  margin-left: 25px;
  margin-top: 10px;
}
@media screen and (max-width:1000px) {
    .Filter-AppdwnldBox{
        max-width:100%;
        margin: 10px 0px 0px 0px;
      }
      .DwnldApp-Text{
        margin: 25px 0px 0px 0px;
      }
      .Filter-Dwnld-img{
        width:100%;
        max-width: 100%;
      }
      .popup-dwnload-BoxP{
        margin-left:0px;
        margin-top: 10px;
      }
}
@media screen and (max-width:330px) {
  .Filter-AppdwnldBox{
      width: 100%;
      min-width:100%;
    }
}