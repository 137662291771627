@import "../../../values/Colored.css";

.Products-Card{
    display: flex;
    flex-direction: column;
    text-decoration: none;
    text-transform: none;
    padding: 15px;
    margin: 0px 0px 30px 0px;
   border:solid 1px  var(--Products-brdr) !important;
}
.naviagtion-card{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-decoration: none;
    text-transform: none;
    background-color: transparent !important;
    -webkit-tap-highlight-color: transparent;
}

.ProductsCard-Image{
    width: 100%;
    max-height: 230px;
    min-height: 230px;
    max-width:230px;
    min-width: 230px;
}
.ProductsName-txt{
    max-width:230px;
    min-height:50px;
    flex-grow: 0;
    font-family: FontRegular;
     font-size: 16px;
     font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: var(--black-Txt);
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-top: 15px;  
    text-align: left;
}

.ProductsInfo-txt{
    min-height:18px;
    display: -webkit-box;
    flex-grow: 0;
    font-family: FontLight;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: var(--txtBlack-light);
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.ProductRatings-Box{
display: flex;
gap: 10px;
margin: 10px 0px;
}
.RatingBtn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-transform: none;
    font-family: FontBold;
    font-size:12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    padding:1px 9px;
    border-radius:5px;
    color: var(--white-color);
    background-color: var(--event-orange) !important;
    margin-top: auto;
    border-radius: 1px;
    max-width:max-content;
}
.RatingTxt{
    text-transform: none;
    font-family: FontSemiBold;
    font-size:12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: var(--text-doctor);
}
.ProductPrice-AddBox{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    margin: 15px 0px;
}
.Price-discountBox{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.Product-PriceTxt{
    text-decoration: line-through;
    text-transform: none;
    font-family: FontRegular;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: var(--black-Txt);
}
.discount-Percentage{
    text-transform: none;
    font-family: FontBold;
    font-size:12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: var(--consult-docbtn);
}
.Discounted-PriceTxt{
    text-transform: none;
    font-family: FontRegular;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: var(--black-Txt);
    margin: 10px 0px 0px 0px;
}
.ADD-CartBtn{
    max-width:100%;
    height:35px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-transform: none;
 padding: 5px 3px;
    border-radius:5px;
    border: solid 1px var(--Products-brdr) !important;
    background-color: var(--consult-docbtn) !important;
}
.ADD-txt{
    text-transform: none;
    font-family: FontRegular;
    font-size: 13px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: var(--white-color);
    padding: 0px 5px 0px 5px;
}
@media screen and (max-width:1050px) {
    .Products-Card{
        max-width: fit-content;
        margin: auto;
        margin-left: 5px;
    }
}

@media screen and (max-width:900px) {
.ProductsCard-Image{
    max-height: 230px;
    min-height: auto;
    max-width:auto;
    min-width: auto;
}
}
@media screen and (max-width:625px) {

    .Products-Card{
        padding: 10px;
        margin: auto;
    }
    .ProductsCard-Image{
    width: 100%;
    max-height: auto;
    min-height: 130px;
    max-width:auto;
    min-width: auto;
}
.ProductsInfo-txt{
min-height: 29px;
font-family: FontLight;
            font-size: 12px;
            font-weight: 600;
            color: var(--black-Txt);
}
.ProductsName-txt{
    min-height:60px;
    font-family: FontLight;
    font-weight: 600;
        font-size: 16px;
        color: var(--text-event);
}
.Product-PriceTxt{
    font-family: FontRegular;
    font-size: 13px;
    font-weight: 400;
    color: var(--black-Txt);
}
.Discounted-PriceTxt{
    font-family: FontRegular;
    font-size: 13px;
    font-weight: 400;
    color: var(--black-Txt);
}
.ADD-txt{
    font-family: FontLight;
    font-size: 13px;
    font-weight: 600;
}
}
@media screen and (max-width:520px) {
    .ProductPrice-AddBox{
        flex-direction: column;
        flex-wrap: wrap;
        margin-top: 0px;
    }
    .ProductsName-txt{
        font-size: 16px;
    }

}
@media screen and (max-width:375px) {
    .ProductsName-txt{
        min-height:79px;}
}
@media screen and (max-width:345px) {
    .Price-discountBox{
        flex-direction: column;
        flex-wrap: wrap;
        height: 55px;
    }
}
@media screen and (max-width:328px) {
    .Products-Card{
        width: 100%;
        min-width: 100%;
        padding: 10px;
    }
    .ProductsCard-Image{
    width: 100%;
    max-height: 70px;
    min-height: 70px;
    max-width:70px;
    min-width:70px;
}
.ProductPrice-AddBox{
    flex-wrap: wrap;
}
.ProductsName-txt{
    min-height:79px;
}

.Product-PriceTxt{
    margin-right: auto;
}
}
@media screen and (max-width:285px) {
.ProductsName-txt{
    font-size: 15px;
    min-height:79px;
}
}
