@import "../../../values/Colored.css";

.DoctorTalk-MainBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.DoctorTalk-Text{
    max-width: 639px;
    margin: 102.3px 0px 16.5px 0px;
  font-size:32px;
    font-family:FontLight;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: normal;
    text-align: center;
    color: var(--white-color);
}
.TalkQueries-Text{
    max-width: 699px;
    margin: 16.5px 0px 0px 0px;
    font-family:FontRegular;
    font-weight: 400;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: var(--white-color);
}

.doctorFilterBox{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0px 25px 0px; 
    gap: 7px;
}
.DoctorTalk-ChipsBox{
    display: flex;
    gap:7px;
}
.DoctorTopic-Chip{
    width:100px;
height:45px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-transform: none;
    border-radius: 70px;
    font-family: FontSemiBold;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    padding: 0px 20px;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-color);
    background-color: var(--white-color);
    overflow: hidden;
}
.DoctorTopic-Chip1{    width:250px;
    max-width: max-content;
height:45px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-transform: none;
    border-radius: 70px;
    font-family: FontSemiBold;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    padding: 0px 20px;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-color);
    background-color: var(--white-color);
    overflow: hidden;}

.MuiSelect-select{
    margin-top: 3px;
    }
    .MuiSelect-select:focus{
        background-color: var(--white-color);
    }

.DoctorTalk-txt{
    width: 100%;
    font-family: FontRegular;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    margin-left: -15px ;
    color: var(--black-color) !important;
    margin-top:17px;
}

  @media screen and  (max-width:1000px) {
    .DoctorTalk-ChipsBox{
        flex-wrap: wrap;
    }
    .doctorFilterBox{
        align-items: center;
        justify-content: center;
    }
  }
  @media screen and (max-width:600px) {
    .TalkQueries-Text{
      font-size: 16px;
    }
    .DoctorTalk-Text{
        font-family: FontRegular;
        font-size:32px;
        font-weight: normal;
    }
    .doctorFilterBox{
        align-items: flex-start;
        justify-content: flex-start;
    }
  }
  @media screen and (max-width:376px) {
    .doctorFilterBox{
  margin-left: -7px;
     }
  }
  @media screen and (min-height:1000px) {
    .DoctorTalk-MainBox{
      padding-top: 50px;
           }
  }
  @media screen and (min-height:1350px) {
    .DoctorTalk-MainBox{
      padding-top: 100px;
           }
  }
  @media screen and (min-height:1750px) {
    .DoctorTalk-MainBox{
      padding-top: 150px;
           }
  }