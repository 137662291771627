@import "../../../values/Colored.css";

.Main-Awards-RecognitionBox{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 30px 77px 50px 77px ;
    background-color: var(--light-grey);
}
.AllCards-Box{
     width: 100%; 
    gap:22.5px; 
}
.AllCards-Slide{
  width: 100%;
padding: 0px 10px 0px 25px;
-webkit-tap-highlight-color: transparent;
}
.typo-box-awards{
  height:auto;
   width:100%;
    display:flex;
    justify-content:center;
     align-items:center;
      margin-bottom:-15px;
}
.Awards-Card{
max-width:100%;
height:100%;
align-self: center;
    flex-grow: 0;
    margin: 0px 22px 0 0px;
    padding: 12px 12px 40px 12px;
    border-radius: 15px;
   text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-shadow: 0 0 24px 0 var(--border-black);
    background-color: var(--white-color);
}
.Awards-Text-box{
  width: 100%;
}
.Awards-Text {
    margin: 0px 0px 48px 0px;
    font-family: Helvetica;
    font-size: 32px;
    font-family: FontSemiBold;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-color);
  }
.Card-Image-Box{
    flex-grow: 0;
    margin: 0 0 22px 0px;
    width:100%;
    height:230px;
}
.Card-categoryText-Box{
    align-self: stretch;
    flex-grow: 0;
    font-family: FontRegular;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.92;
    letter-spacing: normal;
    text-align: left;
    color: var( --black-color);
    margin: 0px 0px 0px 7px;
}
.Recognized-carosualbox{
  width: 100%;
  padding: 30px 120px 40px 118px ;
}
@media screen and (max-width:1472px) {
  .Recognized-carosualbox {
padding: 50px 100px 18px 100px }
}
@media screen and (max-width:1161px) {
  .Recognized-carosualbox {
padding: 40px 80px 5px 80px}
}
@media screen and (max-width:1350px) {
  .AllCards-Slide{
  flex-direction:row;}
}
@media screen and (max-width:1000px) {
    .Main-Awards-RecognitionBox{
      padding: 20px;
    }
    .AllCards-Box{
  flex-wrap: wrap;
    }
    .Awards-Text{
      font-size: 36px;
    }
    .AllCards-Slide{
      align-items:center;
      justify-items:center;
      padding: 0px 10px 0px 10px;
    }
}
@media screen and (max-width:560px) {
  .Recognized-carosualbox {
padding: 40px 30px 14px 50px}
}