@import "../../../values/Colored.css";

.Main-Pastevent-Box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 160px 0px 160px;
    margin-bottom: 50px;
}
.pastevents-Box{
    width: 100%;
}
.EventTextBox{
    width: 100% ;
    display: flex;
    flex-direction: column;
 align-items: flex-start;
    justify-content:left;
}
.pastEvents-txt{
   width:100%;
    margin: 60px 0px 32px 1%;
    font-family: FontBold;
    font-size: 36px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.96;
    letter-spacing: normal;
text-align: left;
color: var(--black-Txt);
}

.EventVideo-infoBox1{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

@media screen and (max-width:1000px) {
    .Main-Pastevent-Box{
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0px 20px 0px 20px;
    }
}