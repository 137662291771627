@import "../../../values/Colored.css";
.OverComing-Cancer{
    display: -webkit-box;
    max-width:230px;
    min-height: 50px;
    flex-grow: 0;
    margin: 16.3px 0px 16.3px 6.4px;
    font-family: FontRegular;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    color: var(--black-Txt);
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}
.mainsurvivors-box{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100%;
}
.Blogs-desc-txt{
  display: -webkit-box;
  max-width:250px;
  flex-grow: 0;
  margin: 0px 0px 22px 6.4px;
  font-family: FontSemiBold;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  height:70px;
  line-height: 1.25;
  letter-spacing: normal;
  color: var(--black-color);
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow:hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.Avatar-Name-Box{
    display: flex;
    gap: 10px;
    margin: 10px 0px 40px 0px;
}
.Events-Avatar {
width: 50px;
height: 50px;
    flex-grow: 0;
    margin: 0 9.2px 0 0;
  }
.Oncologist-Name-Text{
    max-width:100%;
   flex-grow: 0;
  font-family: FontRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
    align-self: center;
    color: var(--black-color);
}
.Blog-Chip-btn {
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: FontSemiBold;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  padding: 2px 12px;
  border-radius: 6px;
  background-color: var(--Badge-Color) !important;
  color: var(--Badge-Text);
  margin-top:-18px;
  word-break: break-all;
}
.BlogEvent-Card{
  max-width: max-content;
    text-transform: none;
    text-decoration: none;
    padding: 15px;
    margin: 0px 0px 30px 0px;
    border: solid 1px var(--wellness-grey);
    border-radius: 14px;
-webkit-tap-highlight-color: transparent;
margin: auto;
margin-bottom: 30px;
  }
  .BlogEvent-Card1{
    max-width: max-content;
    text-transform: none;
    text-decoration: none;
    padding: 15px;
    margin: 0px 0px 0px 0px;
    border-radius: 12px;
-webkit-tap-highlight-color: transparent;
  }
  .BlogEventSearch-Card{
    text-transform: none;
    text-decoration: none;
    width: 100%;
    max-width:260px;
    padding: 15px;
    margin: 0px 0px 30px 0px;
    border: solid 1px var(--wellness-grey);
    border-radius: 14px;
-webkit-tap-highlight-color: transparent;
  }
  .thanksBox-Card{
    text-transform: none;
    text-decoration: none;
    padding: 15px;
     -webkit-tap-highlight-color: transparent;
      width: 95%;
  }
  .thanks-joinCards{
    text-transform: none;
    text-decoration: none;
    padding: 15px;
     -webkit-tap-highlight-color: transparent;
      width: 95%;
      height: 400px;
  }
  .BlogEvent-Card:hover{
    box-shadow: 0 4px 23px 0 var(--Border-shadow);
    background-color: var(--white-color);
    border-radius: 14px;
  }
  .searchEvent-mainbox{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Events-Videos{
    width: 100%;
    min-width:250px;
    max-width:250px;
  min-height:225px;
  max-height:225px;
 border-radius: 22px;
  }
  .EventsSearch-Videos{
    width: 100%;
    min-width:250px;
    max-width:260px;
  min-height:225px;
  max-height:225px;
 border-radius: 22px;
  }
  .survivorsPage-Videos{
    width: 100%;
    max-width:250px; 
  min-height:225px;
  max-height:225px;
 border-radius: 22px;
  }
  .thanksBox-Videos{
    width: 100%;
    min-width:230px;
    max-width:250px;
  min-height:225px;
  max-height:225px;
 border-radius: 22px;  }
.thankYou-BtnBox{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
max-width:250px;
}
.thankYou-joinBtn{
    width: 167px;
    height: 40px;
    display: flex;
    flex-direction: row;
    text-transform: none;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 23.4px 0px 0px 0px;
    padding: 10px 16px;
    border-radius: 7px;
    background-color: var(--consult-docbtn) !important;
}
.survivarText-title{
  max-width:100%;
  display: -webkit-box;
    flex-grow: 0;
    font-family: FontSemiBold;
    margin: 16.3px 0px 1.3px 6.4px;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    height: 70px;
    line-height: 1.25;
    letter-spacing: normal;
    color: var(--black-color);
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    margin-top: 15px;
}
.survivarText{
  flex-grow: 0;
  font-family: fontRegular;
  font-size: 15px;
  font-weight: normal;
  margin: 0px 0px 0px 10px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: var(--black-color);
  width: 100%;
  text-align: left;
}
.survivarText2{
  flex-grow: 0;
  font-family: FontMedium;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43; 
  letter-spacing: -0.15px;
  text-align: center;
  color: var(--orange);
}
.thankYou-jointext{
    flex-grow: 0;
    font-family: FontMedium;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.15px;
    text-align: center;
    color: var(--white-color);
  }

  @media screen and (max-width:1000px) {
    .Events-Videos{
      min-height:270px;
      max-height:270px;
    }
    .EventsSearch-Videos{
      min-height:270px;
      max-height:270px;
    }
    .OverComing-Cancer{
      margin: 16.3px 0px 16.3px 0px;
      min-height: 50px;    }
    .Blogs-desc-txt{
      margin: 0px 0px 22px 0px;
    }
    .Avatar-Name-Box{
      gap: 1%;
  }
  .thanks-joinCards{
      width: 92%;
  }
  .thanksBox-Card{
      width: 92%;
  }
  .BlogEvent-Card{
 max-width: fit-content;
 margin-bottom: 10px;
  }
    }
    @media screen and (max-width:660px){
      .Events-Videos{
        min-width:auto;
        max-width:auto ;
        min-height:200px;
        max-height:200px;
      }
      .EventsSearch-Videos{
        min-width:auto;
        max-width:auto ;
        min-height:150px;
        max-height:150px;
      }
      .thanksBox-Videos{
        min-width:200px
    }
      .OverComing-Cancer{
        width: 100%;
        font-size: 115%;
        word-break: break-word;
        min-height:50px;
      }
      .BlogEvent-Card{
        width: 100%;
        max-width:260px;
        padding: 10px;
        margin: 0px 0px 10px 0px;
      }
      .BlogEventSearch-Card{
        max-width:228px;
      }
    }
    @media screen and (max-width:560px){
      .Blog-Chip-btn {
        width: 100%;
        font-size: 12px;
        padding: 2px 0px;
      }
  }
    .Oncologist-Name-Text{
    font-size: 120%;
  }
@media screen and (max-width:400px) {
  .BlogEvent-Card{
    margin: auto;
          }}
  @media screen and (max-width:330px){
    .Events-Videos{
      min-height:120px;
    }
    .EventsSearch-Videos{
      min-height:120px;
    }
  .Avatar-Name-Box{
    gap: 0px;
}
.Oncologist-Name-Text{
font-size: 14px;
}
.survivarText-title{
    height: 80px;
}
  }
    @media screen and (max-width:350px) {
      .Events-Videos{
        min-width:40vw;
        max-width:100%;
      }
      .EventsSearch-Videos{
        min-width:40vw;
        max-width:100%;
      }
      .OverComing-Cancer{
        height:auto;
      }
      .BlogEventSearch-Card{
        width: 80%;
        max-width:fit-content;
      }
      .thanksBox-Videos{
        min-width:180px
    }
    .BlogEvent-Card{
      max-width:250px;
            }
    }