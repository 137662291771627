@import "../../../values/Colored.css";

.MsgMain-box{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 77px 77px 0px 77px;
    background-color: var(--Top-color);
}
.thankYou-txt{
    flex-grow: 0;
    margin: 0 0 23px;
    font-family:cursive;
    font-size:36px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: left;
    color: var(--orange);
}

.ourAim-txt{
    flex-grow: 0;
    margin: 0 0 23px;
    font-family: FontRegular;
    font-size:18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-color);
}

.Helping-pointsBox{
display: flex;
gap:70px;
}

.helpingPoints{
    flex-grow: 0;
    margin: 0 0 23px;
    display: flex;
    font-family: FontRegular;
    font-size:18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-color);  
    gap: 10px;
}

.points{
    align-self: center;
    height:5px;
    width:5px;
    background-color: var(--orange);
    border-radius: 50%;
    display: inline-block;
}

.immidiateResponce-txt{
    flex-grow: 0;
    font-family: FontRegular;
    font-size:18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-color);
    padding-bottom: 40px;
}

.thanks-imageBox{
    width:100%;
    display: flex;
    align-items: center;
    justify-content:flex-end;
    margin-top: auto;
    margin-top: -30px;
}

.thankYou-DRimage{
    width:100%;
    max-width:500px;
}
@media screen and (max-width:1167px) {
    .thanks-imageBox{
        margin-top: 30px;
    }
}
@media screen and (max-width:1074px) {
    .thanks-imageBox{
        margin-top: 100px;
    }
}
@media screen and (max-width:1000px) {
    .MsgMain-box{
        flex-wrap: wrap;
        margin-bottom: 77px;
        padding: 70px 20px 0px 20px; 
    }
    .thanks-imageBox{
        margin-top: 1px;

    }
    .thankYou-DRimage{
        width:65%;
    }

    .Helping-pointsBox{
        flex-wrap: wrap;
        gap:30px
    }
}
@media screen and (max-width:500px) {
    .Helping-pointsBox{
        flex-wrap: wrap;
        gap:0px
    }
    .helpingPoints{
        margin: 0 0 15px 0px;
    }
}
@media screen and (min-height:1050px) {
    .MsgMain-box{
      padding-top: 100px;
           }
  }
  @media screen and (min-height:1350px) {
    .MsgMain-box{
      padding-top: 150px;
           }
  }
  @media screen and (min-height:1750px) {
    .MsgMain-box{
      padding-top: 190px;
           }
  }