@import "../../../values/Colored.css";

.heading-Session-txt{
    flex-grow: 0;
    font-family: FontSemiBold;
    font-size: 32px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.96;
    letter-spacing: normal;
text-align: center;
margin-right: auto;
margin-top: -10px;
margin-bottom: -20px;
    color: var(--black-color);
}

.joinEventsMain-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 100px 50px 100px;
}

.all-eventcards{
    width: 100%;
    align-items: center;
  justify-items:center;
}

.joinEventsMain{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    max-width: 300px;
}
.joinEventsMain-carouselBox{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.all-Survivorscards{
    width: 100%;
    display: flex;
    align-items: center;
  justify-items:center;
gap: 5px;
-webkit-tap-highlight-color: transparent;
}

@media screen and (max-width:1000px) {
    .joinEventsMain-box{
        margin: 20px;
       
    }
    .joinEventsMain-box{
        padding: 0px 30px 0px 30px;
        margin-top: -30px;
    }
   
}
@media screen and (max-width:570px) {
    .heading-Session-txt{
        font-size: 30px;
    }
}
@media screen and (max-width:473px) {
    .heading-Session-txt{
        font-size: 28px;
    }
}