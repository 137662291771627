@import "../../../values/Colored.css";

.WellnesscareFaq-Box{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:30px;
    margin: 30px 0px 0px 20px;
}

.FaqCareWellness-Text {
width:22%;
  font-family: FontSemiBold;
  font-size: 36px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.95;
  letter-spacing: normal;
  align-self: flex-start;
  margin: 20px 0px 0px 0px;
  color: var(--black-color);
  min-width:320px;
}
.FaqSearch-Box{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.FAQsearch-Box-input{
  width: 100%;
  max-width:400px;
    border-radius: 8px;
    font-family: FontRegular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    background-color: var(--inputBG);
}
@media screen and (max-width:1250px) {
  .FaqCareWellness-Text {
      font-size: 32px;
    }
}
@media screen and (max-width:1129px) {
  .FaqCareWellness-Text {
      font-size: 29px;
    }
}
@media screen and (max-width:1000px) {

  .WellnesscareFaq-Box{
  flex-direction: column;
    padding: 0px 20px 0px 20px;
    margin: 10px 0px 0px 0px;
  }
  .FaqCareWellness-Text{
    font-size:24px;
    width: 80%;
    min-width:0px;
  }
  .FaqSearch-Box{
padding: 10px;
  }
}