@import "../../../values/Colored.css";


.orderDetails-Main-Box{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 70px;
    margin: 35px 77px 50px 77px;
}
.products-View-Box{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}