@import "../../values/Colored.css";


.blogPage-header{
    position: sticky;
    z-index: 5;
    
}
.blogPage-Footer{
    background:var(--footer_BG-Color);
    padding-top: 30px;
}
