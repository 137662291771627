@import "../../../values/Colored.css";

.StoriesHeadBox{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 160px -60px 77px;
}
.StoriesHead-Text{
  margin: 64px 0px 29.4px 0px;
  font-family:FontLight;
    font-weight: 600;
  font-size:32px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  text-align: left;
  color: var(--white-color);
}
.oneplace-Text {
  max-width:650px;
  margin: 29.4px 0px 12.5px 0px;
  font-family:FontLight;
    font-weight: 600;
  font-size:32px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: var(--white-color);

}
.Storiesdesc-Text {
  max-width: 420px;
  margin: 12.5px 0px 46.1px 0px;
  font-family:FontRegular;
  font-weight: 400;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: left;
  color: var(--white-color);
}
.Stories-headImg {
  width: 325.7px;
  height: 325.7px;
  margin: 90.9px 0px 50.5px 0px;
}
.StoriesFilterBox{
  display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 0px 25px 0px; 
    gap: 7px;
}
.stories-chipsBox{
  display: flex;
  gap:7px;
}
.storie-txt{
  width: 100%;
  font-family: FontSemiBold;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  margin-left: -15px ;
  margin-top: 22px;
  color: var(--black-color) !important;
}

.StorTopic-chip1{
  width:250px;
  max-width: max-content;
height:45px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-transform: none;
  border-radius: 70px;
  font-family:FontSemiBold;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  padding: 0px 20px;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: var(--black-color);
  background-color: var(--white-color);
  overflow: hidden;
}
.StorTopic-chip{
  width:100px;
  height:45px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      text-transform: none;
      border-radius: 70px;
      font-family:FontSemiBold;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      padding: 0px 20px;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: center;
      color: var(--black-color);
      background-color: var(--white-color);
      overflow: hidden;
}
.selected-BlogBtn{
  max-height:45px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-transform: none;
 padding: 12px 35px;
  border-radius: 70px;
  color: var(--black-color);
  background-color: var(--consult-docbtn) !important;
  margin-top: auto;
}
  .MuiSelect-select{
      margin-top: 3px;
      }
      .MuiSelect-select:focus{
          background-color: var(--white-color);
      }
.StoriesAvatar-Box{
  margin-left: auto;
}
.StoriesCardsBox{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px 77px 50px 77px;
}
.StoriesBtn{
  width: 100%;
  max-width: 200px;
  display: flex;
  text-transform: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 77.5px 0px 104.2px 33.22px;
  padding: 14px 24px;
  border-radius: 8px;
  background-color: var(--feedback-orange) !important;
  color:var(--white-color) !important;
}

.Stories-btnTxt{
  flex-grow: 0;
  font-family: FontRegular;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: var(--white-color) !important;
}
@media screen and (max-width:1000px) {

 .StoriesHeadBox{
  flex-wrap: wrap-reverse;
  margin: 0px 0px 0px 0px;
 }
 .StoriesCardsBox{
  margin: 30px 20px 30px 20px;
}
 .StoriesAvatar-Box{
  margin-left:0px;
 }
 .Stories-headImg{
  width: 100%;
  height: 100%;
 }
 .StoriesHead-Text{
  margin:0px 0px 0px 0px;
 }
 .oneplace-Text{
  margin: 15px 0px 12.5px 0px;
 }
 .StoriesFilterBox{
  align-items: flex-start;
  justify-content:flex-start;
 }
 .StoriesBtn{
  margin: 77.5px 0px 104.2px 0px;
 }
 .Storiesdesc-Text{
  margin: 12.5px 0px 10px 0px;
 }
 .stories-chipsBox{
  flex-wrap: wrap;
 }
}

@media screen and (max-width:600px) {
  .Storiesdesc-Text{
    font-size: 16px;
  }
  .oneplace-Text{
    font-family: FontRegular;
      font-size:32px;
      font-weight: normal;
  }
  .StoriesHead-Text{
      font-family: FontRegular;
      font-size:32px;
      font-weight: normal;
  }
  .storie-txt{
    font-family:FontLight;
    font-size: 13px;
font-weight: 600;
  }
}
@media screen and (max-width:380px) {
  .StoriesFilterBox{
margin-left: -5px;
   }
}
@media screen and (max-width:320px) {
  .StoriesFilterBox{
margin-left: -10px;
   }
}
@media screen and (min-height:1000px) {
  .StoriesHeadBox{
    padding-top: 50px;
         }
}
@media screen and (min-height:1350px) {
  .StoriesHeadBox{
    padding-top: 100px;
         }
}
@media screen and (min-height:1750px) {
  .StoriesHeadBox{
    padding-top: 150px;
         }
}