@import "../../../values/Colored.css";

.Treatment-MainBox1{
  margin: 0px 0px 40px 0;
  padding: 160px 80px 93.8px 160px;
background: var(--event-darkblue);
}

.Stories-Text {
  font-family: fontSemiBold;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: var(--white-color);
}
.linkClass{
  text-transform: none;
  text-decoration: none;
cursor: pointer;
}
.arrow_forward {
    max-width:20px;
  flex-grow: 0;
  color: var(--white-color);
}

.DoctorTalks-Text {
    margin: 0px 0px 0px 10px;
  font-family: fontSemiBold;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: var(--white-color);
}

.ShimogaCancer-Text {
  max-width: 735px;
  margin: 28px 0px 12.6px 0px;
  font-family: FontMedium;
  font-size: 46px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.12;
  letter-spacing: normal;
  text-align: left;
  color: var(--white-color);
}

.May27-Text {
  margin: 12.6px 1.5px 0 0px;
  font-family: fontSemiBold;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: var(--white-color);
}

@media screen and (max-width:1000px) {
  .Treatment-MainBox1{
    padding: 80px 20px 20.8px 20px;
  }
  .ShimogaCancer-Text {
    font-size: 26px;
    margin: 25px 0px 6px 0px;
  }
}
@media screen and (min-height:1500px) {
  .Treatment-MainBox1{
    padding-top: 200px;
         }
}
@media screen and (min-height:1750px) {
  .Treatment-MainBox1{
    padding-top: 250px;
         }
}