@import "../../../values/Colored.css";

.main-Box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 77px 50px 77px;
  gap: 20px;
}
.Videoand360-Box {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.AllTxt-BtnBox {
  width: 44%;
  margin-left: 13%;
}
.Buttons-box {
  display: flex;
}
.Members-Succes-box {
  display: flex;
  gap: 40px;
}
.degree-360 {
  width: 100%;
  margin: 5% 0px 12.9px 0px;
  font-size: 34px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--black-Txt);
  font-family: FontLight;
  font-weight: 600;
}
.Improve {
  margin: 17.9px 0px 0px 0px;
  font-family: FontRegular;
  font-weight: 400;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: var(--black-Txt);
}
.download-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin: 31.9px 15px 10px 0px;
  width: 100%;
  max-width: max-content;
  min-width: 160px;
  text-transform: none;
  font-size: 14px;
  flex-grow: 0;
  padding: 9px 20.5px 9px 12.5px;
  border-radius: 4px;
  gap: 7px;
  background-color: var(--consult-docbtn) !important;
}
.dwnld-text {
  width: 100%;
  flex-grow: 0;
  font-family: FontSemiBold;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.15px;
  text-align: center;
  color: var(--white-color);
  min-height: 20px;
}
.call-Us-btn {
  width: 100%;
  max-width: 165px;
  min-width: 165px;
  display: flex;
  text-decoration: none;
  text-transform: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 7px;
  margin: 31.9px 15px 10px 0px;
  padding: 9px 20.5px 9px 20.5px;
  border-radius: 4px;
  background-color: transparent !important;
  border: solid 1px var(--darkBlue) !important;
}
.call-text {
  flex-grow: 0;
  font-family: FontSemiBold;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.15px;
  text-align: center;
  color: var(--darkBlue);
}
.banner1000Box {
  display: flex;
  gap: 5%;
  width: 100%;
}
.banneBoxrWidth {
  width: 100%;
  min-height: 370px;
}
.fifty-thousand {
  margin: 25px 7.4px 0 0px;
  font-family: FontLight;
  font-size: 36px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: var(--black-color);
}
.five-thousand {
  width: 103px;
  margin: 25px 0px 0 0px;
  font-family: FontLight;
  font-weight: 400;
  font-size: 36px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: var(--black-color);
}
.success {
  max-width: 160px;
  margin: 0px 0px 21.5px 0px;
  font-family: FontRegular;
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  text-align: left;
  color: var(--black-color);
}
.video_banner-Box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.Video-Box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 35vw;
  height: 100%;
  min-height: 33vw;
  display: flex;
  background-image: url("/src/images/zenBackImage.webp");
  background-image: image-set(
    "/src/images/zenBackImage.webp" 1x,
    "/src/images/zenBackImageMobile.webp" 3x
  );
  background-repeat: no-repeat;
  background-size: 77% 83%;
  background-position: 50%;
  overflow: hidden;
  align-self: center;
  padding: 0px 30px 0px 30px;
  margin: 5px 0px -35px 0px;
}
.NewsPartners-Logo {
  width: 100%;
  flex-grow: 0;
  margin-bottom: -20px;
}
.NewsPartners-Logo3 {
  width: 100%;
  flex-grow: 0;
  margin-bottom: -27px;
}
.NewsPartners-Logo2 {
  width: 100%;
  flex-grow: 0;
  margin-bottom: -17px;
}
.news-Link {
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
}
.NewsPartners-Logo1 {
  width: 100%;
  flex-grow: 0;
  margin-bottom: -27px;
}
.Shark-Tank-Banner {
  width: 100px;
  max-width: fit-content;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: auto;
  margin-top: 10px;
  margin-left: 0%;
  text-decoration: none;
}
.sharkTankLink {
  width: 100%;
}
.txt-Box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.sharktank-mobile-img {
  height: auto;
}
@media screen and (max-width: 1562px) {
  .Video-Box {
    background-size: 75% 75%;
  }
  .main-Box {
    padding: 15px 77px 50px 77px;
  }
  .AllTxt-BtnBox {
    width: 60%;
    margin-left: 10%;
  }
  .Improve {
    font-size: 20px;
  }
}
@media screen and (max-width: 1280px) {
  .Video-Box {
    padding: 0px 20px 0px 20px;
  }
  .degree-360 {
    font-size: 4vh;
  }
  .download-btn {
    padding: 12px 12px 12px 12.5px;
  }
  .NewsPartners-Logo {
    margin-bottom: -13%;
  }
  .Improve {
    font-size: 18px;
  }
}
@media screen and (max-width: 1072px) {
  .Video-Box {
    max-width: 40vw;
    min-height: 43vw;
  }
  .AllTxt-BtnBox {
    width: 100%;
    margin-left: 4%;
  }
  .main-Box {
    padding: 17px 77px 50px 77px;
  }
}
@media screen and (max-width: 1000px) {
  .main-Box {
    padding: 40px 20px 50px 20px;
  }
  .Videoand360-Box {
    flex-wrap: wrap;
  }
  .Improve {
    font-size: 20px;
  }
  .degree-360 {
    max-width: none;
    font-size: 32px;
    margin: 50px 0px 12.9px 0px;
    text-align: center;
  }
  .Improve {
    text-align: center;
  }
  .download-btn {
    padding: 10px 10px 10px 10px;
  }
  .AllTxt-BtnBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0%;
  }
  .Video-Box {
    max-width: 550px;
    min-height: 580px;
    background-size: 85% 82%;
  }
  .Buttons-box {
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .Members-Succes-box {
    gap: 2vh;
  }
  .fifty-thousand {
    margin: 10px 0px 0px 0px;
  }
  .five-thousand {
    margin: 10px 0px 0px 0px;
  }
  .success {
    margin: 0px 0px 0px 0px;
  }
  .NewsPartners-Logo {
    width: 190px;
    margin-bottom: -9%;
  }
  .NewsPartners-Logo1 {
    width: 190px;
  }
  .NewsPartners-Logo2 {
    width: 190px;
    margin-bottom: 0px;
  }
  .NewsPartners-Logo3 {
    width: 110px;
    flex-grow: 0;
    margin-left: 12%;
    margin-bottom: -18px;
  }
  .video_banner-Box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .sharkTankLink {
    width: 100%;
    max-width: 180px;
  }

  .banneBoxrWidth {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .Shark-Tank-Banner {
    margin-top: 30px;
  }
  .sharktank600-mainbox {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .sharktank-mobile-img {
    width: 100%;
  }
  .Shark-Tank-Banner {
    width: 100%;
    margin-top: -20px;
  }
}
@media screen and (max-width: 890px) {
  .NewsPartners-Logo3 {
    margin-left: 45px;
    margin-bottom: -13px;
  }
}
@media screen and (max-width: 700px) {
  .NewsPartners-Logo {
    margin-top: 7px;
  }
  .NewsPartners-Logo3 {
    margin-left: 40px;
  }
  .Video-Box {
    max-width: 70vw;
    min-height: 450px;
    background-size: 80% 80%;
  }
}
@media screen and (max-width: 601px) {
  .sharkTankLink {
    margin-right: -60px;
  }
  .AllTxt-BtnBox {
    align-items: flex-start;
    min-height: 250px;
  }
  .Buttons-box {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .degree-360 {
    font-family: FontRegular;
    font-weight: normal;
    margin: 35px 0px 0px 0px;
    line-height: 1.1;
    text-align: left;
  }
  .Buttons-box {
    margin-top: -8px;
    margin-bottom: 20px;
  }
  .Improve {
    font-size: 16px;
    text-align: left;
  }
  .Video-Box {
    max-width: 420px;
    min-height: 420px;
    margin: -0px 0px -5px 0px;
  }
  .dwnld-text {
    font-family: FontLight;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.154px;
    min-height: 19px;
  }
  .call-text {
    font-family: FontLight;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.154px;
    min-height: 18px;
  }
  .download-btn {
    padding: 7px 0px 7px 0px;
    min-width: 140px;
  }
  .call-Us-btn {
    padding: 6px 2px 6px 1px;
    max-width: 142px;
  }
  .sharktank-mobile-img {
    max-width: 277px;
  }
}
@media screen and (max-width: 550px) {
  .main-Box {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .Video-Box {
    max-width: 420px;
    min-height: 420px;
  }
  .NewsPartners-Logo1 {
    width: 140px;
    margin-left: 25%;
  }
  .NewsPartners-Logo {
    width: 140px;
    flex-grow: 0;
    margin-bottom: -150;
  }
  .NewsPartners-Logo3 {
    width: 90px;
    flex-grow: 0;
    margin-bottom: -10px;
    margin-left: 20px;
  }
  .NewsPartners-Logo2 {
    width: 140px;
    margin-bottom: 0;
    margin-left: 25%;
  }
  .Buttons-box {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 480px) {
  .NewsPartners-Logo1 {
    margin-bottom: 4;
  }
  .NewsPartners-Logo {
    margin-top: 15px;
  }
}
@media screen and (max-width: 460px) {
  .sharkTankLink {
    max-width: 150px;
  }
  .Video-Box {
    max-width: 80vw;
    min-height: 360px;
    background-size: 80% 75%;
  }
  .main-Box {
    gap: 0px;
    padding: 20px 20px 30px 20px;
  }
}
@media screen and (max-width: 434px) {
  .Video-Box {
    max-width: 410px;
    min-height: 400px;
  }
  .NewsPartners-Logo3 {
    margin-left: 30px;
    margin-bottom: -8px;
  }
  .NewsPartners-Logo1 {
    margin-left: 13%;
    padding: 3px;
  }
  .NewsPartners-Logo2 {
    margin-left: 13%;
    padding: 3px;
  }
  .Shark-Tank-Banner {
    margin-top: -5px;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 0px;
  }
  .sharkTankLink {
    max-width: 115px;
  }
}
@media screen and (max-width: 415px) {
  .banner1000Box {
    max-width: 100%;
  }
  .AllTxt-BtnBox {
    min-height: 290px;
  }
  .Video-Box {
    max-width: 400px;
  }
  .main-Box {
    padding: 10px 20px 30px 20px;
  }
  .call-Us-btn {
    max-width: 140px;
    min-width: 140px;
  }
  .degree-360 {
    min-height: 105px;
  }
  .Improve {
    min-height: 40px;
  }
}
@media screen and (max-width: 407px) {
  .Shark-Tank-Banner {
    margin-top: 9px;
  }
  .Video-Box {
    min-height: 330px;
    background-size: 80% 82%;
  }
  .sharkTankLink {
    max-width: 150px;
  }
  .NewsPartners-Logo {
    margin-top: 24px;
  }
  .NewsPartners-Logo1 {
    margin-left: 0%;
  }
  .NewsPartners-Logo2 {
    margin-left: 0%;
  }
  .call-Us-btn {
    min-width: max-content;
  }
}
@media screen and (max-width: 355px) {
  .Video-Box {
    max-width: 400px;
    min-height: 300px;
    background-size: 85% 85%;
  }
  .NewsPartners-Logo {
    margin-top: 25px;
  }
  .download-btn {
    min-width: 50%;
    padding: 7px 10px;
  }
  .call-Us-btn {
    max-width: 50%;
    padding: 6px 10px;
  }
}
@media screen and (max-width: 325px) {
  .NewsPartners-Logo1 {
    width: 100%;
  }
  .NewsPartners-Logo {
    width: 100%;
    margin-bottom: -15px;
  }
  .NewsPartners-Logo3 {
    width: 70%;
  }
  .NewsPartners-Logo2 {
    width: 100%;
    flex-grow: 0;
    margin-bottom: 0;
  }
  .call-Us-btn {
    padding: 6px 12px 6px 12px;
  }
  .call-text {
    font-size: 12px;
  }
  .dwnld-text {
    font-size: 12px;
  }
  .Video-Box {
    min-height: 280px;
  }
}
@media screen and (max-width: 308px) {
  .NewsPartners-Logo {
    margin-top: 28px;
  }
  .download-btn {
    padding: 7px 10px 7px 10px;
  }
}
@media screen and (min-height: 800px) {
  .main-Box {
    padding-top: 25px;
  }
}
@media screen and (min-height: 900px) {
  .main-Box {
    padding-top: 50px;
  }
}
@media screen and (min-height: 1350px) {
  .main-Box {
    padding-top: 100px;
  }
}
@media screen and (min-height: 1750px) {
  .main-Box {
    padding-top: 150px;
  }
}
