@import "../../../values/Colored.css";

.CouponandPaymentBox{
    margin-left: auto;
    }
    .CouponBox{
        width: 100%;
        display: flex;
        flex-direction: column;
        border: solid 1px var(--Border-shadow);
        padding: 15px;
        border-radius: 5px;
    }
    .Coupen-txt{
        width:300px;
        text-transform: none;
        font-family: FontBold;
     font-size: 18px;
     font-weight: 500;
     flex-grow: 0;
     border-radius: 4px;   
    }
.couponIcon{
    width: 8%;
}
    .Coupantxtbtn-Box{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 15px;
    }
    .apply-Coupan-txt{
        text-transform: none;
        font-family: FontSemiBold;
     font-size: 14px;
     font-weight: 500;
     flex-grow: 0;
     border-radius: 4px;  
    }
    .apply-btn{
        width: 100%;
        max-width:120px;
        max-height:35px;
        text-transform: none;
           font-family: FontRegular;
        font-size: 12px;
        font-weight: 500;
        flex-grow: 0;
        border-radius: 4px;
        gap:25px;
        border: solid 1px;
        border-color: var(--orange) !important;
        color: var(--orange) !important;
        margin-left: auto;
    }