@import "../../../values/Colored.css";


.QueriesHead-Box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 90px 0px 0px 0px;
}
.QueriesTXt-Box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.CancerFind-Text{
    max-width:950px;
    font-family: FontSemiBold;
    margin: 0px 0px 0px 28.5px;
    font-size: 36px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: normal;
    text-align: center;
    color: var(--white-color);  
}

.queries-txt{
    max-width: 699px;
    margin: 16.5px 0px 0px 0px;
    font-family: FontRegular;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    color: var(--white-color);
}

.QueriesBtns-Box{
    display: flex;
    align-items:center ;
    justify-content: center;
    gap: 15px;
    width: 100%;
    margin: 20px 0px 80px 0px;
}

.Whatsapp-btn{
    display: flex;
    width: 100%;
    max-width:  max-content;
    text-transform: none;
    font-family: FontSemiBold;
    font-size: 14px;
    font-weight: 500;
    flex-grow: 0;
    padding: 10px 40.5px 10px 12.5px;
    border-radius: 4px;
    gap:7px;
    background-color: var(--orange) !important;
}
.FQAChatus-btn-btn {
    width: 100%;
    max-width: 167px;
height: 40px;
    display: flex;
    text-decoration: none;
    text-transform: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:7px;
    padding: 1px 1px;
    border-radius: 4px;
    background-color: var(--consult-docbtn) !important;
}

.serviceCallus-btn{
    text-decoration: none;
}
.QueriesCall-btn{
    display: flex;
    gap: 6px;
    width: 100%;
    max-width:max-content;
    text-transform: none;
       font-family: FontSemiBold;
    font-size: 14px;
    font-weight: 500;
    flex-grow: 0;
    margin: 0 0px 0 20px;
    padding: 6px 40.5px 6px 19.5px;
    border-radius: 4px;
    gap:22px;
    border:solid 1px var(--white-color) !important;
    color: var(--white-color) !important;
}
.QueriesCall-callus-Text {
    flex-grow: 0;
    font-family: FontMedium;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.15px;
    text-align: center;
    color: var(--white-color);
}

.btn-txt{
    flex-grow: 0;
    text-transform: none;
    font-family: FontSemiBold;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.15px;
    text-align: center;
    color: var(--white-color);
}

@media screen and (max-width:1000px) {
.QueriesHead-Box{
    align-items: flex-start;
    padding: 0px 20px 0px 20px;
    margin: 48px 0px 0px 0px;
}
.CancerFind-Text{
    font-size:28px;
    margin: 0px 0px 0px 0px;
    text-align: left;
}
.QueriesTXt-Box{
    display: flex;
    flex-wrap: wrap;
    flex-direction:row;
    align-items: flex-start;
    justify-content: flex-start;
}
.queries-txt{
    text-align: left;
}

    .QueriesCall-btn{
        margin: 0px 0px 0px 0px;
    }
    .QueriesBtns-Box{
        margin: 20px 0px 50px 0px;
    }   
}
@media screen and (max-width:496px) {
    .CancerFind-Text{
        font-size: 210%;
        text-align: left;
    }
}
@media screen and (max-width:441px) {
    .CancerFind-Text{
        font-size: 180%;
    }
}
@media screen and (max-width:384px) {
    .CancerFind-Text{
        font-size: 165%;
        width: 100%;
    }
    .QueriesCall-btn{
        margin: 0 10px 0 0px;
        padding: 6px 20.5px 6px 19.5px;
        gap:8%;
    }
    .QueriesBtns-Box{
        gap: 8px;
    }
    .QueriesCall-btn-text2{
     width: 10vh;
    }
}
    @media screen and (max-width:330px) {
        .CancerFind-Text{
            font-size: 155%;
        }
        .FQAChatus-btn-btn {
            width: 130px;
            gap:2px;
        }
    }
    @media screen and (max-width:290px){
        .FQAChatus-btn-btn {
width: 130px;
margin-left: 7px;
        }
    }
    @media screen and (min-height:1000px) {
        .QueriesHead-Box{
          padding-top: 100px;
               }
      }
      @media screen and (min-height:1300px) {
        .QueriesHead-Box{
          padding-top: 130px;
               }
      }
      @media screen and (min-height:1750px) {
        .QueriesHead-Box{
          padding-top: 180px;
               }
      }