@import "../../../values/Colored.css";
.Rectangle-Consultation {
  width: fit-content;
  display: flex;
    flex-grow: 0;
    padding: 0px 15px 0px 0px;
    border-radius: 41px;
    background-color: var(--book-BG);
    border: solid 1px var(--book-border);
    overflow: hidden;
     gap:0%;
     margin: auto;
     margin-bottom: 50px;
  }
  .Image-box{
    width:400px;
    margin-bottom:-1vh;
     align-self:flex-end;
  }
.image{
  width:335px;
  max-width: 335px;
  height:max-content
}
  .text-to-btnBox{
      display:flex;
       flex-direction:column;
        padding:0px;
        max-width: 100%;
        align-items: center;
        justify-content: center;
  }
  .Personal-aproch {
    max-width: 700px;
    flex-grow: 0;
    margin: 0 0 23px;
    font-family: FontSemiBold;
    font-size: 34px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: left;
    color: var(--book-txt);
    word-break:break-word;
    white-space:initial;
  }
  .OurinHouse-text {
    max-width:100% ;
    flex-grow: 0;
    margin: 13.1px 97px 0px 0;
    font-family: FontMedium;
    font-size:1.3vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: left;
    color: var(--white-color);
    align-self:flex-start;
  }
  .Book-consultation-text {
    width: 100%;
    flex-grow: 0;
    font-family: FontMedium;
    font-size:14px;
    font-weight:100;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--white-color);
  }
  .Book-consultation-btn{
    width: 100%;
    max-width:max-content;
    flex-grow: 0;
    display: flex;
    text-transform: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: stretch;
    align-self:flex-start;
    gap: 10px;
    margin: 7px 0px 0 3px;
    padding: 10px 30px 10px 30px;
    border-radius: 5px;
    background-color: var(--consult-docbtn) !important;
  }
  .AlertST{
    margin-right:260px;
     margin-top:5px;
  }
@media screen and (max-width:1300px){
  .Image-box{
    width:300px;
  }
.image{
  width:300px;
}
}
@media screen and (max-width:1050px) {
  .Rectangle-Consultation{
    margin-left: 20px;
    margin-right: 20px;
    padding: 0px 4% 15px 0%;
  }
  .Image-box{
    width:100%;
    max-width: 350px;
    align-self: flex-start;
    margin-bottom:-4vh;
  }
  .image{
    width:100%;
    max-width: 350px;
  }
  .text-to-btnBox{
    max-width: 100%;
  }
  .OurinHouse-text{
    font-size:18px;
    max-width: 100%;
    margin: 13.1px 0px 0px 20px;
  }
  .Book-consultation-btn{
    margin: 30px 0px 0 0px;
  }
  .Book-consultation-text{
    font-size:14px;
    max-height:100%;
  }
}
  @media screen and (max-width:750px) {
    .Personal-aproch{
      font-size:24px;
      margin: 15px 0px 0px 0px;
    }
  }
@media screen and (max-width:600px) {
  .Rectangle-Consultation{
    padding: 0px 0% 40px 0%;
    flex-wrap: wrap;
    margin-bottom: 50px;
  }
  .Image-box{
    margin-bottom:-0vh;
  }
  .Personal-aproch {
    color: var(--black-color);
    letter-spacing: 5;
  }
  .text-to-btnBox{
      padding-left:8%;
      padding-right:8%;
  }
  .Book-consultation-btn{
   padding: 7px 12px 7px 12px;
   border-radius: 4px;
   margin: auto;
   margin-top: 25px;
  }
}
@media screen and (max-width:420px){
  .text-to-btnBox{
    min-height: 150px;
}
}
@media screen and (max-width:305px) {
  .Book-consultation-text{
    font-size:12px;
  }
}