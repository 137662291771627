@import "../../../values/Colored.css";

.ListOfProducts-MainBox{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 50px 77px 50px 77px;
    gap:40px;
}

.Data-Not-Found{
    font-family: FontBold;
    font-size:5vh;
  }

.FilterBysearch-Box{
    width: 100%;
      border-radius: 8px;
      font-family: FontRegular;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      background-color: var(--inputBG);
  }

  .Search-Icon {
    color:  var(--inputIcon);
    margin: 0px 10px 0px 0px;
    }

    .Filter-byText{
        font-family: FontSemiBold;
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        margin: 18px 0px 3px 0px;
    }
    .FilterByBadgesBox{
        max-width:400px;
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        margin: 0px 0px 30px 0px;
    }
.SortCheckBox{
    width:auto;
    min-width: 150px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.checkBox-Filtered{
    width: 5px;
}
.checkBox-Filtered:hover{
    color:var(--event-orange);
}   
    .SelectedFillter-Badges {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-family: FontRegular;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        padding: 2px 12px;
        border-radius: 6px;
        background-color: var(--event-orange) !important;
        color: var(--white-color) !important; 
      }

      .FilterdBadges{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-family: FontRegular;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        padding: 2px 12px;
        border-radius: 6px;
    }
    .sortBy-Box{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 15px;
        margin-top: -5px;
    }
    .sortBY-txt{
        font-family: FontRegular;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
    }
    .ProductsLeftside-Box{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
    .ProductsLeftside-Box-subbox{
       width: 100%;
    }
    .ProductsRightSide-Box{
        width:100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .ProductsListBox{
        width:100%;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content:center;
        gap: 30px;
    }
    .Loadproduct-btn {
        width: 100%;
        max-width: 167px;
        height: 40px;
        display: flex;
        flex-direction: row;
        text-transform: none;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin: 23.4px 26.5px 23.4px 7px;
        padding: 10px 16px;
        border-radius: 4px;
        background-color: var(--consult-docbtn  ) !important;
        color:var(--white-color) !important;
      }
      .ViewAll-textproduct{
        flex-grow: 0;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: -0.15px;
        text-align: center;
        color: var(--white-color);
        font-family:FontRegular;
        line-height: 20px;
        letter-spacing: -0.154px;;
        text-align: center;
        color: var(--white-color);
      }
    @media screen and (max-width:1025px) {
     .ListOfProducts-MainBox{
        flex-wrap: wrap;
        padding: 30px 20px 50px 20px;
        gap:1%;
     }
     .Loadproduct-btn{
        margin: 0px;
        margin-top: 15px;
     }
     .ProductsLeftside-Box{
       width: 100%;
    }
    .FilterBysearch-Box{
        width: 100%;
        max-width:450px;
      }
    }
    @media screen and (max-width:625px) {
    .ProductsListBox{
        display: grid;
 grid-template-columns: 1fr 1fr;
        gap:7px;
    }
    .sortBy-Box{
        gap: 15px;
    }
    .Data-Not-Found{
        width:250px;
        font-family: FontBold;
        font-size:24px;
      }
    .ProductsLeftside-Box-subbox{

        margin-bottom: 20px;
     }
    .Filter-byText{
        font-size: 18px;
    }
    .sortBY-txt{
        font-size: 15px;
    }
}
