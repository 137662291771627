@import "../../../values/Colored.css";

.Main-cancerfighting_box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0px 0px 50px 0px;
  padding: 40px 100px 30px 100px;
  gap: 35px;
  background-color: var(--centers-BG1);
}
.cancerfighting-Points {
  font-family:FontLight;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  font-weight: 600; 
  display: flex;
  align-items: center;
  justify-content: flex-start;
  letter-spacing: normal;
  text-align: left;
  color: var(--black-color);
}
.cancerfighting-tik-Icon {
  width: 100%;
  max-width: 16px;
  margin-right: 10px;
}
.cancerfighting-box {
  width: 100%;
  min-width: 430px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.cancerfighting-button {
  max-width: max-content;
  min-width: fit-content;
  max-height: 35px;
  display: flex;
  text-transform: none;
  text-decoration: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0px 0px 0px 0px;
  padding: 7px 70px;
  border-radius: 5px;
  border: 1px solid;
  border-color: var(--darkBlue);
  text-transform: none;
  font-family: FontRegular;
  flex-grow: 0;
  color: var(--white-color);
  background-color:transparent !important;
  -webkit-tap-highlight-color: transparent;
}
@media screen and (max-width:1000px) {
  .Main-cancerfighting_box {
    flex-wrap: wrap;
    padding: 55px 20px 40px 20px;
  }
  .Allcards-Box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .Endtoend-Medical-text {
    margin: 0 0px 0px 0;
    max-width: none;
    width: 100%;
  }
  .CounsultExpert-Points {
    margin: 0px 0px 0px 0px;
  }
  .cancerfighting-box {
    min-width: 220px;
    margin-top: -30px;
  }
}
@media screen and (max-width:600px) {
  .Main-cancerfighting_box {
    padding: 60px 20px 10px 20px;
    min-height: 500px;
  }
  .cancerfighting-box {
    margin-bottom: 8px;
  }
  .cancerfighting-button-box{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 0px;
    margin-bottom: 15px;
  }
}