@import "../../../values/Colored.css";
.messageBtn{
    position: fixed;
    max-width: 55px;
    min-width: 55px;
    max-height: 55px;
    min-height: 55px;
    border-radius: 50%;
    animation-delay: 5s inherit;
    font-size: 70%;
    bottom: 20px;
    left: 25px;
    background-color: var(--consult-docbtn) !important;
    color: var(--white-color);
    z-index: 1300;
}
.messageBtn1{
    position:fixed;
    max-width: 55px;
    min-width: 55px;
    max-height: 55px;
    min-height: 55px;
    border-radius: 50%;
    animation-delay: 5s inherit;
    font-size: 70%;
    bottom: 615px;
    left: 345px;
    background-color: var(--consult-docbtn) !important;
    color: var(--white-color);
    z-index: 1300;
}
.Message-Stickyimg {
    width:28px;
    height: 28px;
}
@media screen and (max-width:450px) {
    .messageBtn1{
        position: sticky;
        z-index: 1300;
        margin: 10px;
    }}
@media screen and (max-width:330px) {
    .messageBtn1{
        bottom: 605px;
    }}
    @media screen and (max-height:678px){
        .messageBtn1{
            bottom: 475px;
        }
    }