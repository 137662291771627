@import "../../../values/Colored.css";

.HealingEvents-MainBox{
    width: 100%;
    display:flex;
     align-items:center;
      justify-content:center;
      padding: 100px 0px 100px 0px;
background-color: var(--event-darkblue);
}
.HealingEvents-Text{
    max-width:100%;
  font-family:FontBold;
  font-size: 56px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: var(--white-color);
}

.Consultation-wellness-Text{
    max-width:70%;
    margin: 21px 0px 0px px;
    font-family: FontRegular;
    font-size: 32px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color:var(--white-color);
}
.healingImagBox{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content:center;
    margin-left: auto;
}
.healignImage{
    width:100%;
    max-width:450px;
}
.upcomingComponent{
    width: 100% ;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 160px 0px 160px;
}
.upcoming-txtBox{
    width: 100% ;
    display: flex;
    flex-direction: column;
 align-items: flex-start;
    justify-content:left;
}

.upcoming-txt{
  margin: 50px 0 32px 6.5%;
  font-family: FontBold;
  font-size: 36px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
    color: var(--black-Txt);
    text-align: left;
}

.AllEvents-MainBox{
    display:flex;
    flex-direction: column;
     align-items:center;
    width: 100%;
    flex-direction: column;
}
.upcoming-Dateicon{
    max-width: 24px;
    max-height: 24px;
    flex-grow: 0;
}
.dattime-Divivder{
    border-color: var(--black-Txt);
    max-height: 18px;
}
.upcomingEvents-Link{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 87%;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    text-transform: none;
    background-color: transparent !important;
    color: var(--black-color);
}

.ComingEvents-img-box{
    width:100%;
 min-width: 242px;
    max-height:240px;
    min-height:240px;
    flex-grow: 0;
    border-radius: 12px;
}
.ComingEvent-MainImg-box{
    width: 250px;
}
.Upcoming-events-Infobox{
display: flex;
align-items: center;
justify-content: flex-start;
gap: 20px;
width:100%;
background-color: transparent !important;
}
.Upcoming-events-Infobox1{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 0px 25px 0px;
    width:100%;
    gap: 20px;
    }
.Location-To-BtnBox{
    max-width: 330px;
    display: flex;
    flex-direction: column;
    margin-left: auto;
}
.join-Now-btn{
width: 150px;
    display: flex;
    flex-direction: row;
    text-transform: none;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 20px 0 0 0px;
    padding: 10px 16px;
    border-radius: 4px;
    background-color: var(--darkBlue) !important;
    -webkit-tap-highlight-color: transparent;
    align-self: center;
    color: var(--white-color)  !important;
}
.nameTodesc-Box{
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: flex-start;
    padding-bottom: 10px;
  width:55%;
}

.Dr-Name-text{
    width: 100%;
    min-height:30px;
    flex-grow: 0;
    margin: 0 0px 5px 0;
    font-family: FontSemiBold;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.98;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-Txt);
 word-wrap: break-word;
    text-overflow: ellipsis;
}

.Events-About-txt{
 max-width: 361px;
    flex-grow: 0;
    font-family: FontRegular;
    font-size: 18px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-Txt);
}
.Events-Type-txt{
    max-width: 361px;
       flex-grow: 0;
       font-family: FontSemiBold;
       font-size: 16px;
       font-weight: 400;
       font-stretch: normal;
       font-style: normal;
       line-height: 1.53;
       letter-spacing: normal;
       text-align: center;
       color: var(--DarkBlue-Text);
       margin-top:auto;
   }
   .Events-Payment-txt{
    max-width: 361px;
       flex-grow: 0;
       font-family: FontSemiBold;
       font-size: 18px;
       font-weight: 400;
       font-stretch: normal;
       font-style: normal;
       line-height: 1.53;
       letter-spacing: normal;
       text-align: center;
       color: var(--black-Txt);
       margin-top:50px;
   }
.healingEvents-box1{
    width:100%
}
.Events-Info-txt{   
     max-width:1000px;
       flex-grow: 0;
  margin: 0px 0px 0 0;
  font-family: FontSemiBold;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: normal;
  text-align: left;
    color: var(--black-Txt);
}
.flex-date-timeBox{
    width:100%;
    display: flex;
    align-items: center;
    margin: 25px 0px 0 0;
    gap:15px;
flex-wrap: wrap;
}
    .Events-TimeDate-Txt{
 min-width:90px;
        display: flex;
        align-items: center;
        gap:15px;
        flex-grow: 0;
        font-family: FontSemiBold;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        text-align: left;
        color: var(--black-Txt);
    }
    .Upcominglocation-icon {
        max-width: 20px;
        flex-grow: 0;
        margin: 3px 12px 0px -2px;
        color:  var(--icon-Blue);
      }
    .Events-Location-Box{
        max-width: 392.3px;
        display: flex;
        align-items: center;
        flex-grow: 0;
        font-family: FontMedium;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        text-align: left;
        color: var(--DarkBlue-Text);
        margin: 5px 0px 0px 0px;
    }
    .upcomingEvent-divider{
        width: 87%;
    }

    @media screen and (max-width:1000px) {
        .HealingEvents-MainBox{
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            padding: 50px 0px 150px 0px;
            gap:25px;
        }
        .HealingEvents-Text{
            max-width: 100%;
            text-align: center;
            margin-bottom: 20px;
        }
        .Consultation-wellness-Text{
            max-width: 100%;
            font-size:24px;
            text-align: center;
        }
        .healingImagBox{
            align-items:center;
            margin-left: 0%;
            margin-top: 15px;
        }
        .nameTodesc-Box{
            width:100%;
            align-items: center;
            justify-content: center;
        }
.upcoming-txt{
    font-size:32px;
    margin: 50px 0 32px 0%;
}
.Dr-Name-text{
    text-align: center;
}
.upcomingComponent{
    padding: 0px 20px 0px 20px;
}
.Events-Info-txt{
    max-width:100%;
    word-wrap: break-word;
    text-align: center;
}
.Upcoming-events-Infobox1{
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.flex-date-timeBox{
    justify-content: center;
}
.Location-To-BtnBox{
    margin-left: 0px;
}  
.upcomingEvents-Link{
align-items: center;
    justify-content: center;
}
    }
    @media screen and (max-width:600px) {
        .Upcoming-events-Infobox{
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
        }
    }
    @media screen and (min-height:1350px) {
        .HealingEvents-MainBox{
          padding-top: 150px;
               }
      }
      @media screen and (min-height:1750px) {
        .HealingEvents-MainBox{
          padding-top: 180px;
               }
      }