@import "../../../values/Colored.css";
.WhatsApp-StickyBtn {
  position: fixed;
  max-width: 70px;
  min-width: 70px;
  max-height: 70px;
  min-height: 70px;
  border-radius: 50%;
  animation-delay: 5s inherit;
  bottom: 20px;
  right: 25px;
  background-color: var(--whatsap-btn) !important;
  animation: pulse 1.5s ease-out infinite;
  box-shadow: 0 0 0 2em transparent;
  z-index: 1200;
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0.5em var(--whatsapp-animation1);
  }
  30% {
    box-shadow: 0 0 0 1.2em var(--whatsapp-animation2);
  }
  35% {
    box-shadow: 0 0 0 2em transparent;
  }
  60% {
    box-shadow: 0 0 0 2em transparent;
  }
  100% {
    box-shadow: 0 0 0 0 0em transparent;
  }
}
.WhatsApp-Stickyimg {
  width: 100%;
  border-radius: 50%;
}
@media screen and (max-width: 600px) {
  .WhatsApp-StickyBtn {
    max-width: 63px;
    min-width: 63px;
    max-height: 63px;
    min-height: 63px;
    bottom: 15px;
    right: 15px;
  }
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0.3em var(--whatsapp-animation1);
    }
    30% {
      box-shadow: 0 0 0 0.9em var(--whatsapp-animation2);
    }
    35% {
      box-shadow: 0 0 0 1em transparent;
    }
    60% {
      box-shadow: 0 0 0 1em transparent;
    }
    100% {
      box-shadow: 0 0 0 0 0em transparent;
    }
  }
}
