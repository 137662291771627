@import "../../values/Colored.css";

.sharkTank-Header{
    min-height:50px;
    position:sticky;
z-index:5;
}
.sharkTankFooter{
    background:var(--footer_BG-Color);
    padding-top: 30px;
}
.sharkTank-VideoBox{
    display:flex;
    align-items:center ;
    justify-content:center;
    display:flex;
    width:100% ;
    align-items:center ;
    justify-content:center;
    overflow: hidden;
  align-self: center;
  margin: auto;
}

