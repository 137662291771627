@import "../../../values/Colored.css";

.MeetDoctors-MainBox{
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-end;
    padding:30px 30px 0px 30px;
gap:20px;
}
.AllMeet-TxtBox{
align-self: center;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 100%;
max-width: fit-content;
align-self: center;
justify-self: center;
margin: 30px 0px 0px 0px;
}
.MeetDoctors-Text{
    max-width:700px;
    font-family: FontRegular;
    font-size: 42px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: normal;
    text-align: center;
    color: var(--white-color);
}
.AskQueries-Txt{
    max-width: 699px;
    margin: 16.5px 0px 0px 0px;
    font-family: FontRegular;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: var(--white-color);
}
.doctr-image{
    max-width:425px;
    margin-top: 50px;
}
@media screen and (min-width:2000px) {
    .MeetDoctors-MainBox{
        flex-wrap: wrap;
        align-items: center;
    justify-content: center;
    padding:0px 20px 0px 20px;
    } 
}
@media screen and (max-width:1000px) {
    .MeetDoctors-MainBox{
        flex-wrap: wrap;
        align-items: center;
    justify-content: center;
    padding:0px 20px 0px 20px;
    }
    .doctr-image{
 max-width:300px;
        padding-right:0px ;
        margin: 0px 0px 0px 0px;
    }
    .AllMeet-TxtBox{
        align-items: center;
justify-content: center;
        margin:80px 0px 0px 0px;
    }
}
@media screen and (max-width:600px) {
    .MeetDoctors-Text{
        font-size: 30px;
        text-align: left;
    }
    .MeetDoctors-MainBox{
    padding:20px 0px 30px 0px;
    }
}
@media screen and (min-height:800px) {
    .MeetDoctors-MainBox{
 padding-top: 25px;
      }
  }
  @media screen and (min-height:900px) {
    .MeetDoctors-MainBox{
      padding-top: 50px;
           }
  }
  @media screen and (min-height:1350px) {
    .MeetDoctors-MainBox{
      padding-top: 100px;
           }
  }
  @media screen and (min-height:1750px) {
    .MeetDoctors-MainBox{
      padding-top: 150px;
           }
  }