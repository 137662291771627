@import "../../../values/Colored.css";

.SavingLives-mainbox{
    width: 100%;
    padding: 45px 50px 50px 50px ;
    justify-content: center;
    align-items: center;
    background-color:var(--book-BG);
}
.SavingLives-headimgtextbox{
width: 100%;
display: flex;
justify-content: center;
align-items:center ;
}
.SavingLives-headingtext{
    margin: 0px 7.4px 200 0px;
    font-family:FontSemiBold;
    font-size: 34px;
    font-weight:400;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-Txt);
    width:40%;
}
.SavingLives-mainbox2{
display: flex;
justify-content: center;
align-items: center;
margin: 10px 0px 0px 0px ;
gap: 60px;
}
.SavingLives-imgbox{
    max-width: 542px;
    margin-top: -70px;
}
.SavingLives-img{
    width: 100%;
}
.SavingLives-textbox{
    width: 30%;
}
.SavingLives-introductiontext{
    margin: 0px 7.2px 20px 0px;
    font-family:FontRegular;
    font-size: 14px;
    font-style: normal;
    line-height: 1.5;
    font-weight: 400;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-Txt);
    width: 534.3px;
}
.SavingLives-introductiontext1{
    height: 140px;
    margin: 0px 7.2px 20px 0px;
    font-family:FontRegular;
    font-size: 14px;
    font-style: normal;
    line-height: 1.5;
    font-weight: 400;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-Txt);
    width: 534.3px;
    overflow: hidden;
}
.SavingLives-ownername{
    margin: 0px 0px 9px 0px;
    font-family:FontSemiBold;
    font-size: 14px;
    font-style: normal;
    line-height: 1.5;
    font-weight: 600;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-color);
}
.SavingLives-extrainfo{
    margin: 0px 7.4px 0 0px;
    font-family:FontMedium;
    font-size: 14px;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
      color: var(--black-color);
}
.SavingLives-extrainfo1{
    text-decoration: none;
    font-family:FontMedium;
    font-size: 14px;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
      text-decoration: underline;
      color: var(--black-color);
}
.SavingLives-button{
    width: 100%;
    max-width: 167px;
    height: 40px;
    display: flex;
    flex-direction: row;
    text-transform: none;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 15px 0x 0px 7px;
    padding: 10px 16px;
    border-radius: 4px;
    max-width: 139.085px;
    height: 34.569px;
      gap:7px;
      padding: 9px 12.5px 9px 12.5px;
      border: solid 1px var(--darkBlue) !important;
      background-color: transparent !important;
}
.SavingLives-buttontex{
    flex-grow: 0;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.15px;
    text-align: center;
    font-family:FontRegular;
    letter-spacing: -0.154px;;
    text-align: center;
    color: var(--darkBlue);
}
.SavingLives-comass{
    width: 100%;
   display: flex;
   justify-content: flex-end;
}
.SavingLives-coma1{
    width: 14px;
    margin-right: -29px; 
    position: relative;
    top: -5px;
}
.SavingLives-coma{
   width: 14px;
    margin-left: -29px; 
    position: relative;
    top: 10px;
 }
 .SavingLives-linkicon{
  position: relative;
  top: 2px;
 }
 .SavingLives-linkiconimg{
    margin-right: 5px;
    width: 16px;
    height: 16px;
    margin-left: 4px;
    border-radius: 50%;
 }
 @media only screen and (max-width: 1522px) {
    .SavingLives-headingtext{
        width: 49%;
    }}
 @media only screen and (max-width: 1381px) {
    .SavingLives-introductiontext{
        max-width: 500px;
    }
    .SavingLives-mainbox{ 
        padding: 50px 0px 50px 0px ;
    }}
  @media only screen and (max-width: 1315px) {
    .SavingLives-introductiontext{
        max-width: 420px;
    }}
  @media only screen and (max-width: 1200px) {
    .SavingLives-introductiontext{
        max-width: 370px;
    }
    .SavingLives-headingtext{
        width: 62%;
    }}
  @media only screen and (max-width: 1097px) {
    .SavingLives-introductiontext{
        max-width: 60%;
    }}
  @media only screen and (max-width: 1040px) {
    .SavingLives-introductiontext{
        max-width: 650px;
    }
    .SavingLives-mainbox2{
        margin: 90px 0px 0px 0px ;
        gap: 0px;
        flex-direction: column;
    }
    .SavingLives-textbox{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .SavingLives-mainbox{
        padding: 40px 50px 40px 50px ;
    }
    .SavingLives-headingtext{
        width: 79%;
    }}
  @media only screen and (max-width: 820px) {
    .SavingLives-headingtext{
        width: 96%;
    }}
  @media only screen and (max-width: 692px) {
    .SavingLives-headingtext{
        width: 100%;
        font-size: 33px
    }}
  @media only screen and (max-width: 625px) {
    .SavingLives-introductiontext{
        max-width:420px;
    }
    .SavingLives-introductiontext1{
        max-width:480px;
    }
    .SavingLives-headingtext{
        margin: 0px 0px 0px 0px;
        font-size: 31px;
        width: 81%;
    }
    .SavingLives-imgbox{
        max-width: none;
        width: 100%;
        margin-top: -70px;
        margin-bottom: -30px;
    }
    .SavingLives-coma1{
        margin-right: -25px; 
    }
    .SavingLives-coma{
        margin-left: -15px; 
     }}
  @media screen and (max-width:600px) {
    .SavingLives-headingtext{
        font-size: 24px;
        width: 80%;
    }
    .SavingLives-extrainfo{
        font-family:FontLight;
        font-weight: 600;
    }
    .SavingLives-extrainfo1{
        font-family:FontLight;
        font-weight: 600;
    }
    .SavingLives-introductiontext{
        margin: 10px 7.2px 20px 0px; 
    }
    .SavingLives-introductiontext1{
        height: 105px;
        margin: 10px 7.2px 20px 0px;
    }
    .SavingLives-coma{
       display: flex;
       justify-content: flex-end;
       margin: auto;
    }}
  @media only screen and (max-width: 521px) {
    .SavingLives-introductiontext{
        max-width:350px;
    }
     .SavingLives-introductiontext1{
        height: 130px;
        max-width:400px;
    }
    .SavingLives-mainbox{
        padding: 40px 30px 40px 30px ;
    }}
  @media only screen and (max-width: 452px) {
    .SavingLives-headingtext{
        width: 100%;
    }}
  @media only screen and (max-width: 437px) {
    .SavingLives-introductiontext{
        max-width:330px;
    }
    .SavingLives-introductiontext1{
        height: 170px;
        max-width:340px;
    }}
  @media only screen and (max-width: 408px) {
    .SavingLives-introductiontext{
        max-width:300px;
    }}
  @media only screen and (max-width: 384px) {
    .SavingLives-introductiontext{
        max-width:275px;
    }
    .SavingLives-introductiontext1{
        height: 170px;
        max-width:300px;
    }}
  @media only screen and (max-width: 332px) {
    .SavingLives-introductiontext{
        max-width:240px;
    }
    .SavingLives-introductiontext1{
        height: 210px;
        max-width:240px;
    }}