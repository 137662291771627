@import "../../../values/Colored.css";

.DownloadApp-Box{
    display: flex;
    margin: 65px 80px 95px 80.1px;
    padding: 0 39.3px 0 65px;
    border-radius: 28px;
    background-color: var( --darkBlue);
    gap: 30px;
}
.DownloadApp-Text {
  max-width: 547px;
  flex-grow: 0;
  margin: 103.5px 0px 23.8px 0;
  font-size:32px;
  font-family:FontLight;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: left;
  color: var(--white-color);
}
.popover-margin{
  margin-left: -40px;
}
.helpyou-Text {
  max-width: 450px;
  flex-grow: 0;
  margin: 23.8px 0px 41.2px 0;
  font-family:FontRegular;
  font-weight: 400;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  text-align: left;
  color: var(--white-color);
}
.dwnld-Btn {
  width: 70%;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  text-transform: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding:10px 20px;
  border-radius: 10px;
  background-color: var(--white-color) !important;
  margin-bottom: 10px;
}
.dwnld-txt{
  flex-grow: 1;
  font-family: FontMedium;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: var(--black-color);
}
.dwnld-App-imageBox{
    background-repeat: no-repeat;
    background-size:contain;
    display: flex;
    align-items: flex-end;
    margin: auto;
    margin-top: 0px;
    margin-bottom: 0px;
}
.dwnldApp-imgsize{
width: 100%;
}
.Download-AppPlay-store{
  display: flex;
  justify-content:center ;
  gap: 10px;
  background-color:var(--white-color) !important;
  width: 100%px;
}
.Download-PlayStore-btn{
  margin-top: 10px;
  width:47%;
      border-radius: 10px;
      -webkit-tap-highlight-color: transparent;
    }
    .Download-AppStore-btn{
  margin-top: 10px;
  width:47%;
      border-radius: 10px;
      -webkit-tap-highlight-color: transparent;
    }
@media screen and (max-width:1200px) {
  .DownloadApp-Text {
    margin: 60px 0px 23.8px 0;
  }
}
@media screen and (max-width:1000px){
  .DownloadApp-Box{
    flex-wrap:wrap;
    margin: 30px 20px 67.4px 20px;
    padding: 0 20px 0 20px;
    gap: 0px;
  }
  .dwnld-txt{
    max-width: fit-content;
  }
  .helpyou-Text{
    max-width:100%;
    text-align: center;
  }
.DownloadApp-Text{
  margin:30px 0px 15px 0;
  font-size:36px;
  text-align: center;
    max-width: 100%;
}
  .downloadApp-textBox{
margin: auto;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
  }
  .dwnld-Btn{
    align-self: center;
    max-width: max-content;
    padding:10px 30px;
  }
  .dwnld-App-imageBox {
    background-position-y: bottom;
  }
}
@media screen and (max-width:620px){
  .downloadApp-textBox{
    align-items: center;
  }
  .DownloadApp-Text{
    margin:35px 0px 1px 0;
    font-size:190%;
  }
  .dwnld-Btn{
    width: 100%;
  }
}
@media screen and (min-width:2250px) {
  .DownloadApp-Box{
    max-width: 2000px;
    margin: auto;
    margin-top: 65px;
    margin-bottom: 95px;
  }
}