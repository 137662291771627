@import "../../../values/Colored.css";

.video-view{
    width: 100%;
    height:17vw;
    aspect-ratio: calc(16/9);
    border-radius: 30px;
    align-self: center;
    background: url("/src/images/playArrow.webp")  no-repeat;
    background-color:var(--black-color);
    background-position: center;
    background-size:25%;
    overflow: hidden;
}
@media screen and (max-width:1100px) {.video-view{height:22vw;}}
@media screen and (max-width:1010px) {.video-view{height:21vw;}}
@media screen and (max-width:1000px) {.video-view{height:38vw;}} 
@media screen and (max-width:450px) {.video-view{height:47vw;}}
 