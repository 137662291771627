@import "../../../values/Colored.css";

.TopHeading-Box {
    padding: 20px 77px 0px 77px;
    background-color: var(--event-darkblue);
    width: 100%;
    
  }
@media screen and (max-width:1000px) {
  .TopHeading-Box{
    padding: 20px 20px 0px 20px;
  }
}