@import "../../../values/Colored.css";

.ConsultInfo-MainBox {
    width: 100%;
    background-color: var(--event-darkblue);
    margin: 0 0 64.1px;
    padding: 0px 0px 40px 0px;
  }
  .Navigation-Seprator {
    color: var(--white-color);
  }
  .Navigation-Labels {
    flex-grow: 0;
    font-family: FontMedium;
    text-decoration: none;
    text-transform: none;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.81;
    letter-spacing: normal;
    text-align: left;
    color: var(--white-color);
    cursor: pointer;
  }
  .Doctors-ProfileBox {
    display: flex;
    gap: 10px;
  }
  .doctor-infoimage {
    width: 100%;
    height: 100%;
    max-width: 208.1px;
    max-height: 208.1px;
    min-height: 208.1px;
    margin: 48.9px 18px 0px 0px;
    object-fit: contain;
  }
.doctor-infoname {
    margin: 58.7px 0px 9.2px 0px;
    font-family: FontSemiBold;
  font-size:32px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: var(--white-color);
  }
  .Information-BadgeBox {
    display: flex;
    gap: 10px;
    align-items: center;
  }
.PaediatricOnco-Badge {
    max-width: max-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 9.2px 0px 9.2px 0px;
    padding: 5px 30px 5px 30px;
    text-transform: none;
    border-radius: 6px;
    background-color: var(--Badge-Color);
  }
  .Badges-Text {
    flex-grow: 0;
    font-family: FontRegular;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: var(--Badge-Text);
  }
  .years55-Badge {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-transform: none;
    padding: 5px 30px 5px 30px;
    border-radius: 6px;
    background-color: var(--Badge-Color);
  }
  .BreastCancer-Text {
    margin: 9.2px 0px 3.2px 0px;
    font-family: FontRegular;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.44;
    letter-spacing: normal;
    text-align: left;
    color: var(--white-color);
  }
  .HospitalsInfo-Box {
    display: flex;
  }
  .ChetpetChennai-Text {
    text-decoration: none;
    text-transform: none;
    margin: 3.2px 15px 28.6px 0px;
    font-family: FontSemiBold;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    text-align: left;
    color: var(--white-color);
  }
  .VSHospitals-Text {
    margin: 9px 0px 28.6px 0px;
    font-family: FontSemiBold;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    text-align: left;
    color: var(--white-color);
  }
  @media screen and (max-width:1050px) {
    .Doctors-ProfileBox {
        justify-content: flex-start;
        flex-direction: column;
      }
      .doctor-infoimage {
        align-self:  flex-start;
      }
      .doctor-infoname {
        margin: 20px 0px 9.2px 0px;
      }
  .Dr-all-infoBox {
    align-self:  flex-start;;
  }
  .Information-BadgeBox {
    flex-wrap: wrap;
  }
  .ConsultInfo-MainBox {
    padding: 0px 20px 20px 20px;
  }
  }
  @media screen and (max-width:400px) {
  .PaediatricOnco-Badge {
    width: 100%;
    padding: 5px 5px 5px 5px;
  }
  .years55-Badge {
    width: 100%;
    max-width: max-content;
    padding: 5px 15px 5px 15px;
  }
  .HospitalsInfo-Box {
    flex-wrap: wrap;
  }
  .VSHospitals-Text {
    margin: 3.2px 0px 0px 0px;
  }
  .ChetpetChennai-Text {
 margin: 3.2px 15px 0px 0px;
  }
}
@media screen and (min-height:800px) {
  .ConsultInfo-MainBox{
padding-top: 25px;}
}
@media screen and (min-height:900px) {
  .ConsultInfo-MainBox{
    padding-top: 50px;}
}
@media screen and (min-height:1350px) {
  .ConsultInfo-MainBox{
    padding-top: 100px;}
}
@media screen and (min-height:1750px) {
  .ConsultInfo-MainBox{
    padding-top: 150px;}
}