  @import "../../../values/Colored.css";

  .AboutMainContent-Box {
    display: flex;
    margin: 0px 77px 0px 77px;
    gap: 20px;
  }
  .AboutMainContent-subBox{
    width: 100%;
  }
  .navigationDetailBox {
    padding: 10px 0px 0px 0px;
  }
  .EventDetail-Navigationtxt {
    max-width: 126px;
    flex-grow: 0;
    margin: 0 0px 0 0;
    font-family: FontSemiBold;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.81;
    letter-spacing: normal;
    text-align: left;
    color: var(--white-color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .iconButtonSt{
    margin-left: "20px";
     margin-top: "5px";
  }
  .OnlineYoga-Text {
    width: 100%;
    margin: 18.3px 0px 0px 0px;
    padding: 0px 230px 80px 0px;
    font-family: FontRegular;
    font-size: 56px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: left;
    color: var(--white-color);
    word-wrap: break-word;
  }
  .eventFlexBox {
    display: flex;
  }
  .AboutEvent-image1 {
    width: 100%;
    max-width: 350px;
    min-width: 350px;
    height: 260px;
    margin: 56.4px 0px 24.5px 0px;
    border-radius: 16px;
    
  }
.About-eventText-mainbox{
  margin-left: 15px;
}

  .About-eventText {
    flex-grow: 0;
    margin: 0 0px 24.4px 0;
    font-family: FontBold;
    font-size: 28px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.98;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-color);
    width: 100%;
    
  }
  .About-descriptionText-boxs{
    width: 190%;
  }
  .About-descriptionText {
    width: 100%;
    max-width: 714.2px;
    flex-grow: 0;
    margin: 24.4px 0 35px 0px;
    font-family: FontMedium;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-color);
  }
  .Divider-Margin {
    margin: 25px 0px 25px 0px;
  }
  .Aboutcouncerler-Text {
    flex-grow: 0;
    margin: 35px 0px 24.4px 0px;
    font-family: FontSemiBold;
    font-size: 28px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.98;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-color);
  }
  .JoinThisMain-Box {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
  }
  .jointhisevent-box {
    display: flex;
    max-width: 450px;
    flex-direction: column;
    height: max-content;
    margin: 43.5px 0px 60.1px 0px;
    padding: 23.2px 28.6px 33.6px 18.2px;
    border-radius: 13px;
    background-color: var(--light-Skyblue);
  }

  .Jointhisevent-Text {
    margin: 0 0px 19.9px 10.4px;
    font-family: FontMedium;
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.98;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-color);
  }

  .Date-Icon {
    max-width: 16.8px;
    max-height: 16.8px;
    flex-grow: 0;
    margin: 0px 15px 0px 0px;
  }

  .Join-Date {
    flex-grow: 0;
    margin: 0 0 0 0px;
    font-family: FontSemiBold;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    display: flex;
    align-items: center;
    text-align: left;
    gap: 7px;
    color: var(--DarkBlue-Text);
  }

  .eventEndTime {
    flex-grow: 0;
    font-family: FontSemiBold;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    display: flex;
    align-items: center;
    text-align: left;
    gap: 7px;
    color: var(--DarkBlue-Text);
  }

  .location-icon {
    max-width: 20.8px;
    flex-grow: 0;
    margin: 0px 15px 0px -2px;
    color: var(--DarkBlue-Text);
  }

  .Join-Location {
    flex-grow: 0;
    margin: 15px 0 0 0px;
    font-family: FontSemiBold;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    display: flex;
    align-items: flex-start;
    text-align: left;
    color: var(--DarkBlue-Text);
  }

  .Faq-Box {
    margin-bottom: 50px;
  }

  .Joinnow-btntxt {
    flex-grow: 0;
    font-family: FontSemiBold;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.15px;
    text-align: center;
    color: var(--white-color) !important;
  }

  .OrangeJoin-btn {
    width:250px;
    display: flex;
    flex-direction: row;
    text-transform: none;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 31.8px 0 0 10.4px;
    padding: 10px 16px;
    border-radius: 4px;
    background-color: var(--consult-docbtn) !important;
  }

  .Join-btn {
    width: 100%;
    display: flex;
    flex-direction: row;
    text-transform: none;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 31.8px 0 0 10.4px;
    padding: 10px 16px;
    border-radius: 4px;
    background-color: var(--consult-docbtn) !important;
  }

  @media screen and (max-width:1000px) {

    .AboutMainContent-Box {
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin: 0px 20px 0px 20px;
    }

    .eventFlexBox {
      align-items: center;
      justify-content: center;
    }

    .AboutEvent-image1 {
      min-width: 50%;
    }

    .jointhisevent-box {
      width: 100%;
      max-width: 80%;
      max-height: fit-content;
      align-items: center;
      justify-content: center;
    }
    .OrangeJoin-btn {
      width: 80%;
    }
    .Join-Location {
      margin: 15px 0 0 0px;
    }
    .Join-Date {
      flex-wrap: wrap;
      margin: 0 0 0 0px;
    }
    .Date-Icon {
      margin: 0px 0px 0px 0px;
    }
    .Jointhisevent-Text {
      margin: 0 0px 19.9px 0px;
    }
    .JoinThisMain-Box {
      justify-content: center;
    }
    .OnlineYoga-Text {
      font-size: 43px;
      padding: 0px 0px 80px 0px;
    }
  }
  @media screen and (max-width:1000px) {
    .jointhisevent-box {
      max-width:100%;
    }
    .AboutMainContent-subBox{
      width: 90%;
    }
    .About-descriptionText-boxs{
      width: 100%;
    }
    .About-eventText-mainbox{
      margin-left: 4%;
    }
    .Aboutcouncerler-Text {
      margin: 20px 0px 0px 0px;
    }
    .JoinThisMain-Box {
      margin-top: 0px;
    }
    .AboutMainContent-Box {
    gap: 0px;
    }
  }
  @media screen and (min-height:900px) {
    .navigationDetailBox{
      padding-top: 50px;
           }
  }
  @media screen and (min-height:1350px) {
    .navigationDetailBox{
      padding-top: 100px;
           }
  }
  @media screen and (min-height:1750px) {
    .navigationDetailBox{
      padding-top: 150px;
           }
  }