
.contactus-Header{
    min-height:40px;
    position:sticky;
z-index:1;
}

.ContactFooter{
    background:var(--footer_BG-Color);
    padding-top: 30px;
}
