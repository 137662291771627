@import "../../../values/Colored.css";

.All-EventsVideo-Box{
  width: 100%;
  display:grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  row-gap: 5px;
  }
  .All-EventsVideo-Box2{
      display: flex;
      align-items: flex-start;
      justify-content:center;
      flex-wrap: wrap;
    gap:4%;
    }
  .All-EventsVideo-Box1{
    width: 100%;
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 5px;
  }
  @media only screen and (max-width: 1615px) {
  .All-EventsVideo-Box{
    grid-template-columns: 1fr 1fr 1fr 1fr ;
    }
  }
  @media only screen and (max-width: 1300px) {
    .All-EventsVideo-Box{
      grid-template-columns: 1fr 1fr 1fr;}}
  @media only screen and (max-width: 1460px) {
    .All-EventsVideo-Box1 {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      row-gap: 4px;}}
  @media only screen and (max-width: 1000px) {
    .All-EventsVideo-Box{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
   column-gap: 5px;}}
    @media only screen and (max-width: 870px) {
    .All-EventsVideo-Box1 {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr ;
      row-gap: 4px;}}
  @media only screen and (max-width: 660px) {
    .All-EventsVideo-Box {
      gap:20px;}}
  @media only screen and (max-width: 500px) {
    .All-EventsVideo-Box2 {
      width: 100%;
      align-items: center;
      gap:10px;
    }
    .All-EventsVideo-Box1 {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap:10px;}}