@import "../../../values/Colored.css";

.Navbar-Box{
    display: flex;
align-items: center;
justify-content: center;
width:99.2vw;
top: 0;
position: fixed;
padding: 2vh;
background-color:  var(--Top-color);
z-index:5000;
gap: 10px;
}
.Navbar-Box1{
  display: flex;
align-items: center;
justify-content: center;
width:99.2vw;
top: 0;
position: fixed;
padding: 2vh;
background-color:  var(--event-darkblue) !important;
z-index:5000;
gap: 10px;
}
.cartIcon{
  color: var(--white-color);
}
.badgeColor{
  color:var(--white-color);
}
.badgeColor1{
  color:var(--black-color);
}
.Allbutton-Box{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-left: auto;
    gap:15px;
}
.LogoPosition-Box{
    display:flex;
     align-items:center;
      width:100%;
       gap:10px;
       -webkit-tap-highlight-color: transparent;
}
.CallSearch-Box{
padding:10px 12px;
margin-top: 5px;
}
.CallSearch-Box1{
  padding:10px 0px 5px 12px;
margin-top: 5px;
  }
  .Watss-up-btn {
    width: 100%;
    max-width:max-content;
    height:35px;
    text-transform: none;
    font-family: FontSemiBold;
    font-size: 14px;
    font-weight: 500;
    flex-grow: 0;
    padding:20px 30px;
    border-radius: 4px;
    background-color: var(--consult-docbtn) !important;
gap: 5px;
  }
  .header-whatsappicon{
    width: 100%;
    max-width: 20px;
    height: max-content;
  }
  .HeaderWhatsapp-Txt{
width:120px;
    text-transform: none;
    font-family: FontSemiBold;
    font-size: 14px;
    font-weight: 500;
    flex-grow: 0;
  }
  .ContactUs-Head-btn{
    width: 20px;
  }
  .Call-us-btn-light{
    width: 100%;
    max-width:max-content;
    max-height: 35px;
    padding: 10px 20px 10px 12.5px;
    border-radius: 4px;
    gap:15px;
    border-color: var(--black-color) !important;
    color: var(--black-color) !important;
  }
  .headerCall-txt{
    width:70px;
    text-transform: none;
    font-family: FontRegular;
 font-size: 14px;
 font-weight: 500;
 flex-grow: 0;
  }
  .Call-us-btn-dark{
    width: 100%;
    max-width:  170px;
    max-height:35px;
    text-transform: none;
       font-family: FontRegular;
    font-size: 14px;
    font-weight: 500;
    flex-grow: 0;
    padding: 10px 40.5px 10px 12.5px;
    border-radius: 4px;
    gap:25px;
    border-color: var(--orange) !important;
    color: var(--orange) !important;
  }
  .ZenOnco-Logo{
    width: 100%;
    max-width:33vh;
  }
  .textType-btn-light {
    width: 100%;
    max-width: max-content;
    flex-grow: 0;
    text-transform: none;
    font-family: FontSemiBold;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.15px;
    text-align: center;
    color: var(--black-color) !important;
    background-color: transparent !important;
  }
  .AHeadLink{
    text-decoration: none;
  }
  .textType-btn-dark{
    width: 100%;
    max-width: max-content;
    flex-grow: 0;
    text-transform: none;
    font-family: FontSemiBold;
    font-size: 17px;
    font-weight:500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.15px;
    text-align: center;
    color: var(--white-color) !important;
    background-color: transparent !important;

  }
  .Cart-btn-dark{
    width: 100%;
    max-width: max-content;
    flex-grow: 0;
    text-transform: none;
    font-family: FontSemiBold;
    font-size: 18px;
    font-weight:500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.15px;
    text-align: center;
    color: var(--white-color) !important;
    background-color: transparent !important;
    margin-top: 2.5px;
    margin-left: 15px;
    margin-right: 15px;
  }
  .Cart-btn-light{
    width: 100%;
    max-width: max-content;
    flex-grow: 0;
    text-transform: none;
    font-family: FontSemiBold;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.15px;
    text-align: center;
    color: var(--black-color) !important;
    background-color: transparent !important;
    margin-top: 2.5px;
    margin-left: 15px;
    margin-right: 15px;
  }
  .Search-btn-dark{
    width: 100%;
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
    flex-grow: 0;
    text-transform: none;
    font-family: FontBold;
    font-size: 18px;
    font-weight:500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.15px;
    text-align: center;
    color: var(--white-color) !important;
    background-color: transparent !important;
 margin-right: 33px;
  } 
  .Search-btn-light{
    width: 100%;
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
    flex-grow: 0;
    text-transform: none;
    font-family: FontBold;
    font-size: 18px;
    font-weight:500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.15px;
    text-align: center;
    color: var(--black-color) !important;
    background-color: transparent !important;
 margin-right: 33px;
  } 
  .search-nav{
    width:25px;
    margin-top: -5px;
    height: auto;
  }
  .search-nav1{
    width:22px;
    margin-top: -5px;
    height: auto;
  }
  .Drawer-btnLight{
    color: var(--black-color) !important;
  }
  .Drawer-btnDark{
    color: var( --white-color) !important;
  }
  .Alert-Succes-header{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width:100%;
    position: fixed;
margin-top:110px;
z-index: 5002;
padding-right: 15px;
}
.languageBox{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  text-decoration:none ;
  font-family: FontBold;
  font-size: 18px;
  font-weight:500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.15px;
  text-align: center;
  color: var(--black-color) !important;
}
.Alert-Succes-txt{
    flex-grow: 0;
    font-family: FontSemiBold;
    font-size:12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
}
@media screen and (max-width:1150px) {
  .Allbutton-Box{
    gap:5px;
}
  }
@media screen and (max-width:1000px) {
.ZenOnco-Logo{
  width: 100%;
  max-width:200px;
  height:min-content;
  max-height:50px;
}
}
@media screen and (max-width:800px) {
  .ZenOnco-Logo{
    width: 100%;
    max-width:250px;
  }
}
@media screen and (max-width:320px) {
  .ZenOnco-Logo{
    max-height:40px;
  }
  }
@media screen and (max-width:600px) {
  .CallSearch-Box{
    padding:10px 0px 10px 5px;
    }
    .CallSearch-Box1{
      padding:10px 0px 5px 5px;
      }
}
@media screen and (max-width:320px) {
  .ZenOnco-Logo{
    max-height:40px;
  }
  }
