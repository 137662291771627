@import "../../../../values/Colored.css";

.ContactUSModal-MainBox{
    width: 100%;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    padding:28px 50px 28px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;}
.Line-49 {
  background-color: var(--Divider);
  flex-grow: 0;}
.timeSlotflex {
  display: flex;}
.TabsIndicatorBook {
  max-width: 80px;
  background-color: var(--event-orange);}
.Mon-Text {
  flex-grow: 0;
  margin: 0 11px 0px;
  font-family: FontRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: var(--black-color);}
.ContactUSModal-text{
    flex-grow: 1;
    font-family: FontBold;
    font-size:20px;
    font-weight:500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-color);}
.width100{
  width: 100%;}
.Select-time-Txt{
    flex-grow: 1;
    font-family: FontSemiBold;
    font-size:20px;
    font-weight:500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-color);}
.ContactUSModal-box{
    width: 100%;
    display: flex;
    gap: 15px;}
  .ContactUsModal-btn{
    width:100%;
    max-width: max-content;
    font-family: FontSemiBold;
    color: var(--black-color);
    border: 2.5px solid;
    border-color: var(--black-color);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 11.5px 16px 11.5px 16px;
    margin: auto;
  }
  .SContactUsModalbtn{
    width:100%;
    max-width: max-content;
    font-family: FontSemiBold;
    color: var(--black-color);
    border: 2.5px solid;
    border-color: var(--feedback-orange);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 11.5px 16px 11.5px 16px;
    margin: auto;
  }
  .ContactUsModal-Chip1 {
    max-width:100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-transform: none;
    padding: 10px 20px 10px 20px;
    border-radius: 70px;
    background-color: var(--white-color) !important;
    cursor:pointer;
    transition: all 0s ease-out;
    margin: auto;
    }
    .ContactUsModal-Done {
      max-width:100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      text-transform: none;
      padding: 10px 30px 10px 30px;
      border-radius: 70px;
      background-color: var(--white-color) !important;
      cursor:pointer;
      transition: all 0s ease-out;
      margin: auto;
      }
    .ContactUsModal-text1 {
      flex-grow: 0;
      font-family: FontSemiBold;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: left;
      color: var(--black-color);
      cursor:pointer;
      transition: all 0s ease-out;
    }
  .Selected-ContactUsModalChip {
    max-width:100%;
    max-height: 41px;
    display: flex;
    flex-direction: row;
    text-transform: none;
    justify-content: center;
    align-items: center;
    gap: 31px;
    padding: 10px 20px 10px 20px;
    border-radius: 70px;
    background-color: var(--Chip-orange) !important;
    cursor:pointer;
      transition: all 0s ease-out;
  }
  .Done-ContactUsModalChip {
    max-width:100%;
    max-height: 41px;
    display: flex;
    flex-direction: row;
    text-transform: none;
    justify-content: center;
    align-items: center;
    gap: 31px;
    padding: 10px 30px 10px 30px;
    border-radius: 70px;
    background-color: var(--Chip-orange) !important;
    cursor:pointer;
      transition: all 0s ease-out;
      margin: auto;
  }
  .ContactUsModalChip-text {
    flex-grow: 0;
    font-family: FontMedium;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: var(--white-color);
    cursor:pointer;
    transition: all 0s ease-out;
  }
  .ContactUsModal-btnImg{
    width: 40px;
    margin-top:-11px;
    position: absolute;
    left: 13px;
  }
  .ContactUsModal-btn-text{
    font-family: FontSemiBold;
    font-weight: 500;
    font-size: 18px;
    text-transform: none;
    text-decoration:none;
  }
  .ContactUsModal-btnI{
    -webkit-filter: invert(100%);
    filter: invert(100%)
  }
  .ContactUsModal-btn2{
    width: 50%;
    font-family: FontSemiBold;
    color: var(--black-color);
    border: 1.8px solid;
    border-color: var(--black-color);
    margin-left: auto;
    padding: 10.5px 16px  8.5px  16px ;
  }
  .ContactUsModal-btn-text2{
    width: 100%;
    font-family: FontSemiBold;
    font-size: 18px;
    font-weight: 500;
    text-transform: none;
    text-decoration:none;
  }
  .ContactUsModal-Time-Box{
    display: flex;
align-self: center;
align-items: center;
  }
  .Blue-TimeBox{
    width: 100%;
  }
  .ContactUsModal-Now-Box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    max-width: max-content;
  padding: 10px 18px 10px 18px;
  border-radius: 13px;
  background-color: var(--light-Skyblue);
}
.ContactUsModal-Monthly-box{
    display: flex;
    align-items: center;
    width: 100%;
 max-height: 81.2px;
  flex-grow: 0;
  margin: 17px 0 22.7px;
  padding: 16.6px 0px 13.9px 0px;
  border-radius: 13px;
  background-color: var(--white-color);
}
.ContactUsModalTabsBox{
    text-transform: none;
    width:fit-content;
    padding: 0px 0px 0px 0px;
    background-color: transparent !important;
  }
  .ContactUsModal-Daily-Box{
    width:fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.ContactUsModal-SlotBox{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 6px;}
.ContactUsModalCarousel{
        width: 100%;
        display: flex;
        align-items:center;
      justify-items:center;
      gap: 15px;}
    .ContactUsModalS-timeChip {
        max-width: 116px;
        max-height: 41px;
        display: flex;
        flex-direction: row;
        text-transform: none;
        justify-content: center;
        align-items: center;
        gap: 31px;
        padding: 10px 20px 10px 20px;
        border-radius: 70px;
        background-color: var(--Chip-orange) !important;
        cursor:pointer;
          transition: all 0s ease-out;
          margin: auto;
      }
      .ContactUsModalS-Timetext {
        flex-grow: 0;
        font-family: FontMedium;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: var(--white-color);
        cursor:pointer;
        transition: all 0s ease-out;
      }
      .ContactUsModal-Chip {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-transform: none;
        padding: 10px 30px 10px 30px;
        border-radius: 70px;
        background-color: var(--white-color) !important;
        cursor:pointer;
        transition: all 0s ease-out;
        margin: auto;
      }
      .ConnectC-Box{
        display: flex;
        align-self: flex-start;
        align-items: center;
        width: 100%;
        margin-top: -43px;
      }
    .ConnectCButton1{
        display: flex;
        flex-direction: column;
        margin-left: auto;
        color: var(--black-color) !important;
        background-color: transparent !important;
        margin-right: -38px;
    }
    .callIcon-btnimg{
      width:21px;
    }
    .ConnectCButton{
        color: var(--black-color) !important;
        background-color: transparent !important;
        margin-left: -43px;
    }
    .ConnectCButtonicon{
    font-size:25px;
    }
      .ContactUsModalTime-text {
        flex-grow: 0;
        font-family: FontSemiBold;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: var(--black-color);
        cursor:pointer;
        transition: all 0s ease-out;
      }
      .Consult-Done-btnBox{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
      }
      .Consult-Done-btn {
        width: 100%;
        max-width:150px;
        display: flex;
        flex-direction: row;
        text-transform: none;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 10px 16px;
        border-radius: 4px;
        background-color: var(--feedback-orange) !important;
        color:var(--white-color) !important;
      }
      .Consult-Done-text {
        flex-grow: 0;
        font-family: FontSemiBold;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: -0.15px;
        text-align: center;
        color: var(--white-color);
      }
      @media screen and (max-width:560px) {
        .ContactUSModal-box{
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
        }
        .ContactUsModal-btn2{
            margin-left:0px;
        }
        .ContactUSModal-MainBox{
            padding:28px 30px 28px 30px;
        }
      }

      @media screen and (max-width:520px) {
        .ContactUsModal-Chip1 {
          padding: 10px 15px 10px 15px;
          }
            .ContactUsModal-Chip {
              padding: 10px 15px 10px 15px;
            }
            .ContactUsModalS-timeChip {
              padding: 10px 15px 10px 15px;
            }
      }
      @media screen and (max-width:320px) {
        .timeSlotflex {
          width: 65px;
        }
        .ContactUsModalTabsBox{
          width:65px;
          max-width: 65px;
          min-width: 65px;
        }
        .TabsIndicatorBook {
          max-width:60px;
        }
        .Mon-Text {
          flex-grow: 0;
          margin: 0 5px 0px;
        }
      }