@import "../../../values/Colored.css";

.Submain-box{
  display:flex;
  flex-direction:column;
   align-items:center;
    justify-content:center;
     width:100%;}
.AllCards-box{
  display:grid;
  grid-template-columns: 1fr 1fr 1fr;
     width:100%;
     gap:20px;}
.caroselClass{
  width: 88%;}
.Review_image{
  width: 60px; 
  height:60px;
  align-self: center;
   border-radius: 50%;
   max-width:100%;
  flex-grow: 0;
  margin: 0px 17.4px 1.6px 18.4px;
}
.box-members-say{
  padding: 0px 220px 50px 220px;
  background-color: var(--MyReport-bg);
}
.MembersSay-text {
    margin: 30px 3px 15px 21.6px;
    font-family: FontSemiBold;
    font-size: 34px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.96;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-Txt);
  }
.AllCards-box-name{
  display: flex;
  gap: 0px;
  align-items: center;
  margin-top: 12px;
}
  .ViewAll-btn {
    width: 100%;
    max-width: 167px;
    height: 40px;
    display: flex;
    flex-direction: row;
    text-transform: none;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 23.4px 26.5px 0px 7px;
    padding: 10px 16px;
    border-radius: 4px;
    max-width: 139.085px;
    height: 34.569px;
      gap:7px;
      padding: 9px 12.5px 9px 12.5px;
      border: solid 1px var(--darkBlue) !important;
      background-color: transparent !important;
  }
  .ViewAll-text {
    flex-grow: 0;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.15px;
    text-align: center;
    font-family:FontRegular;
    letter-spacing: -0.154px;;
    text-align: center;
    color: var(--darkBlue);
  }
.Card-background {
    width:30vh;
    width:100%;
    padding: 28px 21px 25px ;
    border-radius: 16px;
    border: solid 1px var(--transperant-black);
    background-color: var(--white-color);

  } 
  .AllCards-card-text{
    flex-grow: 0;
    font-family:FontRegular;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.15px;
    color: var(--black-Txt);
  }
  .AllCards-card-text1{
    height: 65px;
    overflow: hidden;
    width: 100%;
    flex-grow: 0;
    font-family:FontRegular;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.15px;
    color: var(--black-Txt);
  }
  .allcard-owername{
    flex-grow: 0;
    font-family: FontSemiBold;
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.15px;
    color: var(--black-color);
  }
  .morelesstext{
    font-family: FontRegular;
    font-weight: normal;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.15px;
    color: var(--black-Txt);
    text-transform: none;
    text-decoration: underline;
  }
  .morelessbtn-mainbox{
    display: flex;
    justify-content:flex-start;
    margin-left: -10px;
    margin-top: 0px;
  }
  .morelessbtn{
    background-color: transparent !important;
    cursor:pointer;
    transition: all 0s ease-out;
  }
  .morelesstext-morebtnbox{
background-color: var(--white-color);
display: flex;
gap: 12px;
  }
 .morelesstext-morebtn-text{
  color: var(--inputIcon);
  margin-top: -2px;
  }
  .FeedBackexpert-Box{
    display: flex;
    align-self: center;
    align-items: center;
    width:100%;
   position:absolute;
   margin-bottom:45px;
  }
  .FeedBackCButton-prev{
    color: var(--black-color) !important;
    background-color: transparent !important;
    min-width: 18px;
    max-width: 38px;
    height: 38px;
    border: 2px solid;
    border-color: var(---border1-color);
    border-radius: 50%;
    margin-left: 10px;
 }
 .FeedBackCButton-next{
  display: flex;
  flex-direction: column;
  margin-left: auto;
  color: var(--black-color) !important;
  background-color: transparent !important;
  border: 1px solid;
  border-radius: 50%;
  margin-right: 10px;
  min-width: 18px;
  max-width: 38px;
  height: 38px;
  border: 2px solid;
  border-color: var(---border1-color);
  border-radius: 50%;
}
.FeedBackCarouselBox{
  width:100%;
  display:flex;
   align-items:center ;
   justify-content:center;
    flex-direction:column;
}
  @media screen and (max-width:1630px) {
    .Card-background {
      padding: 26px 18px 22px;
    }
  }
  @media screen and (max-width:1439px) {
    .AllCards-card-text1{
      letter-spacing: 0.15px;
    }
    .box-members-say{
      padding: 0px 180px 50px 180px;
    }
  }
  @media screen and (max-width:1116px) {
    .AllCards-box{
      grid-template-columns: 1fr 1fr;
      padding: 15px;}
    .box-members-say{
      padding: 0px 7px 0px 2px;
    }
    .MembersSay-text{
      font-size:32px;
    margin: 40px 3px 0px 21.6px;
    }
    .Card-background{
      max-width:370px;
      margin: auto;
      margin-bottom: 32px;
      margin-top: 10px;
      padding: 20px 15px 16px;
    }
    .AllCards-card-text1{
      height: 84px;
    }
        .ViewAll-btn {
         margin: 23.4px 26.5px 10px 7px;
        }
  }
  @media screen and (max-width:873px){
    .AllCards-box{
      margin-left: 0%;
    }
  }
  @media screen and (max-width:804px) {
    .AllCards-box{
         gap:2%; 
    }
    .AllCards-card-text1{
    font-size: 13.6px;
    line-height: 1.50399;
    }
    .Card-background {
 height: 100%;
    }
  }
@media screen and (max-width:699px) {
.Card-background {
  padding: 6% 5% 5%;
}
.AllCards-card-text1{
  letter-spacing: 0.50px;
}
}
@media screen and (max-width:600px) {
  .Card-background {
    padding: 10% 5% 5%;
margin-bottom: 15px;
margin-top: 10px;
max-width: 240px;
height:auto;
  }
  .AllCards-box-name{
    gap: 10px;
  }
  .box-members-say{
    padding: 0px 7px 30px 7px;
  }
  .Review_image{
    max-width: 45px;
    max-height: 45px;
    margin: 0px 0px 1.6px 0px;
  }
}
@media screen and (max-width:600px) {
  .Card-background {
    width:100%;
    padding: 6% 5% 6%;
max-width:95%;
  } 
  .ViewAll-btn {
    max-width: 139.085px;
    height: 34.569px;
      gap:7px;
      padding: 9px 12.5px 9px 12.5px;
  }
  .ViewAll-text {
    font-family:FontSemiBold;
    font-size: 13px;
    font-weight: normal;
    line-height: 20px;
  }
  .AllCards-card-text1{
    font-weight: 400;
    line-height: 22px;
    color: var(--txtblack-color);
    }
    .AllCards-card-text{
      font-weight: 400;
      line-height: 22px;
      color: var(--txtblack-color);
      }
      .MembersSay-text {
        margin: 35px 0px 20px 0px;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.154px;
      }
      .allcard-owername{
         font-size: 14px;
        font-weight: normal;
        line-height: 20px;
      }
      .morelesstext{
          color: var(--txtblack-color);
          cursor: pointer;
      }
}
  @media screen and (max-width:550px) {
      .AllCards-card-text1{
        height: 83.5px;
      }
    
      .Card-background {
        width: 90%;
      }
  }
  @media screen and (max-width:500px) {
  .Card-background {
    width: 85%;
  }
}
  @media screen and (max-width:378px) {
    .AllCards-card-text1{
      letter-spacing: 0.70px;
    }
  }
  @media screen and (max-width:367px) {
    .Card-background {
      width: 82%;
    }

  }
  @media screen and (max-width:350px) {
    .AllCards-card-text1{
      letter-spacing: 1.2px;
    }
  }
  @media screen and (max-width:340px) {

    .AllCards-card-text1{
      letter-spacing: 0.9px;
    }
  }
  @media screen and (max-width:332px) {
    .AllCards-card-text1{
      letter-spacing: 0.8px;
    }
  }