.journeyMain-Box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 25px;
  padding: 8px 77px 0px 77px;
  margin: auto;
  background-image: url("/src/images/Reports-Icons.png");
  background-repeat: no-repeat;
  background-position-x:90%;
  background-position-y: center;
  background-color: var(--MyReport-bg);
}
.journeyMain-Box1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 8px 77px 0px 77px;
  margin: auto;
}
.aiDR-img {
  margin-top: auto;
  width: 100%;
  max-width:100px;
}
.aiDR-img1 {
  margin-top: auto;
  width: 100%;
  max-width: 290px;
  left: 40px;
  position: relative;
}
.reportIcon-img{
  width: 100%;
  max-width: 180px;
  position: relative;
  top: 200px;
  left: 2%;
}
.reportIcon-imgHome{
  width: 100%;
  max-width: 150px;
  margin-left: auto;
  align-self: center;
}
.points_Info-Box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;

}
.points_Info-Box1 {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}
.cancerReport-Box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  border-radius: 35px;
  padding: 3px 30px 40px 30px;
  max-width: max-content;
  gap: 20px;
}
.cancerReport-Box1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  border-radius: 35px;
  padding: 50px 70px 50px 70px;
  max-width: max-content;
  gap: 20px;
  margin-top: 160px;
  margin-bottom: -160px;
    background-color: var(--MyReport-bg);
}
.ReportHeading-Box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 7px;
}
.CancerReport-txt {
  font-family: FontSemiBold;
  font-size: 34px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.0;
  letter-spacing: normal;
  text-align: center;
  color: var(--black-Txt);
}
.path_to_treatment-txt {
  font-family:FontRegular;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: var( --txtBlack-light);
}
.reportInputs_main-Box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 15px;
}
.report-errorBox{
  width:100%;
  max-width: 350px;
}
.reportInputs-Box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  gap: 15px;
}
.reportInputs-Box1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  gap: 15px;
}
.reportInputs_Form {
  width: 100%;
  max-width: 350px;
  max-height:50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.MuiInputBase-root::before {
  border: none;
}
.report_Inputs {
  width: 350px;
  max-width: 350px;
  min-height: 50px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: FontRegular;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.15px;
  border-radius: 4px;
  padding: 0px 5px 5px 10px;
  margin: 6px 0px 0px 0px;
  background-color: var(--white-color);
  color: var(--black-color) !important;
  border: solid 1px var(---border2-color);
}
.report_Inputs-label {
  font-family: FontRegular;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.15px;
  text-align: left;
  margin: 7px 0px 0px 0px;
  color: var(--wellness-grey-txt) !important;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  max-width: max-content;
}
.my_plan-Btn {
  width: 100%;
  text-decoration: none;
  text-transform: none;
  background-color: var(--consult-docbtn) !important;
  max-width: 300px;
  padding: 10px;
  color: var(--white-color);
  gap: 15px;
  margin-top: 15px;
}
.my_plan-txt {
  font-family: FontSemiBold;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0px;
  text-align: left;
  color: var(--white-color) !important;
}
@media screen and (max-width:1620px) {
  .journeyMain-Box {
    background-position-x: 98%;
  }
  .journeyMain-Box1 {
    width: 98%;
    padding: 8px 20px 0px 20px;
  }
}
@media screen and (max-width:1750px) {
  .reportInputs-Box1 {
    grid-template-columns: 1fr 1fr ;
  }
}
@media screen and (max-width:1560px) {
  .journeyMain-Box1 {
    margin-left:-30px;
  }
  .reportInputs_Form {
    max-width: 320px;
    max-height: 50px;
  }
  .report_Inputs {
    max-width: 320px;
  }
  .report_Inputs-label {
    margin: 7px 0px 0px 0px;
  }
  .path_to_treatment-txt {
    font-size: 16px;
    text-align: center;
  }
}
@media screen and (max-width:1473px) {

  .reportIcon-img{
    max-width: 150px;
    margin-top:25px;
    left: 2%;
  }
  .reportIcon-imgHome{
    max-width: 120px;
  }
}
@media screen and (max-width:1445px) {
  .path_to_treatment-txt {
    width: 80%;
  }
  .reportIcon-img{
    max-width: 120px;
    margin-top:60px;
    left: 2%;
  }
  .reportIcon-imgHome{
    max-width: 100px;
  }
}
@media screen and (max-width:1410px) {
  .ReportHeading-Box {
    width: 85%;
  }
  .reportIcon-img{
    width: 0%;
    max-width:0;
    margin-top:0;
    left: 2%;
  }
  .reportIcon-imgHome{
    width: 0%;
    max-width: 0px;
  }
.cancerReport-Box {
  padding: 3px 10px 40px 10px;
}
.journeyMain-Box {
background-size:150px 320px;
}
.path_to_treatment-txt {
  width: 88%;
}
.journeyMain-Box1 {
  margin-left:-10px;
}
}
@media screen and (max-width:1338px) {
  .path_to_treatment-txt {
    width: 97%;
  }
}
@media screen and (max-width:1270px) {
  .path_to_treatment-txt {
    width: 100%;
  }
}
@media screen and (max-width:1213px) {
  .journeyMain-Box {
    background-image: none;
  }
  .journeyMain-Box1 {
    margin-left:-10px;
  }
  .ReportHeading-Box {
    width: 100%;
    gap: 15px;
    margin-right: 0px;
  }
  .my_plan-Btn {
    margin-left:0%;
    margin-right: 0%;
      }
      .reportInputs-Box {
        grid-template-columns: 1fr 1fr;
          padding-right:0%;
        }
        .reportInputs-Box1 {
          padding-right:0%;
        }
}
@media screen and (max-width:1060px) {
  .points_Info-Box1 {
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 80px;
  }
  .journeyMain-Box {
    padding: 30px 20px 20px 20px ;
  }
  .cancerReport-Box1 {
    margin-top: 10px;
  }
    .aiDR-img1 {
    position:inherit;
  }
  .journeyMain-Box1 {
    margin-left:10px;
  }
}
@media screen and (max-width:850px) {
  .cancerReport-Box {
    max-width: 100%;
    padding: 10px 0px 10px 0px;
  }
  .report-errorBox{
    max-width: 100%;
  }
  .reportInputs_Form {
    max-width: 100%;
    max-height: 50px;
  }
  .report_Inputs {
    width: 100%;
    max-width: 100%;
  }
}
@media screen and (max-width:770px) {
.report_Inputs {
  width: 100%;
}
}
@media screen and (max-width:600px) {
  .reportInputs-Box1 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
  }
  .ReportHeading-Box{
    min-height: 68px;
  }
    .reportInputs-Box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 10px;
    max-width: 350px;
  }
  .cancerReport-Box {
    padding: 0px 0px 20px 0px;
  }
  .cancerReport-Box1 {
    padding: 30px 20px 40px 20px;
  }
  .journeyMain-Box1 {
    width: 100%;
    padding: 8px 0px 0px 0px;
    margin-left: 0px;
  }
  .CancerReport-txt {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.154px;
  }
  .path_to_treatment-txt {
  font-family:FontLight;
    font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px;
color: var(--txtBlack-light) !important;
letter-spacing: -0.154px;
  }
  .my_plan-txt {
    font-weight: 400;
    line-height: normal;
  }
  .my_plan-Btn{
    height: 50px;
  }
  .aiDR-img {
    max-width: 170px;
  }
}
@media screen and (max-width:500px) {
  .report_Inputs {
    margin: auto;
    margin: 0px 0px 0px 0px;
  }
  .report_Inputs-label {
    width: 100%;
    max-width: 140px;
  }
}
@media screen and (max-width:330px) {
  .report_Inputs-label {
    padding-left: 1%;
  }
}