
.innerBlog-Header{
    position:sticky;
    z-index: 5;
}
.innerBlog{
    overflow: hidden;
}
.Loading-effect2{
 width: 100%;
 display: flex;
 justify-content: center;
 align-items: center;   
}
.Loading-effect{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; 
    padding: 50px;  
    margin-left: -40px;
   }
   .dwnload-innerBlog{
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
   }
   @media screen and (max-width:1000px) {
    .Loading-effect2{
        margin-bottom: 20px;  
       }
       .Loading-effect{
        margin-left: 0px;
       }
   }   