@import "../../../values/Colored.css";


.Main-ListViewBox{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap:10%;
    margin: 25px 0px;
}
.List-name-imgBox{
    display: flex;
    align-items: center;
    justify-self:center;
    gap: 15px;
}
.List-name-imgBox1{
    display: flex;
    align-items: center;
    justify-self:center;
    gap: 15px;
    margin-left: auto;
}
.nameAndQuantityBox{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.ListView-img{
    max-width: 90px;
    min-width: 90px;
    min-height: 90px;
    max-height: 90px;
    background-color: var(--white-color) !important;
    border: solid 1px var(--Border-shadow);
    border-radius: 12px;
    padding:7px;
}
.list-view-DeletIcon{
    width: 33%;
}
.ListHeadProduct-txt{
    min-width: 107px;
    text-transform: none;
    font-family: FontSemiBold;
    font-size:18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: var(--black-color);
}
.ListProduct-quantity-txt{
    flex-grow: 0;
    font-family: FontSemiBold;
    font-size:14px;
    font-weight:400;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.98;
    letter-spacing: normal;
    color: var(--wellness-grey); 
}
.quantityTotal-txt{
    width:25px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: none;
    font-family: FontSemiBold;
    font-size:12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: var(--black-color) !important;  
}
.ListD-PriceTxt{
    min-width:75px;
    flex-grow: 0;
    font-family: FontSemiBold;
    font-size:14px;
    font-weight:400;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.98;
    letter-spacing: normal;
color: var(--text-grey);
}
.List-PriceTxt{
    min-width:75px;
    flex-grow: 0;
    font-family: FontSemiBold;
    font-size:14px;
    font-weight:400;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.98;
    letter-spacing: normal;
    color: var(--black-color);
}
.Listquantity-btn-Box{
    display: flex;
    align-items: center;
    justify-content: center;
    padding:5px;
    gap:20px;
    background-color: var(--white-color);
    border: solid 1px var(--Border-shadow);
    border-radius: 5px;
    }
    .List-Delete-icon{
        color: var(--black-color);
    }
    .Listplus-minusBtn{
        text-transform: none;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width:25px;
        min-width:25px;
        max-height:25px;
        min-height:25px;
        background-color: var(--Border-shadow) !important;
        color: var(--white-color) !important;
        border-radius: 24px;
    }
    .Listplus-minusBtn1{
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: none;
        max-width:25px;
        min-width:25px;
        max-height:25px;
        min-height:25px;
        background-color: var(--consult-docbtn) !important;
        color: var(--white-color) !important;
        border-radius: 24px;
    }
    .DVDList{
        width: 100%;
    }
    .list-view-DeletIcon{
        width:100%;
        max-width:17px;
        margin-left: auto;
    }
    .delete-mobile-Btn{
        background-color: transparent !important;
            }
    @media screen and (max-width:700px) {
        .Main-ListViewBox{
            flex-wrap: wrap;
            gap:20px;
        }
        .List-name-imgBox1{
            display: flex;
            align-items: center;
            justify-self:center;
            gap: 7px;
            margin-left:0;
        }
        .ListD-PriceTxt{
            min-width:60px;
            flex-grow: 0;
            font-family: FontSemiBold;
            font-size:14px;
            font-weight:400;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.98;
            letter-spacing: normal;
        color: var(--text-grey); 
        }
        .List-name-imgBox{
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-self:center;
            gap: 15px;

        }
        .quantityTotal-txt{
            width:15px;
        }
        .ListD-PriceTxt{
width:57px;
        }
        .list-view-DeletIcon{
            width:100%;
        }
        .delete-mobile-Btn{
    max-width:32px;
    min-width:32px;
    margin-bottom: auto;
    margin-left: auto;
    background-color: transparent !important;
        }
    }
