@import "../../../values/Colored.css";

.Main-AdvisoryBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url("/src/images/AdivsoryRing.png");
    background-repeat: no-repeat;
    background-size:100%;
    background-position: center;
    background-position-y:top;
    padding-left: 77px;
    padding-right: 77px;
    padding-top: 62px;
    margin-bottom: 60px;
}
.Alltext-Box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Advisory-Text {
  max-width: 224px;
  margin: 50px 0px 0px 0px;
  font-family: FontSemiBold;
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.81;
  letter-spacing: normal;
  color: var(--black-color);
}
.Advisory-description-Text {
  max-width: 844.3px;
  margin: 28px 0px 40.2px 0px;
  font-family:FontRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align:left;
  color: var(--black-color);
}
.DoctorsList-Box{
  width:100%;
   display:flex;
}
@media screen and (max-width:1000px) {
  .Main-AdvisoryBox{
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 0px;
    background-size:130%;
    background-position-y:180px;
  }
.Advisory-Text{
  margin: 0px 0px 0px 0px;
}
  .Advisory-description-Text{
    margin: 28px 0px 0px 0px;
  }
}

