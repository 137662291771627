@import "../../../values/Colored.css";

.TreatmentInfo-Box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .ExpertImage{
    width: fit-content;
    max-width: 40%;
    margin: 0px 0px 50px 0px;
    border-radius: 28px;
    background-color: var(--wellness-grey);
  }
  .ExpertDesc-Text {
    width: 100%;
    max-width: 869px;
    font-family: FontRegular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-color);
  }
  @media screen and (max-width:1000px) {
    .ExpertImage{
      max-width: 100%;
      margin: 20px 0px 20px 0px;
    }
    .TreatmentInfo-Box{
      margin-top: -40px;
      padding: 0px 20px 20px 20px;
    }
  }
  @media screen and (min-width:2500px) {
    .ExpertDesc-Text {
      max-width: 1200px;
    }
  }