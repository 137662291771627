@import "../../../values/Colored.css";


.Cart-Main-Box{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top:50px;
    padding-bottom: 35px;
    gap:10px;
}
.heading-txt{
    text-transform: none;
    font-family: FontSemiBold;
    font-size:16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: var(--white-color);
}
.CartHeadS-heading-txt{
    text-transform: none;
    font-family: FontSemiBold;
    font-size:16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: var(--white-color);
    text-decoration: underline;
    text-decoration-color:var(--event-orange);
    text-decoration-thickness: 2px;
    text-underline-position: under;
}
@media screen and (max-width:1000px) {
    .Cart-Main-Box{
        flex-wrap: wrap;
    }
}
@media screen and (min-height:1000px) {
  .Cart-Main-Box{
    padding-top: 70px;
         }
}
  @media screen and (min-height:1350px) {
    .Cart-Main-Box{
      padding-top: 100px;
           }
  }
  @media screen and (min-height:1750px) {
    .Cart-Main-Box{
      padding-top: 150px;
           }
  }