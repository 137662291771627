@import "../../../../values/Colored.css";
.SharkTank-MainBox{
    display: flex;
    align-items: center;
    justify-content: center;
 margin:100px 0px 77px 0px;
 width: 100%;
    height:100%;
    width:50vw;
    height:27vw;
}
@media screen and (max-width:1000px) {
    .SharkTank-MainBox{
        width:60vw;
        height:35vw;}}
@media screen and (max-width:700px) {
    .SharkTank-MainBox{
        width:70vw;
        height:40vw;}  }
@media screen and (max-width:500px) {
    .SharkTank-MainBox{
        width:80%;
        height:48vw;
        margin:100px 0px 50px 0px;}  }
  @media screen and (min-height:1350px) {
    .SharkTank-MainBox{
      padding-top: 100px;
           }}
  @media screen and (min-height:1750px) {
    .SharkTank-MainBox{
      padding-top: 150px;}}