@import "../../../../values/Colored.css";
.caregiverpatient-Box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:30px 45px 80px 45px;
}
.caregiverpatient-title-text{
    font-family:FontBold;
    font-style: normal;
    font-size:26px;
    text-align: center;  
    color: var(--Modal-head);
}
.caregiverpatient-imgbox{
    width: 100%;
    margin-top: 35px;
    display: flex;
    justify-content: space-between;
    gap: 15px;
}
.cargiverpatient-imgss{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-transform: none;
    text-decoration: none;
    width: 100%;
    max-width:180px;
    max-height:max-content;
    border-radius: 15px;
    border: 2px solid;
    border-color:var(--copy-rightTxt) !important ;
    text-align: center;
    padding: 5%;
}
.caregive-img{
    width: 100%;
    min-height: 150px;
}
.patient-img{
width:100%;
max-height: 150px;
}
.cargiverpatient-imgss:hover{
    border: 2px solid;
    border-color:var(--feedback-orange) !important ;
}
.cargiverpatient-imgss-text1{
    font-family:FontBold;
    font-style: normal;
    font-size:18px;
    color: var(--black-color);
    margin-top: auto;
} 

@media screen and (max-width:550px) {.caregiverpatient-Box{padding:20px 36px 80px 35px;}}
@media screen and (max-width:490px) {.caregiverpatient-Box{padding:20px 18px 80px 18px;}}
@media screen and (max-width:445px) {.caregiverpatient-Box{padding:20px 12px 40px 12px;}
.caregiverpatient-imgbox{
        justify-content: center;
        align-items: center;
        flex-direction: column;}}

