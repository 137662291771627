@import "../../../values/Colored.css";

.MfooterMain-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
      flex-grow: 0;
      padding: 30px 20px 50px 20px;
      border-radius: 41px;
      background-color: var(--darkBlue);
     gap:15px;
      overflow: hidden;
      background-image: url("/src/images/footerBack.webp");
      background-position:center;
      background-position-x:center;
      background-position-y:7vh;
      background-size:95%;
       background-repeat: no-repeat; 
       margin-left:20px;
       margin-right: 20px;
       margin-bottom:40px;
    }
    .helplineNumber-box{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-bottom: 10px;
        gap: 7%;
        padding-right: 15px;
    }
    .AllLinkBtn-box{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        gap: 10%;
        margin-bottom: 20px;
    }
    .leftRightLink-box{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        max-width: max-content;
        gap: 15px;
        margin: auto;
    }
    .helpline-txt{
        flex-grow: 0;
        font-family:FontSemiBold;
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: var(--white-color);
    }
    .helpline-numbertxt{
        flex-grow: 0;
        font-family:FontSemiBold;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: var(--white-color);
    }
    .Linknavigate-txt{
        text-decoration: none;
        flex-grow: 0;
        font-family:FontRegular;
        font-size: 16px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: var(--white-color);
        -webkit-tap-highlight-color: transparent !important;
    }
    .download-app-box{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 5px;
    }
    .Stores-btns-box{
        width:100%;
        max-width: 140px;
        height: max-content;
          }
    .SocialMedia_Main-Box{
        display: flex;
        align-items:center;
        justify-content: center;
        flex-direction: column;
        gap: 15px;
      }
      .NGO-Box{
        display: flex;
        align-items:center;
        justify-content: center;
        flex-direction: column;
        gap: 5px;
      }
      .socialMedia_heading-txt{
        text-decoration: none;
        flex-grow: 0;
        font-family:FontRegular;
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: var(--footer_txt);
    }
    .SocialMedia-Box{
        display: flex;
        align-items:center;
      }
          .Privacy_sitemap-Box{
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            align-self: flex-start;
            gap: 10px;
          }
          .terms_sitemap-Link{
            width: 100%;
            max-width: max-content;
            text-decoration: none;
            text-transform: none;
            background-color: transparent !important; 
            flex-grow: 0;
            font-family:FontMedium;
            font-size:12px;
            font-weight: 400;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            line-height: 16px;
            color: var(--white-color); 
          }
          .Privacy-SitemapDvd{
            height: 10px;
            margin-top: 3px;
            border-color: var(--white-color);
          }
          .copyRight_addressBox{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 15px;
          }
          .copyRight_address-txt{
            text-decoration: none;
            flex-grow: 0;
            font-family:FontRegular;
            font-size: 12px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: var(--footer_txt);
        }
        .Ngo_SocialMedia-Box{
          flex-wrap: nowrap;
              }
              .socialMedia-link{
                padding:3px
              }
              .socialMedia-link1{
                padding:4px
              }
              @media screen and (max-width:415px) {
              .MfooterMain-box {
min-height: 680px;
              }
            }
@media screen and (max-width:388px) {
        .helpline-txt{
          font-size: 12px;
      }
      .helpline-numbertxt{
          font-size: 12px;
      }
      .terms_sitemap-Link{
        font-size: 12px;
      }
      .AllLinkBtn-box{
        gap: 2%;
      }
}
        @media screen and (max-width:380px) {
     .MfooterMain-box{
          padding: 30px 15px 50px 15px;
     }
          .Ngo_SocialMedia-Box{
    flex-wrap: nowrap;
        }
        .SocialMedia_Main-Box{
          gap: 5px;
        }
        .helplineNumber-box{
        padding-right: 7px;
        }
        .Youtube-iconSize{
          width:15px;
          margin-top: 5px;
           }
           .Insta-iconSize{
               width:15px;
                }
           .Facebook-iconSize1{
               width:8px;
               }
                .LoveHeals-iconSize{
                   width: 100%;
                   min-width: 70px;
                }
                .terms_sitemap-Link{
                  font-size: 11px;
                }
                .helpline-txt{
                  font-size: 14px;
              }
              .helpline-numbertxt{
                  text-align: left;
              }
          .Privacy_sitemap-Box{
            gap: 5px;
          }
        }
        @media screen and (max-width:318px) {
          .Privacy_sitemap-Box{
            flex-wrap: wrap;
            gap: 3px;
          }
          .terms_sitemap-Link{
            font-size: 10px;
          }
          .helplineNumber-box{
            align-items: flex-start;
            justify-content: flex-start;
            flex-wrap: wrap;
        padding-right: 0px;
          }
          .socialMedia-link{
            padding:0px
          }
          .socialMedia-link1{
            padding:0px
          }
          .Linknavigate-txt{
            font-size: 14px;
        }
        }