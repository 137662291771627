@import "../../../values/Colored.css";

.zenOncoApp_main-Box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0px 77px 0px 77px;
    gap: 35px;
    min-height: 510px;
    margin: 10px 0px 2px 0px;
}
.Appimagespoints-Box{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 35px;
}
.main-img {
    width: 100%;
    max-width: 180px;
    min-width: 180px;
}
.Head_Points-Box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;
}
.zenOncoApp_Head-txt {
    font-family: FontSemiBold;
    font-size: 34px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.15px;
    text-align: center;
    margin: 0px 0px 0px 0px;
    color: var(--black-Txt) !important;
    width: 100%;
}
.zenOncoApp_sub-txt {
    font-family: FontRegular;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.15px;
    text-align: center;
    margin: 15px 0px 0px 0px;
    color: var(--black-color) !important;
}
.zenOncoApppoints-Box {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    gap: 15px;
    text-transform: none;
    color: var(--black-color);
    background-color: transparent !important;
    cursor: pointer;
    transition: all 0s ease-out;
}
.zenOncoApp_points-icon {
    width: 18px;
    height: 18px;
    margin-top: 5px;
          }
.zenOncoApp_points-txt{
    font-family:FontRegular;
       font-size: 18px;
       font-stretch: normal;
       font-style: normal;
       line-height: 1.7;
       letter-spacing: normal;
       text-align: left;
       color: var(--black-Txt);
     }
.app_playstore-Box{
display: flex;
align-items: flex-start;
justify-content: flex-start;
margin-top: -5px;
gap: 5px;
}
.DownloadBtn{
    width: 100%;
    height: 40px;
    border-radius: 24px;
}
.app_playstore-img{
cursor: pointer;
}
.images-boxmobile{
    min-height: 370px;
}
@media screen and (max-width:1000px) {
    .zenOncoApp_main-Box{
        flex-wrap: wrap;
padding: 30px 20px 30px 20px ;
    }
    .zenOncoApp_Head-txt{
        font-size: 30px;
        line-height: 1.1;
    }
        .zenOncoApp_Head-txt{
            text-align: center;}
            .app_playstore-img{
                width: 100%;
                max-width:150px;
            }
}

@media screen and (max-width:600px) {
    .zenOncoApp_main-Box{
gap: 25px;
    }
    .zenOncoApp_Head-txt {
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.154px;
    }
    .Appimagespoints-Box{
        flex-wrap:wrap;
        gap: 10px;
    }
    .zenOncoApp_points-txt{
        font-size: 14px;
font-weight: 400;
letter-spacing: -0.154px;
    }
    .zenOncoApp_points-icon {
        width: 15px;
        height: 15px;
              }
              .Head_Points-Box {
                gap: 25px;
            }
            .main-img {
                max-width: 265px;
            }
}
