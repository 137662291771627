@import "../../../values/Colored.css";


.MainContactUs-Box{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 100px 0px 130px 0px;
}

.ContactUs-Text{
    font-family: FontRegular;
    font-size: 56px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align:center;
    color: var(--white-color);
}

.ContactUs-Consultation-Text{
    max-width:431px;
margin: 24px 0px 0px 0px;
    font-family: FontRegular;
    font-size: 32px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align:center;
    color:var(--white-color);
}

  @media screen and (min-height:1350px) {
    .MainContactUs-Box{
      padding-top: 130px;
           }
  }
  @media screen and (min-height:1750px) {
    .MainContactUs-Box{
      padding-top: 190px;
           }
  }
