@import "../../../values/Colored.css";

.storieHope_main-Box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 27px 0px 43px 0px;
    gap: 35px;
    background-color:var(--white-color);
}
.storiesHope_head-txt {
    font-family: FontSemiBold;
    font-size: 34px;
  font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.15px;
    text-align: center;
    margin: 15px 0px 0px 0px;
    color: var(--black-Txt) !important;
    width: 100%;
}
.storiesHope_sub-txt {
    font-family: FontRegular;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.15px;
    text-align: center;
    margin: 15px 0px 0px 0px;
    color: var(--black-color) !important;
    width: 100%;
}
.hope_carousel-Box {
    width: 100%;
    margin: 0 -10px;
}
.slick-slide > div {
    margin: 0 7px;
  }
  .slick-list {
    margin: 0 -7px;
  }
.hope_imgs {
    width: 100%;
    max-width: 160px;
    max-height: 150px;
    border-radius: 10px;
    margin: 0 10px;
    z-index: 1;
}
.txt-color {
    text-decoration: none;
    text-transform: none;
    color:var(--consult-docbtn);
}
.Maincontainer {
    position: relative;
    width:100%;
    border-radius: 10px;
    cursor: pointer;
  }
  .Hover-image {
    width: 100%;
    height: auto;
    max-width: 160px;
    max-height: 150px;
    border-radius: 10px;
  }
  .Hover-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color:var(--Blue-slide-box);
    max-width: 160px;
    max-height: 150px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .Maincontainer:hover .Hover-overlay {
    opacity: 1;
  }
  .Hover_head-txt{
    font-family: FontMedium;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.15px;
    text-align: center;
    color: var(--white-color) !important;
    width: 100%;
  }
  .Hover_read-txt{
    font-family: FontMedium;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.15px;
    text-align: center;
    color: var(--hover-read-txt);
    width: 100%;
  }
  .Storieswidth100{
    width: 100%;
  }
@media screen and (max-width:1000px) {
    .storieHope_main-Box {
        padding: 40px 0px 40px 0px;

    }
    .storiesHope_head-txt {
      font-size: 30px;
  }}
@media screen and (max-width:900px) {
    .Hover_head-txt{
        font-family: FontMedium;
        font-size: 12px;
    }
    .Hover_read-txt{
        font-family: FontMedium;
        font-size: 10px;
    }}
    @media screen and (max-width:600px) {
      .storiesHope_head-txt {
        font-size: 24px;
    }
    }
@media screen and (max-width:415px) {
  .Hover_head-txt{
    font-size: 15px;
  }
  .Storieswidth100{
min-height: 200px;
  }
}
@media screen and (max-width:360px) {
  .Hover_head-txt{
    font-size: 12px;
  }
}