@import "../../../values/Colored.css";

.zioperInnerDetails_main-Box{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding: 40px 77px;
}
.zioperTab_panels-Box{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}
.zioperTabpanel-Box{
    width: 100%;
}
.tabs-details-Box{
    text-transform: none;
    width: 100%;
    border: 1px solid  var(--consult-cardborder);
    min-width: 350px;
    max-width: 350px;
}
.tabs-details-Box1{
    text-transform: none;
    width: 100%;
    border: 1px solid  var(--consult-cardborder);
    background-color: var(--consult-docbtn) !important;
    min-width: 350px;
    max-width: 350px;
}
.ZioperDetails_pointImg{
    width:15px;
    height:15px;
margin-top:3px;
margin-left: 25px;
}
.txtDot_box{
    font-size: 22px;
font-family: FontBold;
font-weight: 1000;
    margin-left: 75px;
    margin-top: -5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.ZeoperDetails-Indicator {
    background-color:transparent;
  }
.ZioperDetails_HeadTxt{
    font-family: FontSemiBold;
    font-size:32px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-color);
}
.txtwith_logo-Box{
display: flex;
align-items: flex-start;
gap: 10px;
}

.ZioperDetails_pointTxt{
    font-family:FontRegular;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-color);
    gap: 5px;
}
.Panel-details-Box{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    padding: 30px;
    border: 1px solid  var(--consult-cardborder);
    gap: 25px;
}
.zioperTab-label{
    display: flex;
    font-family:FontMedium;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-color);
    margin-right: auto;
}
.zioperTab-label1{
    display: flex;
    font-family:FontMedium;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: var(--white-color);
    margin-right: auto;
}
@media screen and (max-width:1000px) {
    .zioperInnerDetails_main-Box{
        padding: 40px 20px;
}
}
@media screen and (max-width:700px) {
    .zioperTab_panels-Box{
        flex-wrap: wrap;
    }
    .tabs-details-Box{
        min-width: 150px;
        max-width: max-content;
    }
    .tabs-details-Box1{
        min-width: 150px;
        max-width: max-content;
    }
    .Panel-details-Box{
        border:none;
        padding:15px 0px;
}
    .ZioperDetails_pointImg{
    margin-left:5px;
    }
    .ZioperDetails_pointTxt{
        font-size: 16px;
    }
    .txtDot_box{
        font-size: 18px;
        margin-left:50px;
    }
    .ZioperDetails_HeadTxt{
        font-size:30px;
    }
}
