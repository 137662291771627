@import "../../../values/Colored.css";
.MainAddressSelect-Box{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap:70px;
    margin: 30px 77px 100px 77px;
}
.addressBars-Box{
    display: flex;
    flex-direction: column;
    gap:15px;
    margin-bottom: 25px;
}
.add-Address-Btn{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: max-content;
    text-transform: none;
    text-decoration: none;
       font-family: FontRegular;
    font-size: 12px;
    font-weight: 500;
    flex-grow: 0;
    border-radius: 4px;
    gap:25px;
    border: solid 1px;
    border-color: var(--consult-docbtn) !important;
    color: var(--consult-docbtn) !important;
    margin-left: auto;
    padding: 18px;
}
.payment-mainBox{
  width: 30%;
}
.AddressContinue-btn {
    width: 100%;
    text-transform: none;
    min-width: max-content;
    border-radius: 4px;
    gap:25px;
    border: solid 1px;
    margin-left: auto;
    padding: 18px;
    background-color: var(--consult-docbtn) !important;
    color:var(--white-color) !important;
  }
  .DisabledAddressContinue-btn {
    width: 100%;
    text-transform: none;
    min-width: max-content;
    border-radius: 4px;
    gap:25px;
    border: solid 1px;
    margin-left: auto;
    padding: 18px;
    background-color: var(--background) !important;
    color:var(--white-color) !important;
  }
  .AddressContinue-text {
    flex-grow: 0;
    font-family: FontRegular;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.15px;
    text-align: center;
    color: var(--white-color);
  }
  .Address-selectbtnBox{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap:50px;
  }
  .Address-leftBox{
width:70%
  }
  @media screen and (max-width:1000px) {
    .MainAddressSelect-Box{
      flex-wrap: wrap;
      margin: 30px 20px 100px 20px;
  }
  .Address-leftBox{
    width:100%
      } 
      .Address-selectbtnBox{
 gap: 30px;
      }
  }
  @media screen and (max-width:1000px) {
    .Address-selectbtnBox{
    flex-wrap: wrap;
           }
           .addressBars-Box{
            width: 100%;
            display: flex;
            flex-direction: column;
            gap:15px;
            margin-bottom: 25px;
        }
        .payment-mainBox{
          width: 100%;
          max-width: max-content;
        }
  }