@import "../../../values/Colored.css";
   .expert-box{
      text-transform: none;
      width: 94%;
     max-width:250px;
     min-height:350px;
     text-decoration: none;
      flex-grow: 0;
      margin: 39.1px 0px 0px 0px;
      padding: 15.8px 15px 0px 19px;
      border-radius: 15px;
      border: 0.25vh solid;
      border-color: var(--consult-cardborder);
      background-color: var(--white-color) !important;
      display: flex;
      flex-direction:column;
      margin: auto;
      -webkit-tap-highlight-color: transparent;
      box-shadow: 0px 3px 3px 3px var(--card-shodow);
    }
    .expert-boxawrd{
      text-transform: none;
      width: 97%;
     max-width:380px;
     min-height:250px;
     text-decoration: none;
      flex-grow: 0;
      padding: 7px 7px 0px 7px;
      border-radius: 15px;
      border-radius: 15px;
      border: 0.25vh solid;
      border-color: var(--awrd-border);
      background-color: var(--white-color) !important;
      display: flex;
      flex-direction:column;
      margin: auto;
      margin-bottom: 5px;
      margin-top:0px;
      -webkit-tap-highlight-color: transparent;
    }
    .expert-boxsd{
      text-transform: none;
      width: 94.5%;
     max-width:250px;
     min-height:354px;
     text-decoration: none;
      flex-grow: 0;
      margin: 39.1px 0px 0px 0px;
      padding: 15.8px 15px 0px 19px;
      border-radius: 15px;
      border: 0.25vh solid;
      border-color: var(--consult-cardborder);
      background-color: var(--white-color) !important;
      display: flex;
      flex-direction:column;
      -webkit-tap-highlight-color: transparent;
      box-shadow: 0px 3px 3px 3px var(--card-shodow);
      margin: auto;
     }
     .expert-boxmd{
      text-transform: none;
      width: 94.5%;
     max-width:250px;
     min-height:379px;
     text-decoration: none;
      flex-grow: 0;
      margin: 39.1px 0px 0px 0px;
      padding: 15.8px 15px 0px 19px;
      border-radius: 15px;
      border: 0.25vh solid;
      border-color: var(--consult-cardborder);
      background-color: var(--white-color) !important;
      display: flex;
      flex-direction:column;
      margin: auto;
      -webkit-tap-highlight-color: transparent;
      box-shadow: 0px 3px 3px 3px var(--card-shodow)
     }
   .expert-image{
      width: 24vh;
      height:23vh;
      max-height: 190px;
      align-self: center;
        border-radius: 5%;
        max-width:100%;
      flex-grow: 0;
      margin: 0px 17.4px 5.6px 11.4px;
   }
   .expert-imagemd{
      width: 24vh;
      height: 24vh;
      max-height: 200px;
      align-self: center;
        border-radius: 5%;
        max-width:100%;
      flex-grow: 0;
      margin: 0px 17.4px 10px 11.4px;
   }
   .expert-imagesd{
      width: 100%;
      height: 24.5vh;
      max-height: 190px;
      align-self: center;
        border-radius: 5%;
        max-width:100%;
      flex-grow: 0;
      margin: 0px 17.4px 10px 15px;
      object-fit: cover;
      }
      .expert-imageawd{
         width: 42vh;
         height: 245px;
         align-self: center;
           border-radius: 4%;
           max-width:100%;
         flex-grow: 0;
         margin: 0px 0px 9px 0vh;
         background-color:var(--text-black);
         }
         .expert-imageawd1{
            width: 42vh;
            height: 245px;
            align-self: center;
              border-radius: 4%;
              max-width:100%;
            flex-grow: 0;
            margin: 0px 0px 9px 0vh;
            background-color:var(--text-black);
         object-fit: cover;
            }
         .expert-brandlogo{
            height: 45px;
            align-self: center;
              border-radius: 4%;
              max-width:100%;
            flex-grow: 0;
            margin: 5px 0px 10.6px -1vh;
            }
   .expert-nametext{
      flex-grow: 0;
      margin: 3px 0 0px 1.6px;
  font-family: FontRegular;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      text-align: center;
      color: var(--black-Txt);
      height: 20px;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
   }
   .expert-nametext2{
      flex-grow: 0;
      margin: 0px 0 0px 1.6px;
  font-family: FontRegular;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      text-align: center;
      color: var(--black-Txt);
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
   }
   .expert-nametextmd{
      flex-grow: 0;
      margin: 0px 0 0px 1.6px;
     font-family: FontRegular;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      text-align: center;
      color: var(--black-Txt);
      height: 40px;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
   }
   .expert-nametextsd{
      flex-grow: 0;
      padding: 0px 14px 0px 14px;
  font-family: FontRegular;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      text-align: center;
      color: var(--black-Txt);
      height: 50px;
      -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
   }
   .expert-linkawd{
      text-decoration: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
   }
   .expert-nametextawd{
      flex-grow: 0;
      padding: 0px 14px 0px 14px;
      margin: 5px 0px 8px 0px ;
  font-family: FontRegular;
      font-size: 16px;
      font-stretch: normal;
      font-weight: 400;
      font-style: normal;
      line-height: 1.4;
      text-decoration:none;
      letter-spacing: normal;
      text-align: center;
      color: var(--black-Txt);
      height: 38px;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      justify-content: center;
     overflow: hidden;
   }
   .expert-typetext{
   max-width: 100%;
      flex-grow: 0;
      margin: 5.5px 0px 0px 0;
  font-family: FontLight;
      font-size: 12px;
      font-weight: 600;
      font-stretch:normal;
      font-style: normal;
      line-height: 1.13;
      letter-spacing: 1;
      text-align: center;
      color: var(--txtBlack-light);
   }
   .expert-typetextmd{
      position: relative;
      max-width: 100%;
         flex-grow: 0;
         margin: -5px 0px 12px 0;
     font-family: FontLight;
         font-size: 12px;
         font-weight: 600;
         font-stretch:normal;
         font-style: normal;
         line-height: 1.13;
         letter-spacing: normal;
         text-align: center;
         color: var(--txtBlack-light);
         height: 11px;
      }
   .expert-typetext2{
      max-width: 100%;
         flex-grow: 0;
         margin: 5px -10px 0px 0;
     font-family: FontLight;
         font-size: 12px;
         font-weight: 600;
         font-stretch:expanded;
         font-style: normal;
         line-height: 1.13;
         letter-spacing:.4px;
         text-align: center;
         color: var(--txtBlack-light);
      }
     .searchPage-typetext2{
      max-width: 100%;
      flex-grow: 0;
      margin: 5.5px -10px 0px 0;
       font-family: FontMedium;
      font-size: 12px;
      font-weight: 600;
      font-stretch:expanded;
      font-style: normal;
      line-height: 1.13;
      letter-spacing:.4px;
      text-align: center;
      color: var(--bg-subtext1);
    }
      .expert-typetextsd{
         max-width: 100%;
            flex-grow: 0;
            margin: 5.5px 0px 1px 0;
        font-family: FontLight;
            font-size: 12px;
            font-weight: 600;
            font-stretch:normal;
            font-style: normal;
            line-height: 1.13;
            letter-spacing: normal;
            text-align: center;
            color: var(--txtBlack-light);
         }
   .expert-consultbtn{
     background-color: var(--consult-docbtn) !important;
     padding: 5px 19px 5px 19px ;
     margin: 10px 0px 15px 0px ;
     border-radius: 5px;
     text-decoration: none;
     height: 33px;
   }
   .expert-consultbtn2{
      background-color: var(--consult-docbtn) !important;
      padding: 5px 05px 5px 5px ;
      margin: 10px 0px 5px 0px ;
      border-radius: 7px;
      text-decoration: none;
      width: 100%;
      height: 38px;
      max-width: 130px;
    }  
    .expert_consultbtntext{
      margin-top: 2px;
      display: flex;
      flex-grow: 0;
      text-transform: none;
      font-family: FontRegular;
      font-size: 13px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.04;
      letter-spacing: normal;
      text-align: left;
      color: var(--white-color);
   }
     .expert-consultbtnsd{
      background-color: var(--consult-docbtn) !important;
      padding: 7px 05px 7px 5px ;
      margin: 0px 0px 20px 0px ;
      border-radius: 7px;
      text-decoration: none;
      width: 100%;
      height: 40px;
      max-width: 100px;
    }
   .expert-pricebox{
      display: flex;
      justify-content: center;
      margin-top:9px;
      gap: 2px;
   }
   .expert-rs{
      max-width: 100%;
      flex-grow: 0;
      margin: 1px 0px 0px 1px;
      font-family: FontRegular;
      font-size: 14px;
      font-weight: 600;
      font-stretch:normal;
      font-style: normal;
      line-height: 1.13;
      letter-spacing: normal;
      text-align: center;
      color: var(--black-Txt);
   }
   .expert-rs2{
      max-width: 100%;
      flex-grow: 0;
      margin: 5.5px 0px 0px 0;
      font-family: FontRegular;
      font-size: 14px;
      font-weight: 600;
      font-stretch:normal;
      font-style: normal;
      line-height: 1.13;
      letter-spacing: 1;
      text-align: center;
      color: var(--black-Txt);
      display: flex;
   }
.expert-rsymbol{
   max-width: 100%;
   flex-grow: 0;
   margin: 0.5px 0px 0px 0;
   font-size: 14px;
   font-weight: 600;
   font-stretch:normal;
   font-style: normal;
   line-height: 1.13;
   letter-spacing: normal;
   text-align: center;
   color: var(--black-Txt);
}
.expert-rsymbol2{
   max-width: 100%;
   flex-grow: 0;
   margin: -1px 2px 0px 4px;
   font-size: 15px;
   font-weight: 600;
   font-stretch:normal;
   font-style: normal;
   line-height: 1.13;
   letter-spacing: normal;
   text-align: center;
   color: var(--black-Txt);
}
.expert-rsymbolmd{
   max-width: 100%;
   flex-grow: 0;
   margin:5px 0px 0px 0;
   font-size: 14px;
   font-weight: 400;
   font-stretch:normal;
   font-style: normal;
   line-height: 1.13;
   letter-spacing: normal;
   text-align: center;
   color: var(--black-Txt);
}
.expert-rsymbolmd2{
   max-width: 100%;
   flex-grow: 0;
   margin:5px 0px 0px 0;
   font-size: 14px;
   font-weight: 500;
   font-stretch:normal;
   font-style: normal;
   line-height: 1.13;
   letter-spacing: normal;
   text-align: center;
   color: var(--black-Txt);
}
.expertlCarousel{
   width: 100%;
   display: flex;
   align-items:center;
 justify-items:center;
}
.expert-Box{
   display: flex;
   align-self: center;
   align-items: center;
   width: 100%;
  margin-top: -210px;
  position: absolute;
 }
 .expert-Box2{
    display: flex; 
   align-self: center;
   align-items: center;
   width: 100%;
  margin-top: -210px;
  margin-bottom: 190px;
 }
 .careCenterbtn-Box{
   display: flex; 
   align-self: center;
   align-items: center;
   width: 100%;
  margin-top: -192px;
  margin-bottom: 192px;
 }
 .searchpagebtn-box{
   display: flex; 
   align-self: center;
   align-items: center;
   width: 100%;
  margin-top: -210px;
  margin-bottom: 190px;
 }
 .expertCButton-next{
   display: flex;
   flex-direction: column;
   margin-left: auto;
   color: var(--black-color) !important;
   background-color: transparent !important;
   border: 1px solid;
   border-radius: 50%;
   margin-right: -40px;
   min-width: 18px;
   max-width: 38px;
   height: 38px;
   border: 2px solid;
   border-color: var(---border1-color);
   border-radius: 50%;
}
.centerList-next{
   display: flex;
   flex-direction: column;
   margin-left: auto;
   color: var(--black-color) !important;
   background-color: transparent !important;
   border: 1px solid;
   border-radius: 50%;
   margin-right: 20px;
   min-width: 18px;
   max-width: 38px;
   height: 38px;
   border: 2px solid;
   border-color: var(---border1-color);
   border-radius: 50%;
}
.expertCButton-nextawd{
   display: flex;
   flex-direction: column;
   margin-left: auto;
   color: var(--black-color) !important;
   background-color: transparent !important;
   border: 1px solid;
   border-radius: 50%;
   margin-right: -40px;
   min-width: 18px;
   max-width: 38px;
   height: 38px;
   border: 2px solid;
   border-color: var(---border1-color);
   border-radius: 50%;
}
.expertCButton-nextawd2{
   display: flex;
   flex-direction: column;
   margin-left: auto;
   color: var(--black-color) !important;
   background-color: transparent !important;
   border: 1px solid;
   border-radius: 50%;
   margin-right: -40px;
   min-width: 18px;
   max-width: 38px;
   height: 38px;
   border: 2px solid;
   border-color: var(---border1-color);
   border-radius: 50%;
}
.expertCButton-prev{
   color: var(--black-color) !important;
   background-color: transparent !important;
   margin-left: -40px;
   min-width: 18px;
   max-width: 38px;
   height: 38px;
   border: 2px solid;
   border-color: var(---border1-color);
   border-radius: 50%;
}
.centerList-prev{
   color: var(--black-color) !important;
   background-color: transparent !important;
   margin-left: 20px;
   min-width: 18px;
   max-width: 38px;
   height: 38px;
   border: 2px solid;
   border-color: var(---border1-color);
   border-radius: 50%;
}
.expertCButton-prev2{
   color: var(--black-color) !important;
   background-color: transparent !important;
   margin-left: -40px;
   min-width: 18px;
   max-width: 38px;
   height: 38px;
   border: 2px solid;
   border-color: var(---border1-color);
   border-radius: 50%;
}
.searchpage-prev{
   color: var(--black-color) !important;
   background-color: transparent !important;
   margin-left: -45px;
   min-width: 18px;
   max-width: 38px;
   height: 38px;
   border: 2px solid;
   border-color: var(---border1-color);
   border-radius: 50%;
}
.searchpage-next{
   display: flex;
   flex-direction: column;
   margin-left: auto;
   color: var(--black-color) !important;
   background-color: transparent !important;
   border: 1px solid;
   border-radius: 50%;
   margin-right: -45px;
   min-width: 18px;
   max-width: 38px;
   height: 38px;
   border: 2px solid;
   border-color: var(---border1-color);
   border-radius: 50%;
}
.expertCButtonicon{
font-size:25px;
color: var(---border1-color);
}
.medicines-consultbtn{
   background-color: var(--consult-docbtn) !important;
   padding: 2px 19px 2px 19px ;
   margin:5px 0px 10px 0px ;
   border-radius: 5px;
   text-decoration: none;
   height: 34px;
}
.medicines-typetext{
display: flex;
justify-content: center;
gap: 6px;
margin-top: -15px;
margin-bottom: 0px;
}
.medicines-rs{
   max-width: 100%;
   flex-grow: 0;
   margin: 5.5px 0px 0px 0;
   text-decoration: line-through;
   font-family: FontRegular;
   font-size: 14px;
   font-weight: 600;
   font-stretch:normal;
   font-style: normal;
   line-height: 1.13;
   letter-spacing: normal;
   text-align: center;
   color: var(--black-Txt);
}
.medicines-rss{
   max-width: 100%;
   flex-grow: 0;
   margin: 5.5px 0px 0px 0;
   font-family: FontRegular;
   font-size: 14px;
   font-weight: 600;
   font-stretch:normal;
   font-style: normal;
   line-height: 1.13;
   letter-spacing: normal;
   text-align: center;
   color: var(--black-Txt);
}
@media screen and (max-width:1800px) {
   .expert-boxawrd{
      width: 93%;
      min-height:20px;
   }
   .expert-imageawd{
      width: 100%;
      height: 220px;
   }
   .expert-imageawd1{
      width: 100%;
      height: 220px;
   }
   .expert-brandlogo{
      height: 40px;
      margin: 5px 0px 8.6px -1vh;
   }
   .expert-Box2{
    margin-top: -185px;
    margin-bottom: 185px;
   }
   .expert-boxmd{
     min-height:355px;
     }
     .expert-boxsd{
      min-height:333px;
      max-width:240px;
      }
 }
 @media screen and (max-width:1508px) {
   .expert-box{
  width: 97%;
    }
    .expert-typetext2{
      width: 110%;
      margin-left: -10px;
      max-width: none;
      }}
 @media screen and (max-width:1320px) {
    .expert-typetext2{
      width: 120%;
      margin-left: -14px;
      height: 25px;
      }
      .searchPage-typetext2{
        height: 15px;
       }
      .expert-Box2{
         margin-top: -175px;
         margin-bottom: 175px;
        }}
 @media screen and (max-width:1200px) {
   .expertCButton-next{
      margin-right: -4vh;
   }
   
   .expertCButton-prev{
      margin-left: -4vh; 
   }}
 @media screen and (max-width:1177px) {
   .expertCButton-next{
      margin-right: -4vh;
   }
 
   .expertCButton-prev{
      margin-left: -4vh; 
   }
 }
 @media screen and (max-width:1136px) {
   .expertCButton-next{
      margin-right: -5vh;
   }
   
   .expertCButton-prev{
      margin-left: -5vh; 
   }
 }
 @media screen and (max-width:1064px) {
   .expertCButton-next{
      margin-right: 0vh;
   }
   .expertCButton-prev{
      margin-left: 0vh; 
   }
   }
   @media screen and (max-width:1050px) {
      .searchpage-prev{
         margin-left: -4.2vh
      }
      .searchpage-next{
         margin-right: -4.2vh;
      }
   }
 @media screen and (max-width:1000px) {
   .expertCButton-next{
      margin-right: -4vh;
   }
   .expertCButton-prev{
      margin-left: -4vh; 
   }
   .searchpage-prev{
      margin-left: -4.5vh
   }
   .searchpage-next{
      margin-right: -4.5vh;
   }
}
 @media screen and (max-width:906px) {
   .expertCButton-next{
      margin-right: -6vh;
   }
   .expertCButton-prev{
      margin-left: -6vh; 
   }}
 @media screen and (max-width:828px) {
   .expertCButton-next{
      margin-right: -5vh;
   }
   .expertCButton-prev{
      margin-left: -5vh; 
   }
}
@media screen and (min-height:950px) {
   .searchpage-prev{
      margin-left: -3.2vh
   }
   .searchpage-next{
      margin-right: -3.2vh;
   }
}
@media screen and (max-width:812px) {
   .searchpage-prev{
      margin-left: -3vh
   }
   .searchpage-next{
      margin-right: -3vh;
   }
}
 @media screen and (max-width:730px) {
   .expertCButton-prev2{
      margin-left: 4%;
   }
   .expertCButton-nextawd2{
     margin-right: 4%;
   }
   .expert-nametextawd{
      margin: 5px 0px 9px 0px ;
   }
   .expert-boxawrd{
     min-height:auto;
    }
    
   }
   @media screen and (max-width:710px) {
      .searchpage-prev{
         margin-left: -3.5vh
      }
      .searchpage-next{
         margin-right: -3.9vh;
      }
   }
   @media screen and (max-width:700px) {
      .searchpage-prev{
         margin-left: -5.5vh
      }
      .searchpage-next{
         margin-right: -5.4vh;
      }
   }
 @media screen and (max-width:700px) {
   .expertCButton-next{
      margin-right: -5.5vh;
   }
   .expertCButton-prev{
      margin-left: -5.5vh; 
   }}
 @media screen and (max-width:665px) {
   .expertCButton-prev2{
      margin-left: 0vh;
   }
   .expertCButton-nextawd2{
     margin-right: 0vh;
   }}
 @media screen and (max-width:636px) {
   .expertCButton-next{
      margin-right: -5.7vh;
   }
   .expertCButton-prev{
      margin-left: -5.7vh; 
   }
   .expertCButton-prev2{
      margin-left: -2vh;
   }
   .expertCButton-nextawd2{
     margin-right: -2vh;
   }}
   
   @media screen and (max-width:600px) {
      .expert-nametextawd{
         font-size: 14px;
      }
      .expert_consultbtntext{
         font-family: FontLight;
         font-weight: 600;
         letter-spacing: 1px;
      }
      .centerListCard-box{
         text-transform: none;
         width: 100%;
        max-width:350px;
        text-decoration: none;
         flex-grow: 0;
         margin: 39.1px 0px 20px 0px;
         display: flex;
         flex-direction:column;
         margin: auto;
         -webkit-tap-highlight-color: transparent;
       }
       .expert-image{
           border-radius:10%;
           margin: 10px 17.4px 5.6px 12px;
      }
      .expert-nametext{
     font-family: FontLight;
         color: var(--text-event);
      }
      .expert-nametextmd{
     font-family: FontLight;
         color: var(--text-event);
      }
      .expert-consultbtn{
         margin: 10px 0px -2px 0px ;
         width: 100%;
         height: 35px;
         max-width: 115px;
       }
       .expert-box{
        min-height:0px;
         padding: 15.8px 2px 18px 7px;
         border-radius: 20px;
        max-width:240px;
       }
      .expert-nametextsd{
         margin: 20px 0 0px 1.6px;
     font-family: FontLight;
         font-size: 16px;
         color: var(--text-event);
      }
      .expert-nametext2{
     font-family: FontLight;
         color: var(--text-event);
      }
      .expert-typetext{
            margin: 0px 0px 0px 0;
            font-size: 13px;
            color: var(--bg-subtext1);
         }
      .expert-typetext2{
         max-width: 100%;
            margin: 0px -10px -8px 0;
            color: var(--bg-subtext1);
         }
      .expert-imagemd{
         width: 22vh;
         height: 23vh;
     max-height: 180px;
         margin: 8px 17.4px 5.6px 11.4px;
      }
      .expert-imagesd{
         height: 190px;
         width: 30vh;
         margin: 5px 0px 1.6px 0vh;
         border-radius: 15px;
         }
         .expert-pricebox{
            margin-top:5px;
         }
         
      .expert-rsymbol{
         max-width: 100%;
         flex-grow: 0;
         margin:5.5px 0px 0px 0;
         font-size: 14px;
         font-weight: 400;
         font-stretch:normal;
         font-style: normal;
         line-height: 1.13;
         letter-spacing: normal;
         text-align: center;
         color: var(--black-Txt);
      }
      .expert-rs{
         margin: 5px 0px 0px 1px;
      }
      .expert-rs2{
         font-family: FontLight;
         font-size: 12px;
      }
      .expert-typetextmd{
            margin: -5px 0px 15px 0;
            color: var(--black-Txt);
         }
      .expert-rsymbolmd{
            margin:10px 0px 0px 0;
            font-size: 13px;
      }
      .expert-rsymbolmd2{
         margin:10px 0px 0px 0;
         font-size: 13px;
      }
      .medicines-rs{
         margin: 10px 0px 0px 0;
         font-size: 13px;
         font-weight: 500;
      }
      .medicines-rss{
         margin: 10px 0px 0px 0;
         font-size: 13px;
         font-weight: 500;
      }
      .medicines-consultbtn{
         margin:5px 0px 12px 0px ;
         width: 60%;
      }
      .expert-boxmd{
        max-width:240px;
     min-height:380px;
     padding: 15.8px 5px 0px 5px;
        }
        .expert-boxsd{
         min-height:355px;
        max-width:240px;
        }
        .expert-consultbtnsd{
         margin: 12px 0px 10px 0px ;
         border-radius: 6px;
         height: 35px;
         max-width: 110px;
       }
   }
 @media screen and (max-width:592px) {
   .expertCButton-prev2{
      margin-left: -4.2vh;
   }
   .expertCButton-nextawd2{
     margin-right: -4.2vh;
   }
   .expert-boxawrd{
      margin-bottom: 5px;
     padding: 8px 8px 0px 8px;
     max-width: 320px;
     border-color: var(--award-brdr);
   }
}
 @media screen and (max-width:560px) {
   .expertCButton-prev2{
      margin-left: -2.5vh;
   }
   .expertCButton-nextawd2{
     margin-right: -2.5vh;
   }
   .searchpage-prev{
      margin-left: 3vhs
   }
   .searchpage-next{
      margin-right: 3vh;
   }
 }
 @media screen and (max-width:530px) {
   .expertCButton-prev2{
      margin-left: -4vh;
   }
   .expertCButton-nextawd2{
     margin-right: -4vh;
   }
   .expertCButton-next{
      margin-right: -2vh;
   }
   .expertCButton-prev{
      margin-left: -2vh; 
   }}
   @media screen and (max-width:507px) {
      .searchpage-prev{
         margin-left: 1vh
      }
      .searchpage-next{
         margin-right: 1vh;
      }
   }
 @media screen and (max-width:500px) {
   .expertCButton-prev2{
      margin-left: -2vh;
   }
   .expertCButton-nextawd2{
     margin-right: -2vh;
   }
   .expertCButton-next{
      margin-right: 3.5vh;
   }
   .expertCButton-prev{
      margin-left: 3.5vh; 
   }
   .expert-image{
      width: 23vh;
   }
   .expert-imagemd{
      width: 23vh;
   }
   .expert-imageawd{
      height: 208px;
   }
   .expert-imageawd1{
      height: 208px;
   }
   .expert-Box2{
      margin-top: -180px;
      margin-bottom: 180px;
   }
}
 @media screen and (max-width:470px) {
   .expertCButton-prev2{
      margin-left: -3.5vh;
   }
   .expertCButton-nextawd2{
     margin-right: -3.5vh;
   }
   .searchpage-prev{
      margin-left: -0.5vh
   }
   .searchpage-next{
      margin-right: -0.5vh;
   }
   .centerListCard-box{
      width: 80%;
     max-width:350px;
    }
    .centerList-prev{
      margin-left: 10px;
   }
   .centerList-next{
      margin-right: 10px
   }
}

@media screen and (max-width:450px) {
   .expertCButton-prev2{
      margin-left: -5.2vh;
   }
   .expertCButton-nextawd2{
     margin-right: -5.2vh;
   }
   .centerListCard-box{
      width: 72%;
     max-width:350px;
    }
}
 @media screen and (max-width:437px) {
   .expertCButton-next{
      margin-right: 0vh;
   }
   .expertCButton-prev{
      margin-left: 0vh; 
   }
   .searchpage-prev{
      margin-left: -2.2vh
   }
   .searchpage-next{
      margin-right: -2.2vh;
   }
}
 @media screen and (max-width:422px) {
   .expertCButton-next{
      margin-right: 0vh;
   }
   .expertCButton-prev{
      margin-left: 0vh; 
   }
   .expert-boxawrd{
      max-width: 275px;
      min-height:250px;
     }
     .expert-imageawd{
      height: 180px;
      }
      .expert-imageawd1{
         height: 180px;
         }
      .expert-brandlogo{
         height: 37px;
         }
         .expertCButton-prev2{
            margin-left: -3.5vh;
         }
         .expertCButton-nextawd2{
           margin-right: -3.5vh;
         }

         } 
 @media screen and (max-width:407px) { 
   .expertCButton-prev2{
      margin-left: -5.5vh;
   }
   .expertCButton-nextawd2{
     margin-right: -5.5vh;
   }
   .searchpage-prev{
      margin-left: -3vh
   }
   .searchpage-next{
      margin-right: -3vh;
   }
}
@media screen and (max-width:400px) {
   .careCenterbtn-Box{
     margin-top: -178px;
     margin-bottom: 178px;
    }
}
 @media screen and (max-width:384px) {
   .expertCButton-next{
      margin-right: -1vh;
   }
   .expertCButton-prev{
      margin-left: -1vh; 
   }
   .expert-imageawd{
      height: 169px;
   }
   .expert-imageawd1{
      height: 169px;
   }
   .expert-boxawrd{
      min-height:25px;
      max-width: 255px;
   }
   .searchpage-prev{
      margin-left: -3vh
   }
   .searchpage-next{
      margin-right: -3vh;
   }

}
 @media screen and (max-width:376px) {  
   .expert-imageawd{
      height: 170px;
   }
   .expert-imageawd1{
      height: 170px;
   }
   .expert-brandlogo{
      height: 35px;
   }
   .expert-boxawrd{
      width: 100%;
      max-width: 250px;
   }
   .expert-imagemd{
      height: 24.5vh
   }
   }
 @media screen and (max-width:369px) {
   .expertCButton-next{
      margin-right: -2vh;
   }
   .expertCButton-prev{
      margin-left: -2vh; 
   }
   .expert-box{
      max-width:220px;
   } 
   .expert-boxsd{
      max-width:220px;
   }   
   .expert-boxmd{
      max-width:220px;
     min-height:370px;
   }  
   .expert-Box2{
      margin-top: -165px;
      margin-bottom: 165px;
   }
   .centerListCard-box{
      width: 69%;
     max-width:350px;
    }
    .careCenterbtn-Box{
      margin-top: -165px;
      margin-bottom: 165px;
     }
   .expert-boxawrd{
      min-height:20px;
   }}

 @media screen and (max-width:354px) {
   .expert-imageawd{
      height: 180px;
   }
   .expert-imageawd1{
      height: 180px;
   }
   }
 @media screen and (max-width:338px) {
   .expert-boxawrd{
      width: 100%;
      min-height:180px;
   }
   .expert-imageawd{
      height: 160px;
   }
   .expert-imageawd1{
      height: 160px;
   }
   .centerList-prev{
      margin-left: 4px;
   }
   .centerList-next{
      margin-right: 4px
   }
   .expert-Box2{
     width: 105%;
   }
   }
 
 @media screen and (max-width:315px) {
   .expert-box{
      max-width:210px;
   } 
   .expert-boxsd{
      max-width:210px;
   }  
   .expert-boxmd{
      max-width:210px;
   } 
   .expert-boxawrd{
      min-height:180px;
   }
   .expert-Box2{
      margin-top: -160px;
      margin-bottom: 160px;
   }
}
 @media screen and (max-width:303px) {
   .expert-box{
     max-width:200px;
     }  
     .expert-boxsd{
      max-width:210px;
      } 
      .searchpage-prev{
         min-width: 30px;
         max-width: 30px;
         height: 30px;
      }
      .searchpage-next{
         min-width: 30px;
         max-width: 30px;
         height: 30px;
      }
   }
