@import  "../../../values/Colored.css";

.MainSearchResult-Box{
display: flex;
align-items: flex-start;
justify-content: flex-start;
flex-direction: column;
margin: 45px 150px;
gap: 40px;
}
.Blogsexpert-Box{
    display: flex;
    align-self: center;
    align-items: center;
    width: 100%;
   margin-top: -260px;
   position: absolute;
  }
.Button_div{
    text-transform: none;
    width: 97%;
   text-decoration: none;
    border-color: var(--consult-cardborder);
    background-color: var(--white-color) !important;
    -webkit-tap-highlight-color: transparent;
}
.Result_type-Box{
    width: 100%;
    display: flex;
    align-items: flex-start;
justify-content:center;
flex-direction: column;
gap: 15px;
}
.Result_typeCard-Box{
width: 100%;
    position: relative;
}
.Box-result{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Searchresult_Txt{
    flex-grow: 0;
    margin: 0 0px 10px 0;
    font-family: FontSemiBold;
    font-size: 32px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-color);
    margin-left:3%;
}
.BlogsCButton-prev{
    color: var(--black-color) !important;
    background-color: transparent !important;
    margin-left: -40px;
    min-width: 18px;
    max-width: 38px;
    height: 38px;
    border: 2px solid;
    border-color: var(---border1-color);
    border-radius: 50%;
 }
 .BlogsCButton-next{
    display: flex;
    flex-direction: column;
    margin-left: auto;
    color: var(--black-color) !important;
    background-color: transparent !important;
    border: 1px solid;
    border-radius: 50%;
    margin-right: -40px;
    min-width: 18px;
    max-width: 38px;
    height: 38px;
    border: 2px solid;
    border-color: var(---border1-color);
    border-radius: 50%;
 }
 @media screen and (max-width:1200px) {
    .BlogsCButton-next{
       margin-right: -4vh;
    }
    .BlogsCButton-prev{
       margin-left: -4vh; 
    }
  }
  @media screen and (max-width:1136px) {
    .MainSearchResult-Box{
        width: 95%;
        margin: 30px 0px;
        align-items: center;
        justify-content: center;
        gap: 20px;
        }
  }
@media screen and (max-width:1050px) {
        .Result_type-Box{
            width: 90%;
        }
        .MainSearchResult-Box{
            width: 100%;
            }
}
@media screen and (max-width:935px) {
    .BlogsCButton-next{
       margin-right: -5.5vh;
    }
    .BlogsCButton-prev{
       margin-left: -5.5vh; 
    }}
    @media screen and (max-width:828px) {
        .BlogsCButton-next{
           margin-right: -4vh;
        }
        .BlogsCButton-prev{
           margin-left: -4vh; 
        }
     }
     @media screen and (min-height:950px) {
        .BlogsCButton-prev{
           margin-left: -3.3vh
        }
        .BlogsCButton-next{
           margin-right: -3.3vh;
        }
     }
     @media screen and (max-width:730px) {
        .BlogsCButton-prev2{
           margin-left: 4%;
        }
        .BlogsCButton-nextawd2{
          margin-right: 4%;
        }
    }
@media screen and (max-width:700px) {
        .Result_type-Box{
            width: 83%;
        }
        .BlogsCButton-next{
            margin-right: -5vh;
         }
         .BlogsCButton-prev{
            margin-left: -5vh; 
         }
}
@media screen and (max-width:660px) {
    .Blogsexpert-Box{
       margin-top: -225px;
      }
}
@media screen and (max-width:450px) {
    .BlogsCButton-next{
        margin-right: -2vh;
     }
     .BlogsCButton-prev{
        margin-left: -2vh; 
     }
}
@media screen and (max-width:369px) {
    .Blogsexpert-box{
        max-width:220px;
     } 
     .BlogsCButton-next{
        margin-right: -2.5vh;
     }
     .BlogsCButton-prev{
        margin-left: -2.5vh; 
     }
}
@media screen and (max-width:315px) {
    .Blogsexpert-box{
       max-width:210px;
    } 
}