.productsH-MainBox{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding:30px 0px 50px 30px;
    gap:50px;
}
.Alert-Succes{
    position: fixed;
    margin-top:39px;
margin-left:80%;
z-index: 5002;
}
.Alert-Succes-txt{
    flex-grow: 0;
    font-family: FontSemiBold;
    font-size:12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
}
.productsH-TxtBox{
align-self: center;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 100%;
max-width: fit-content;
align-self: center;
justify-self: center;
margin: 30px 0px 0px 0px;

}

.increasChances-Text{
    max-width:750px;
    font-family: FontRegular;
    font-size: 42px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: normal;
    text-align: center;
    color: var(--white-color);
}

.AllPointBox{
display: flex;
flex-wrap: wrap;

   gap: 5px;
   width: 100%;
   justify-content: center;
    
}
.AllPoint-txt{
    margin: 16.5px 0px 0px 0px;
    font-family: FontRegular;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: var(--white-color);
    width: fit-content;
}


.productsH-image{
    width: 100%;
    max-width:425px;
    margin-top: 50px;
}

@media screen and (max-width:1619px){
    .AllPoint-txt{
   font-size: 24px;
    }
}
@media screen and (max-width:1000px) {
    
    .productsH-MainBox{
        flex-wrap: wrap;
        align-items: center;
    justify-content: center;
    padding:0px 20px 50px 20px;
    }

    .productsH-image{
 max-width:300px;
        padding-right:0px ;
        margin: 0px 0px 0px 0px;
    }

    .productsH-TxtBox{
        align-items: center;
justify-content: center;
        margin:40px 0px 0px 0px;
    }
.AllPointBox{
    flex-wrap: wrap;
align-items: center;
justify-content: flex-start;
}
.increasChances-Text{
    text-align: left;
    font-size: 30px;
}
.AllPoint-txt{
    text-align: left;
   font-size: 14px;
}
}
  @media screen and (min-height:900px) {
    .productsH-MainBox{
      padding-top: 50px;
           }
  }
  @media screen and (min-height:1350px) {
    .productsH-MainBox{
      padding-top: 100px;
           }
  }
  @media screen and (min-height:1750px) {
    .productsH-MainBox{
      padding-top: 150px;
           }
  }