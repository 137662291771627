@import '../../../values/Colored.css';

.SearchHeading-mainbox{
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-end;
    padding:30px 30px 0px 30px;
    gap:20px;
}
.SearchHeading-TxtBox{
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    align-self: center;
    justify-self: center;
    margin: 30px 0px 0px 0px;
    padding:70px 30px 0px 30px;
    gap: 15px;
}
.SearchHeading-Text{
    max-width:700px;
    font-family: FontSemiBold;
    font-size: 32px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: normal;
    text-align: center;
    color: var(--white-color);
}
.SearchHeading-searchbar{
    width: 100%;
    max-width: 650px;
    border-radius: 8px;
    font-family: FontRegular;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    background-color: var(--inputBG);
    margin: 10px 0px 0px 0px;
    height: 53.5px;
    border: none !important;
}
.searchicon{
width: 40px;
    height:40px;
    margin-right: -7px;
    margin-top: -2px;
}
.searchheading-tabssbox{
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.tabs-btnbox{
    width: 100%;
    max-width: 160px;
    height: 70px;
    border-radius: 0%;
    border-bottom: 1px solid;
    border-right: 1px solid;
    background-color: var(--white-color) !important;
    border-color: var(---border2-color) !important;
}
.selecttabs-btnbox{
    width: 100%;
    max-width: 160px;
    height: 70px;
    border-radius: 0%;
    border-bottom: 1px solid;
    border-right: 1px solid;
    background-color: var( --consult-docbtn) !important;
    border-color: var(---border2-color) !important;
}
.listLength-Txt{
    max-width: 699px;
    font-family:FontRegular;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: var(--white-color);
}
.searchbar-btntext{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family:FontRegular;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1;
    text-align: center;
    padding: 2px 12px;
    border-radius: 6px;
    cursor:pointer;
    text-transform: none;
    color: var(--text-black);
}
.selcteed-btntext{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: FontMedium;
    font-size: 17px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    padding: 2px 12px;
    border-radius: 6px;
    cursor:pointer;
    text-transform: none;
    color: var(--white-color);
}

@media screen and (max-width:1000px) {
    .SearchHeading-mainbox{
        padding:0px 0px 0px 0px;
    }
    .searchbar-btntext{
        font-size:2.2vw;
    }
    .selcteed-btntext{
        font-size:2.2vw; 
    }
    .tabs-btnbox{
        height: 50px;
        max-width: min-content;
    }
.selecttabs-btnbox{
    height: 50px;
    max-width: min-content;
}
}
@media screen and (max-width:320px) {
.selecttabs-btnbox{
    max-width: 60px;
}
.tabs-btnbox{
    max-width:55px;
    min-width: 55px;
}
.searchbar-btntext{
    font-size:2.7vw;
}
.selcteed-btntext{
    font-size:2.7vw; 
}
}
  @media screen and (min-height:1600px) {
    .SearchHeading-mainbox{
      padding-top: 120px;
           }
  }