@import "../../../values/Colored.css";

.ZioperInner_main-Box{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top:80px;
}
.aiDrHead-img{
    width:30vw;
    max-width:400px;
    margin-top: auto;
}
.TechnicalHead-img{
    width: 100%;
    max-width: 160px;
}
.ZioperInner-Box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
margin: auto;
}
.innerPageHead-txt{
    font-family: FontSemiBold;
    font-size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    align-self:center;
    color:var(--white-color);
}
.innerPageSub-txt{
    max-width: 900px;
    margin: 18.9px 0px 0px 0px;
    font-family:FontRegular;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: var(--white-color);
}
.innerPageReport-btn{
    background-color: var(--consult-docbtn) !important;
    padding: 7px 30px 7px 30px ;
    margin: 0px 0px 0px 0px ;
    border-radius: 7px;
    text-decoration: none;
    width: 100%;
    height: 40px;
    max-width: max-content;
    margin-top: 15px;
}
.innerPageReport-txt{
    margin-top: 2px;
    display: flex;
    flex-grow: 0;
    text-transform: none;
    font-family: FontRegular;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: normal;
    text-align: left;
    color: var(--white-color);
}
@media screen and (min-height:1220px) {
    .ZioperInner_main-Box{
    margin-top:170px;
    }
}
@media screen and (max-width:1000px) {
    .TechnicalHead-img{
        width: 0%;
        max-width: 0px;
        margin-left: auto;
    }
.ZioperInner_main-Box{
flex-wrap: wrap;
justify-content:center;
    background-repeat: no-repeat;
    background-position-x:right;
    background-position-y:bottom;
    padding-bottom: 20px;
    margin-top:30px;
}
.aiDrHead-img{
    width:70vw;
    max-width:400px;
    margin-top: auto;
}
}