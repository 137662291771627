@import "../../values/Colored.css";

.Dashboard-MainBox{
    overflow: hidden;
}
.Header{
    min-height:55px;
position:sticky;
z-index:1001;
}
.Top{
    background-color: var(--Top-color);
}
.Footer{
    background: var(--footer_BG-Color);
    padding-top: 30px;
}
.divider-line{
    width: 70%;
}
.homefeedback-cards{
background-color:var(--bg-consultcard);
}