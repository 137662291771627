@import "../../values/Colored.css";


.Header-Top-Box{
    background-color: var(--event-darkblue);
}

.DRpage-Header{
    min-height:100px;
    position:sticky;
    z-index:5;
}
.loader-position-DR{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    align-self: center;
    margin-top:100px;
    margin-bottom: 100px;
  }