@import "../../values/Colored.css";

.downloadPage_main-Box{
    display: flex;
    flex-direction: column;
align-items: center;
justify-content: center;
width: 100%;
}
.downloadOrange-Box{
    display: flex;
    flex-direction: column;
align-items: center;
justify-content: center;
width: 100%;
background-color: var(--dwnld-orange);
padding: 15px 20px 80px 20px;
}
.downloadLogoImg{
    max-width: 220px;
}
.cancerCare-txt{
    width: 100%;
  font-size:32px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--white-color);
  font-family:FontSemiBold;
  font-weight: 600;
  line-height: 1.7;
}
.yourCompanionJourny-txt{
    width: 100%;
  font-size:16px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--white-color);
  font-family:FontRegular;
  font-weight: 600;
  line-height: 1.7;
}
.Appbtn-txtBox{
    display: flex;
    flex-direction: column;
align-items: center;
justify-content: center;
width: 100%;
margin-top: 15px;
}
.AppfromStore-txt{
    width: 100%;
  font-size:16px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--white-color);
  font-family:FontSemiBold;
  font-weight: 600;
}
.Downloadpage-AppPlay-store{
    width: 100%;
    display: flex;
    gap: 5px;
    margin-top: 15px;
  }
  .Downloadpage-PlayStore-btn{
    width:200px;
    max-height: 50px;
        border-radius: 10px;
        -webkit-tap-highlight-color: transparent;
      }
      .Downloadpage-AppStore-btn{
    width:200px;
        border-radius: 10px;
        max-height: 50px;
        -webkit-tap-highlight-color: transparent;
      }
      .Downloadpage-Store-btns-box{
    width:100%;
    max-width: 180px;
    max-height: 50px;
    border-radius: 24px;
    box-shadow: 0px 15px 10px -15px var(--bg-subtext);
      }
      .Downloadpage-Store-btns-box2{
        width:100%;
        max-width: 180px;
        max-height: 50px;
        border-radius: 24px;
        box-shadow: 0px 15px 10px -15px var(--bg-subtext);
          }
          .text-mobileimgBox{
            display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
          }
          .text-lineBox{
            display: flex;
        align-items:center;
        justify-content: flex-start;
        width: 100%;
        gap: 10px;
          }
          .lineBox{
            width: 3px;
            height: 70px;
            background-color: var(--event-orange);
          }
          .top-txt{
            width: 100%;
            font-size:20px;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: var(--black-text);
            font-family:FontLight;
            font-weight: 600;
          }
          .bottom-txt{
            width: 100%;
            font-size:21px;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: var(--black-text);
            font-family:FontSemiBold;
            font-weight: 600;
          }
          .mobileimg{
            width: 100%;
            position: relative;
            bottom: 35px;
          }