@import "../../values/Colored.css";

.AboutUs-MainBox{
    overflow: hidden;
}
.aboutus-Header{
    min-height:50px;
    position:sticky;
z-index:1200;
}
.TeamofCoaches-Top{
    background-color: var(--event-darkblue);
}
.AboutFooter{
    padding-top: 30px;
    background:var(--footer_BG-Color);
}
