@import "../../../values/Colored.css";
.subdocs_div{
 width: 100%;
   grid-template-columns: 1fr 1fr 1fr;
   display:grid;
   gap:10px;
   justify-content: center;
   align-items: center;
}
.subdocs_div2{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 30px;
  }
.subdoc_box{
  text-transform: none;
  width: 100%;
 max-width:180px;
 min-height:250px;
 text-decoration: none;
  flex-grow: 0;
  margin: 39.1px 0px 0px 0px;
  padding: 24.8px 26.5px 0px 25px;
  border-radius: 15px;
  box-shadow: 0 0 24px 0 var(--border-black) !important;
  background-color: var(--white-color) !important;
  display: flex;
  flex-direction:column;
  margin-left: auto;
  -webkit-tap-highlight-color: transparent;
 }
 .subdoc_box1{
  text-transform: none;
  width: 100%;
  max-width:150px;
  min-height: 240px;
  max-height:240px;
  text-decoration: none;
   flex-grow: 0;
   margin: 39.1px 0px 0px 0px;
   padding: 15.8px 22.5px 0px 22px;
   border-radius: 15px;
   box-shadow: 0 0 24px 0 var(--border-black);
   background-color: var(--white-color);
   display: flex;
   flex-direction:column;
   pointer-events: none;
   -webkit-tap-highlight-color: transparent;
 }
.subdoc_image{

width: 13vh;
height:12vh;
align-self: center;
  border-radius: 50%;
  max-width:100%;
flex-grow: 0;
margin: 0px 17.4px 14.6px 11.4px;
}
.doctername_text{
   flex-grow: 0;
   margin: 0px 0 0px 1.6px;
   font-family: FontRegular;
   font-size: 16px;
   font-weight: 600;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.4;
   letter-spacing: normal;
   text-align: center;
   color: var(--black-Txt);
}
.doctertype_text{
max-width: 100%;
   flex-grow: 0;
   margin: 6.5px 0px 0px 0;
   font-family: FontLight;
   font-size: 12px;
   font-weight: 600;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.13;
   letter-spacing: normal;
   text-align: center;
   color: var(--txtBlack-light);
}
.doctertype-consultbtn{

  background-color: var(--consult-docbtn) !important;
  padding: 7px 25px 7px 25px ;
  margin: 10px 0px 10px 0px ;
  margin-top: auto;
  border-radius: 7px;
  text-decoration: none;
}
.doctername_consultbtntext{
   flex-grow: 0;
   font-family: FontRegular;
   font-size: 13px;
   font-weight: 400;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.13;
   letter-spacing: normal;
   text-align: center;
   text-transform: none;
   color: var(--white-color);
}
@media screen and (max-width:1050px) {
  .subdocs_div{
    display: flex;
    flex-wrap: wrap;
    margin-left: 4%;
  }
  .subdocs_div2{
    flex-wrap: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
    align-items: center;
    justify-content: center;

  }
  .subdoc_box{
    margin-left:0px;
  }
}
@media screen and (max-width:1000px) {
  .subdocs_div{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr ;
  }
}
@media screen and (max-width:883px) {
  .subdocs_div2{
    grid-template-columns: 1fr 1fr 1fr 1fr ;
  }
}
@media screen and (max-width:876px) {
  .subdocs_div{
    margin-left: 2%;
  }
}
@media screen and (max-width:774px) {
  .subdocs_div{
    grid-template-columns: 1fr 1fr 1fr ;
    margin-left: 5%;
  }
}
@media screen and (max-width:697px) {
  .subdocs_div2{
    grid-template-columns: 1fr 1fr 1fr ;
  }
}
@media screen and (max-width:609px) {
  .subdocs_div{
    margin-left: 1%;
  }
}
@media screen and (max-width:600px) {
.doctername_text{
  font-family: FontLight;
      color: var(--text-event);
}
.doctertype_text{
font-size: 13px;
  color: var(--bg-subtext1);
}
.doctername_consultbtntext{
  font-family: FontLight;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 1px;
}
.subdoc_box1{
  min-height: 240px;
}
}
@media screen and (max-width:577px) {
  .subdocs_div{
  grid-template-columns: 1fr 1fr ;
    margin-left: 14%;
  }
}
@media screen and (max-width:523px) {
  .subdocs_div2{
    grid-template-columns: 1fr 1fr;
    margin-left: 7%;
  }
}
@media screen and (max-width:543px) {
  .subdocs_div2{
    margin-left: 0%;
  }
}
@media screen and (max-width:497px) {
  .subdocs_div{
    margin-left: 6%;
  }
}
@media screen and (max-width:434px) {
  .subdocs_div{
    margin-left: 0%;
  }
  .subdocs_div2{
    margin-left: 2%;
  }
}
@media screen and (max-width:388px) {
  .subdocs_div2{
    margin-left: 0%;
  }
}
@media screen and (max-width:358px) {
  .subdocs_div{
  grid-template-columns: 1fr 1fr;
  }
  .subdocs_div2{
    display: grid;
grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
  }
  .subdoc_box1{
    text-transform: none;
    max-width:100%;
     padding: 15.8px 8px 0px 8px;
   }
   .subdoc_image{
    width:11vh;
    height: 100%;
    }
    .doctertype-consultbtn{
width: 100%;
      padding: 7px 2px 7px 2px ;
    }
}
@media screen and (max-width:310px) {
  .subdoc_image{
    width:10vh;
    }
}
@media screen and (max-width:370px) {

  .subdoc_image{
    align-self: center;
     border-radius: 50%;
     max-width:100%;
    flex-grow: 0;
    margin: 0px 17.4px 14.6px 11.4px;
  }
}
@media screen and (min-height:900px) {
  .subdoc_box1{
    min-height: 260px;
    max-height:260px;
  }
}
@media screen and (min-height:1050px) {
  .subdoc_image{
    height:100%;
    }
}
@media screen and (min-height:1150px) {
  .subdoc_box1{
    min-height: 270px;
    max-height:270px;
  }
}
@media screen and (min-height:1550px) {
  .subdoc_box1{
    min-height: 290px;
    max-height:290px;
  }
}

