@import "../../../values/Colored.css";

  .fullFaq-block {
      width:50cqmax;
      flex-grow: 0;
      margin: 0 15.9px 0 0;
      padding: 13px 56.6px 0px 0px;
      border-radius: 25px;
      border: solid 1px var( --transperant-black);
      overflow: hidden;}
  .orange-Bar{
    width: 100%;
     max-width: 12px;
   flex-grow: 0;
   height: auto;
   margin: -80px 10px 29px -16px;
      background-color: var(--Wellnes-clickedbtn);
  }
  .AccordionDetails-Box{
    display: flex;
  }
  .Youcanwhats-appText {
    flex-grow: 0;
    margin: -31px 0 25px 25px;
    font-family: FontRegular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-color);
    white-space:break-spaces
  }
  .Canicall-doctorText {
    max-width: 517.8px;
    flex-grow: 0;
    margin: 10px 0px 10px 33.8px;
    font-family: FontRegular;
    font-size: 17px;
    font-weight:500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-color);
  }
  .Accordion-Box{
      box-shadow: none;
  }
@media screen and (max-width:1000px) {
  .fullFaq-block{
    width: 100%;
    padding: 0px 0px 0px 0px;}
  .Canicall-doctorText {
    margin: 1px 0px 0px 5px;}
  .orange-Bar{
   margin: -50px 10px 29px -16px;}}