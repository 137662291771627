@import "../../../values/Colored.css";

.TeamofCoaches-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 59px 80px 59px 80px;
  background-color: var(--event-darkblue);
  background-image: url("/src/images/OrangeRibbon.png");
  background-repeat: no-repeat;
  background-size:55.5%;
  background-position:82%;
  background-position-y: 35px;
  }
.TeamofCoaches-text {
  display: flex;
  align-items: center;
      max-width:550px;
    font-family: FontSemiBold;
    font-size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    align-self:center;
    color:var(--white-color);
    height: 15cqmax;
  }
  .Teamofcoaches-Video-Box {
      width:100%;
      height:100%;
      max-width:37vw;
      display:flex;
      align-items:center ;
      justify-content:center;
      margin-left: 20px;
  }
  @media screen and (max-width:1530px) {
    .TeamofCoaches-text {
      max-width:415px;
  }
}
  @media screen and (max-width:1130px) {
    .TeamofCoaches-text {
      max-width:335px;
      height: 15cqmax;
  }
     .TeamofCoaches-box { 
      padding: 48.5px 65px 48.5px 65px;
      background-size:66%;
      background-position:100%;
      background-position-y: 39px;
      }
  }  
  @media screen and (max-width:1000px) {
    .TeamofCoaches-text-box{
      width: 100%;
   }
    .TeamofCoaches-box{
   flex-wrap: wrap-reverse;
   padding: 20.5px 20px 20.5px 20px;
   margin: 65px -20px 0px -20px;
   background-size:100% 100%;
   background-position:top;
   background-position-y:20px ;

    }
    .Teamofcoaches-Video-Box{
      max-width:60%;
      padding-top:25px;
      margin-left: 0;
    }
    .TeamofCoaches-text{
      max-width: 80%;
      font-size:32px;
      margin: 20px 0px 0px 0px;
      height: auto;
    }
  }

  @media screen and (max-width:710px) {
    .TeamofCoaches-box{
      margin: 30px -20px 0px -20px;
      background-size:700px 400px;
    }
    .TeamofCoaches-text {
    line-height: 1.35;
    font-size: 30px;
  }
  .Teamofcoaches-Video-Box{
    max-width:75%;}
  }
  @media screen and (max-width:600px) {
    .TeamofCoaches-text {
max-width:85%;
    }
  }
  @media screen and (max-width:510px) {
    .Teamofcoaches-Video-Box{
      max-width:90%;} 
  }
  @media screen and (max-width:415px) {
    .TeamofCoaches-box{
      padding: 0px 20px 75.5px 20px;
    }
  }
  @media screen and (min-height:800px) {
    .TeamofCoaches-box
    {padding-top: 25px;}
  }
  @media screen and (min-height:900px) {
    .TeamofCoaches-box
    {padding-top: 110px;}
  }
  @media screen and (min-height:1350px) {
    .TeamofCoaches-box{
      padding-top: 160px;}
  }
  @media screen and (min-height:1750px) {
    .TeamofCoaches-box{
      padding-top: 200px;}
  }