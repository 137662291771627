@import "../../../../values/Colored.css";

.RequestModal-dialog{
    border-radius: 20px;
    max-width: 380px;
box-shadow: 0px 20px 20px 0px var(--card-shodow);
}
.RequestModal-closeBtn{
    max-width:15px;
    min-width:15px;
  margin-left: auto;
    margin-right: 15px;
    margin-top: 5px;
    color: var(--black-color);
    background-color: transparent !important;
}
.RequestModal-closeIcon{
    font-size: 18px;
}
.RequestModal-mainBox{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
border-radius: 10px;
background: var(--white-color);
padding:10px 25px 65px 25px ;
gap: 20px;
}
.RequestAptment-txt{
    font-family: FontSemiBold;
    font-size: 26px;
    font-stretch: normal;
    font-style: normal;
    line-height:32px;
    letter-spacing: normal;
    color: var(--black-Txt);
    border: none !important;
    text-transform: capitalize;
    text-align: center;
}
.RequestModal-Btn {
    width: 100%;
    text-decoration: none;
    text-transform: none;
    background-color: var(--consult-docbtn) !important;
    max-width: 350px;
    height: 50px;
    padding: 10px;
    color: var(--white-color);
    gap: 15px;
    margin-top: 7px;
  }
  .RequestModal-Btntxt {
    font-family: FontSemiBold;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height:20px;
    letter-spacing: -0.154px;
    text-align: left;
    color: var(--white-color) !important;
  }
.RequestAptment_sub-txt{
    font-family: FontRegular;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height:22px;
    letter-spacing: normal;
    color: var(--txtBlack-light);
    border: none !important;
    text-transform: normal;
    text-align: center;
}
.name_numberBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    width: 100%; 
}
.Head_SubBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%; 
}
.RequestModal-name-Box{
    width: 100%;
    border-radius: 4px;
    font-family: FontMedium;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--white-color);
    background-color: var(--white-color) !important;
    box-shadow: 0px 4px 4px 0px var(--card-shodow);
}
.RequestModal-numberDivider{
    border: 1px solid var(--input-border);
    height: 56px;
    }
.RequestModal-numberBox{
    width: 100%;
    display:flex ;
    align-items: flex-start;
    justify-content: flex-start;
    border: 1.5px solid var(--input-border);
    box-shadow: 0px 4px 4px 0px var(--card-shodow);
}
.RequestModal-number-Box{
    width:100%;
    max-width:101px;
    height: 56px;
        font-family: FontSemiBold;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: var(--Badge-Text);
        background-color: var(--white-color) !important;
        display: flex;
        align-items: center;
        padding: 5px 5px 0px 10px;
    }
    .CountryconnectForm{
        border: none;
    }
    .submitedImagBox{
        width: 213.761px;
height: 200.727px;
        width: 100%;
        display:flex ;
        align-items: center;
        justify-content: center;
    }
    .submitedImag{
width:100%;
max-width: 200px;
        }
        .Rquest-thanks-txt{
            color: var( --black-Txt);
            text-align: center;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-family:FontLight ;
            font-size: 42px;
            font-style: normal;
            font-weight: 300;
            line-height: 27px;
        }
        .Rquest-thanksSub-txt{
            color: var( --black-Txt);
            text-align: center;
            font-family: FontRegular;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
        @media screen and (max-width:330px) {
            .RequestModal-Btntxt {
                font-size: 14px;
            }
            .RequestModal-number-Box{
                max-width:60px;
            }
            .RequestAptment-txt{
                font-size: 22px;
            }
            .RequestAptment_sub-txt{
                font-size: 14px;
            }
            .Rquest-thanks-txt{
                font-size: 38px;
            }
        }