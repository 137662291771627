@import "../../../values/Colored.css";

.BlogsHeading-MainBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}
.Blogs-Text{
    max-width: 639px;
    margin: 85.3px 0px 16.5px 0px;
    font-family: FontSemiBold;
    font-size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: normal;
    text-align: center;
    color: var(--white-color);
}
.auto-keyboardIcon{
    color: var(--black-color);
    width: 24px;
}
.autotxtField-inputBox{
     font-size:18px; 
     font-family:FontSemiBold;
}
.Queries-Text{
    max-width: 699px;
    margin: 16.5px 0px 0px 0px;
    font-family: FontRegular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: var(--white-color);
}
.Blog-btn-ChipsBox{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0px 25px 0px; 
    gap:7px;
    width: 100%;
}
.allTo-category-Box{
    display: flex;
 gap: 10px;
}
.topic-label-txt {
    max-width: 348px;
    margin: 18px 0px 3px 0px;
    font-family: FontSemiBold;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: var(--Badge-Text);
  }
  .topicSelect-InputFilter {
    min-width:25vh;
    max-height: 42px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    justify-content: center;
    gap: 10px;
    margin: 18px 0px 0px 0px;
    border-radius: 8px;
    background-color: var(--inputBG) ;
    color: var(--black-color);
  }
  .topicSelect-input {
    align-self: stretch;
    display: flex;
    flex-grow: 1;
    font-family: FontSemiBold;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: var(--black-color)  !important;
    align-self: center;
    justify-self: center;
    text-overflow: ellipsis; 
    word-break: break-word;
    flex-wrap: wrap;
  }
.Blog-ChipsBox{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:7px;
    width:100%;
    max-width: max-content;
}
.topic-filterBox{
    width:100%;
}
.mainBlogs-Box{
    margin: 60px 0px 0px 0px;
    width: 100%;
    padding: 0px 77px 0px 77px;
}
.BlogAll-btn{
    max-height:45px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-transform: none;
    padding: 12px 35px;
    border-radius: 70px;
    color: var(--black-color);
    background-color: var(--white-color) !important;
    margin-top: auto;
}
    .MuiSelect-select{
        margin-top: 3px;
        }
        .MuiSelect-select:focus{
            background-color: var(--white-color);
        }
.selected-BlogBtns{
    max-height:45px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-transform: none;
   padding: 12px 35px;
    border-radius: 70px;
    color: var(--black-color);
    background-color: var(--consult-docbtn) !important;
    margin-top: auto;
}
.BlogAll-txt{
    font-family:FontSemiBold;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-color) !important; 
}
.selected-Blogtxt{
    font-family:FontSemiBold;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: var(--white-color) !important; 
}

.Blog-Chip{
    width:130px;
    height: 45px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-transform: none;
    border-radius: 70px;
    font-family:FontSemiBold;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    padding: 0px 20px;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-color);
    background-color: var(--white-color);
    overflow: hidden;
}
.Blog-Chip1{
    width:250px;
    max-width: max-content;
height:45px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-transform: none;
    border-radius: 70px;
    font-family:FontSemiBold;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    padding: 0px 20px;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-color);
    background-color: var(--white-color);
    overflow: hidden;
}
.Topic-chip1{
    width:100%;
    min-width: 25vh;
height:45px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-transform: none;
    border-radius: 70px;
    font-family:FontSemiBold;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    padding: 0px 25px;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-color);
    background-color: var(--white-color);
    overflow: hidden;
    margin-top:15px;
}
.auto-Topic-input{
    display: -webkit-box;
    flex-grow: 0;
    font-family:FontSemiBold;
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: var(--black-color);
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis; 
    text-align: left;
  }

.Topic-chip{
    width:100%;
    min-width:250px;
height:45px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-transform: none;
    border-radius: 70px;
    font-family:FontSemiBold;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    padding: 0px 20px;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-color);
    background-color: var(--white-color);
    overflow: hidden;
    text-overflow: ellipsis; 
    margin-top:15px;
}

.Blog-txt{
    width: 100%;
  font-family:FontSemiBold;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    margin-left: -15px ;
    margin-top: 22px;
    color: var(--black-color) !important;
}
.Next-BtnBox{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 60px 0px 85px 0px;
}
.Next-btn{
    padding:10px 25px;
 text-transform: none;
background-color: var(--consult-docbtn) !important;
}
.Next-btnTxt{
    align-self: center;
    justify-self: center;
    font-family: FontRegular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: var(--white-color);
}

@media screen and (max-width:1000px) {
    .Blogs-Text{
        margin: 102.3px 0px 0px 0px;
    }
    .BlogsHeading-MainBox{
        margin: -20px 0px 30px -12px;
        align-items: flex-start;
        justify-content:flex-start;
    }
    .Blog-ChipsBox{
        flex-wrap: wrap;
        gap:15px;
    }
    .Queries-Text{
        text-align:left;
    }
    .Next-BtnBox{
        margin: 0px 0px 50px 0px;
    }
    .Next-btn{
        width:50%;
        max-width:190px;
    }
    .Blog-btn-ChipsBox{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 4px 0px 10px 0px; 
        gap:7px;
        width: 100%;
    }
    .mainBLogdonwload-changebox{
        width:100%;
        display:flex;
        align-items:center;
        justify-content:center;
    } 

}
@media screen and (max-width:700px) {
    .mainBlogs-Box{
        width: 98%;
        margin: 40px 0px 20px 0px;
        padding: 0px 7px 0px 7px;
    }
}
@media screen and (max-width:600px) {
.Blog-Chip{
    font-family:FontLight;
    font-size: 13px;
font-weight: 600;
}
.Blog-Chip1{
    font-family:FontLight;
    font-size: 13px;
font-weight: 600;
}
.Topic-chip{
    font-family:FontLight;
    font-size: 13px;
font-weight: 600;
}
.Topic-chip1{
    font-family:FontLight;
    font-size: 13px;
font-weight: 600;
}
.allTo-category-Box{
    margin-top: 18px;
        }
}
@media screen and (max-width:527px) {
    .Topic-chip{
        align-self: flex-start;
        width:80vw;
    min-width: 100%;
    } 
    .Blog-btn-ChipsBox{
        flex-wrap: nowrap;
    } 
    .selected-BlogBtns{
        margin-left: 10px;
    }
}
@media screen and (max-width:460px) {
    .Blog-btn-ChipsBox{
        display: flex;

        flex-wrap: nowrap;
        gap: 5px;
    } 
    .allTo-category-Box{
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
align-self: flex-start;
gap: 0px;
    }
    .Blog-ChipsBox{
        flex-direction: row;
        gap:5px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: flex-start;
    }
    .Blog-Chip{
        width:190px;
    }
    .Blog-Chip1{
        width:180px;
    }
   .Topic-chip1{
        width:290px; 
    }
    .Topic-chip{
        align-self: flex-start;
        width:70vw;
    min-width: 100%;
    } 
} 
@media screen and (max-width:413px) {
    .auto-keyboardIcon{
        color: var(--black-color);
        width: 38px;
        height: 38px;
        padding: 2px;
    }
}
@media screen and (max-width:410px) {
    .auto-keyboardIcon{
        color: var(--black-color);
        width: 30px;
        height: auto;
        padding: 0px;
    }
}
@media screen and (max-width:320px) {
    .Topic-chip{
        width:65vw;
    } 
}
  @media screen and (min-height:900px) {
    .BlogsHeading-MainBox{
      padding-top: 50px;
           }
  }
  @media screen and (min-height:1350px) {
    .BlogsHeading-MainBox{
      padding-top: 100px;
           }
  }
  @media screen and (min-height:1750px) {
    .BlogsHeading-MainBox{
      padding-top: 150px;
           }
  }