:root{
    --Top-color:#FFF6EE;
    --book-BG:#FFF7F0;
    --black-color:#000000;
    --black-Txt:#333;
    --book-txt:#2E2E2E;
    --ChatBox-coachTxt:#656565;
    --filled-holdertxt:#999;
    --txtblack-color:#000000d6;
    --txtBlack-light:#666;
    --transperant-black:#00000029;
    --border-black:#00000012;
    --text-black:#282828;
    --text-doctor:#585858;
    --text-event:#0b0b0b;
    --white-color:#ffffff;
    --orange:#ff7540;
    --Chatbox-send:#F57D2F;
    --Chatbox-replay:#FFF6EF;
    --text-grey:#505050;
    --light-grey:#f3f3f3;
    --darkBlue:#001e9b;
    --event-orange:#f57625;
    --mate-orange:#ff9550;
    --event-darkblue:#0f172f;
    --feedback-orange:#f57822;
    --wellness-grey:#a3a1a1;
    --wellness-grey-txt:#727070;
    --copy-rightTxt:#bababa;
    --footer-black:#202020;
    --Wellnes-clickedbtn:#f87f2f;
    ---border1-color:#c9c7c7;
    ---border2-color:#d3d3d3ac;
    --Badge-Color:#f4f4f4;
    --Badge-Text:#111928;
    --light-Purle:#cabffd;
    --Sky-Blue:#c3ddfd;
    --Light-Pink:#fad1e8;
    --Light-Purple:#CABFFD;
    --Light-Orange:#FCDDEC;
    --Light-Green:#C1FFDB;
    --light-green1:#afecef;
    --Light-BrownOrange:#fcd9bd;
    --light-Skyblue:#ebf5ff;
    --Divider:#e0e0e0;
    --Chip-orange:#f88335;
    --dwnld-orange:#fb9417;
    --EventsText:#4d4d4d;
    --DarkBlue-Text:#081f32;
    --icon-Blue:#6e798c;
    --inpuborder:#c6c6c6;
    --Border:#d8dcde;
    --inputIcon:#6b7280;
    ---txt-color:#a3a6a8;
    --inputBG:#f9fafb;
    --lightMarron-BG:#fde8e8;
    --Border-shadow:#00000040;
    --awrd-border:#E3D9D9;
    --Modal-head:#333333;
    --Products-brdr:#b5b5b54d;
    --award-brdr:#8a8a8a4d;
    --Tabs-bgcolor:#efefef;
    --background: #dbdada;
    --light-blue:#e5f0fb;
    --light-yellow:#f7f7dd;
    --licence-box:#fffbeb;
    --thank-you:#20963c;
    --consult-docbtn:#00229F;
    --mobilesearchbar-bg:#F6F6F7;
    --centers-BGs:#EFF6FF;
    --centers-BG1:#FEF2F2;
    --centers-BG2:#FEFCE8;
    --txt-center:#F47E25;
    --consult-cardborder:#f0f0f0;
    --bg-consultcard:#f6f6f6;
    --bg-subtext:#212529;
    --bg-subtext1:#666666;
    --Zenonco:#1482f0;
    --Founder:#7e7e7e;
    --MyReport-bg:#F9EBDF;
    --Blue-slide-txt:#007bff;
    --Blue-slide-box:#007bffbd;
    --hover-read-txt:#ffff00;
    --book-border:#FEDCBE;
    --card-shodow:#00000008;
    --footer_txt:#9BC7FF;
    --footer_BG-Color:#F0F4F9;
    --whatsap-btn:#14d506;
    --whatsapp-animation1:#02cb02b1;
    --whatsapp-animation2:#02cb022a;
    --expert-dvd:#ECECEC;
    --connectForm:#FDDDC7;
    --input-border:#C5C5C5;}