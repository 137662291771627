@Import "../../values/Colored.css";
.mainBoxEventsList{
overflow: hidden;
}
.HeadingComponents{
    background-color:var(--event-darkblue)
}
.event-Header{
    min-height:55px;
    position:sticky;
z-index:1001;
}