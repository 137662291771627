@import "../../../values/Colored.css";

.heading-Survivor-txt{
    flex-grow: 0;
    font-family: FontSemiBold;
    font-size: 32px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.96;
    letter-spacing: normal;
text-align: center;
margin-right: auto;
margin-top: -10px;
margin-bottom: -20px;
color: var(--black-color);
}
.survivorsMain-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 100px 0px 100px ;
}
.allBlogs-carouselBox{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.all-Survivorscards{
    width: 100%;
    display: flex;
    align-items: center;
  justify-items:center;
gap: 5px;
-webkit-tap-highlight-color: transparent;
}
.cardHoverBox{
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width:300px;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 30px;
    border-radius: 15px;
    border: 0.25vh solid;
    border-color: var(--consult-cardborder);
}
@media screen and (max-width:1000px) {
    .survivorsMain-box{
        margin: 20px;
    }
    .survivorsMain-box{
        padding: 0px 35px 50px 35px ;
}
}
@media screen and (max-width:570px) {
      .heading-Survivor-txt{
        font-size: 30px;
    }
}
@media screen and (max-width:500px) {
    .all-Survivorscards{
        width: 100%;
        align-items:center;
      justify-content:flex-start;
    padding: 0px 10px 0px 0px;
    }
    .cardHoverBox{
        margin-top: 50px;
    }
    .survivorsMain-box{
        padding: 0px 25px 0px 25px ;
    }
}
@media screen and (max-width:473px) {
    .heading-Survivor-txt{
        font-size: 28px;
    }
}
